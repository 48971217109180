import React, { useState, useEffect } from 'react';
import { Popover, Box, Avatar, Typography, Button, Divider, TextField } from '@mui/material';
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Profile = ({ avatarSrc }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState(null);
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [passwordFormData, setPasswordFormData] = useState({
    user_id: "", old_password: "", password: "", password_confirmation: "",
  });

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'avatar-popover' : undefined;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.get('https://development.amomarket.in/api/user_profile', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          setUserData(response.data.user);
          setUserId(response.data.user.id);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    }
  }, []);

  const handleOpenChangePasswordDialog = (userId) => {
    setPasswordFormData({ ...passwordFormData, user_id: userId });
    setChangePasswordOpen(true);
    handleClose();
  };

  const handleCloseChangePasswordDialog = () => {
    setChangePasswordOpen(false);
    setPasswordFormData({
      user_id: "",
      old_password: "",
      password: "",
      password_confirmation: "",
    });
  };

  const handlePasswordFormChange = (event) => {
    const { name, value } = event.target;
    setPasswordFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePasswordFormSubmit = () => {
    setOldPasswordError('');
    setPasswordError('');

    const token = localStorage.getItem("token");

    axios.post('https://development.amomarket.in/api/user/change_password', passwordFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        Swal.fire("Success!", response.data.message, "success");
        handleCloseChangePasswordDialog();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data && error.response.data.errors) {
            if (error.response.data.errors.old_password) {
              setOldPasswordError(error.response.data.errors.old_password[0]);
              toast.error(error.response.data.errors.old_password[0]);
            }
            if (error.response.data.errors.password) {
              setPasswordError(error.response.data.errors.password[0]);
              toast.error(error.response.data.errors.password[0]);
            }
          } else if (error.response.data.message) {
            handleCloseChangePasswordDialog();
            Swal.fire("Error!", error.response.data.message, "error");
          }
        } else {
          Swal.fire("Error!", "Server error or network issue. Please try again later.", "error");
        }
      });
  };

  const handleLogoutButtonClick = () => {
    handleClose();

    Swal.fire({
      title: "Logout ?",
      text: "You will be logged out and unable to proceed with the next step!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log me out",
      cancelButtonText: "Cancel"
    }).then((result) => {
      if (result.isConfirmed) {
        logoutUser();
      }
    });
  };

  const logoutUser = () => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.post('https://development.amomarket.in/api/logout', {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          Swal.fire("Success!", response.data.message, "success");
          setTimeout(() => {
            localStorage.removeItem('token');
            window.location.href = '/admin';
          }, 2000); // Delay of 2 seconds
        })
        .catch(error => {
          console.error('Error logging out:', error);
        });
    }
  };

  const handleViewProfileButtonClick = (userId) => {
    navigate(`/update-user/${userId}`); // Navigate to UpdateUserPage with the user ID
  };

  return (
    <>
      <Avatar
        alt="Avatar"
        src={userData.avatar || avatarSrc} // Use fetched user data
        sx={{ width: 40, height: 40, cursor: 'pointer' }}
        onClick={handleAvatarClick}
      />
      <ToastContainer />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ padding: 2, width: 250, minWidth: 250 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              mb: 2,
              p: 2,
              backgroundImage: 'url(/profile2.png)',
              backgroundSize: 'cover',
              borderRadius: 1,
            }}
          >
            <Avatar alt="Avatar" src={userData.avatar || avatarSrc} sx={{ width: 80, height: 80 }} />
          </Box>
          <Typography variant="h6" align="center" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
            {userData.name || 'Guest'}
          </Typography>
          <Typography variant="body2" align="center" color="text.secondary">
            {userData.email || 'guest@example.com'}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Button
            fullWidth
            sx={{
              mb: 1,
              fontWeight: 'bold',
              color: 'text.secondary',
              borderRadius: 2,
              '&:hover': {
                bgcolor: 'lightblue',
              },
            }}
            onClick={() => handleViewProfileButtonClick(userId)}
          >
            View Profile
          </Button>
          <Button
            fullWidth
            sx={{
              mb: 1,
              fontWeight: 'bold',
              color: 'text.secondary',
              borderRadius: 2,
              '&:hover': {
                bgcolor: '#d2e416',
              },
            }}
            onClick={() => handleOpenChangePasswordDialog(userId)}
          >
            Change Password
          </Button>
          <Button
            fullWidth
            sx={{
              fontWeight: 'bold',
              color: 'text.secondary',
              borderRadius: 2,
              '&:hover': {
                bgcolor: 'lightcoral',
              },
            }}
            onClick={handleLogoutButtonClick}
          >
            Logout
          </Button>
        </Box>
      </Popover>
      <Dialog open={changePasswordOpen} onClose={handleCloseChangePasswordDialog}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="old_password"
            label="Old Password"
            type="password"
            fullWidth
            value={passwordFormData.old_password}
            onChange={handlePasswordFormChange}
            error={!!oldPasswordError}
            helperText={oldPasswordError}
          />
          <TextField
            margin="dense"
            name="password"
            label="New Password"
            type="password"
            fullWidth
            value={passwordFormData.password}
            onChange={handlePasswordFormChange}
            error={!!passwordError}
            helperText={passwordError}
          />
          <TextField
            margin="dense"
            name="password_confirmation"
            label="Confirm Password"
            type="password"
            fullWidth
            value={passwordFormData.password_confirmation}
            onChange={handlePasswordFormChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChangePasswordDialog} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePasswordFormSubmit} variant="contained" color="success">
            Change
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Profile;

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleIcon from "@mui/icons-material/Google";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';

const theme = createTheme({
  palette: {
    primary: {
      main: "#23DC0D", // Your custom color
    },
  },
});

const WelcomePage = () => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // State for dialog box

  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    try {
      window.location.href = "https://development.amomarket.in/api/auth/google";
    } catch (error) {
      console.error("Error while logging in with Google:", error);
    }
  }
  const handleFacebookLogin = () => {
    try {
      window.location.href = "https://development.amomarket.in/api/auth/facebook";
    } catch (error) {
      console.error("Error while logging in with Google:", error);
    }
  }
  const handleLogin = async (e) => {
    e.preventDefault();
    setNameError("");
    setNumberError("");
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://development.amomarket.in/api/auth/auth_mobile",
        {
          name,
          number,
        }
      );

      console.log("Response from server:", response.data);
      toast.success(response.data.message);
      localStorage.setItem("token", response.data.token);
      navigate("/otp_verification");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        if (error.response.data.errors.name) {
          setNameError(error.response.data.errors.name[0]);
          toast.error(error.response.data.errors.name[0]);
        }
        if (error.response.data.errors.number) {
          setNumberError(error.response.data.errors.number[0]);
          toast.error(error.response.data.errors.number[0]);
        }
      } else {
        toast.error(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleOpenLogin = () => {
    navigate('/login');
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ToastContainer />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} order={1}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <img
                src="/welcome.png"
                alt="login"
                style={{ maxWidth: "100%" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={2}>
            <Card elevation={3} sx={{ maxWidth: "400px", width: "100%" }}>
              <CardContent>
                <div>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ color: "#757575", textAlign: "center" }}
                  >
                    ________ Log in or sign up ________
                  </Typography>
                </div>
                <form onSubmit={handleLogin}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!!nameError}
                    helperText={nameError}
                  />
                  <TextField
                    label="Phone Number"
                    type="number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    error={!!numberError}
                    helperText={numberError}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    style={{ marginTop: "1rem" }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Continue"}
                  </Button>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ color: "#757575", textAlign: "center" }}
                  >
                    ____________ or ____________
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    mt={2}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "50%",
                        padding: 0,
                        width: 65,
                        height: 65,
                      }}
                      onClick={handleGoogleLogin}
                    >
                      <GoogleIcon fontSize="large" />
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "50%",
                        padding: 0,
                        width: 65,
                        height: 65,
                      }}
                      onClick={handleDialogOpen}
                    >
                      <MoreHorizIcon fontSize="large" />
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle className="no-wrap">Choose Log in or Sign up Method</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                mt={2}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "50%",
                    padding: 0,
                    width: 65,
                    height: 65,
                  }}
                  onClick={handleFacebookLogin}
                >
                  <FacebookIcon fontSize="large" />
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "50%",
                    padding: 0,
                    width: 65,
                    height: 65,
                  }}
                  onClick={handleOpenLogin}
                >
                  <EmailIcon fontSize="large" />
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
};

export default WelcomePage;

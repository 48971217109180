import React from 'react';
import { Route, Routes } from 'react-router-dom';

// Portal Route
import Main from '../Main';
import CartButtonLayout from '../Cart/CartButtonLayout';
import AccountPage from '../Account/AccountPage';
import AllProduct from '../AllProduct';
import ProductDetails from '../ProductDetails';
import SubCategoryWiseProduct from '../SubCategoryWiseProduct';
import OrderHistoryPage from '../Account/OrderHistoryPage';
import AddressBookPage from '../Account/AddressBookPage';

const PortalRoutes = () => {
    return (
        <Routes>
            {/* portal Route Path */}
            <Route exact path="/" element={<CartButtonLayout><Main /></CartButtonLayout>} />
            <Route exact path="/account" element={<AccountPage />} />
            <Route exact path="/all_product/:categoryId" element={<AllProduct />} />
            <Route exact path="/product-details/:productId" element={<ProductDetails />} />
            <Route exact path="/all_product/subcategory/:categoryId" element={<SubCategoryWiseProduct />} />

            {/* Mobile-specific routes */}
            <Route path="/order-history" element={<OrderHistoryPage />} />
            <Route path="/address-book" element={<AddressBookPage />} />
        </Routes>
    );
};

export default PortalRoutes;
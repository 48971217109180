import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Layout from "../../NewTemplate/Layout";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import MenuItem from "@mui/material/MenuItem";
import ToggleOnTwoToneIcon from "@mui/icons-material/ToggleOnTwoTone";
import ToggleOffTwoToneIcon from "@mui/icons-material/ToggleOffTwoTone";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ExtensionOffTwoToneIcon from '@mui/icons-material/ExtensionOffTwoTone';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import qs from "qs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  paddingTop: theme.spacing(9), // Adjust padding top to accommodate header height
  display: "flex", // Add display flex to make children flex items
  flexDirection: "column", // Align children in a column
 overflowX: 'auto',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#D7D7D7",
  color: "black",
  fontSize: "14px",
  fontWeight: "bold", // Add this line to make text bold
  "&:first-of-type": {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  "&:last-of-type": {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
}));

const ProductInformationPage = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");
  const [rangeStart, setRangeStart] = useState(1);
  const [rangeEnd, setRangeEnd] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    fetchProducts(currentPage, perPage, sortOrder, sortBy);
  }, [currentPage, perPage, sortOrder, sortBy]); // Empty dependency array to ensure the effect runs only once on component mount

  // Function to fetch categories
  const fetchProducts = (page, perPage, sortOrder, sortBy) => {
    setLoading(true); // Set loading state to true when fetching data
    const token = localStorage.getItem("token");
    axios
      .get(
        `https://development.amomarket.in/api/product_info/fetch_all_product_info?page=${page}&per_page=${perPage}&sort_order=${sortOrder}&sort_by=${sortBy}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const activeLogData = response.data.data;
        setRows(activeLogData.data);
        setRangeStart(activeLogData.from);
        setRangeEnd(activeLogData.to);
        setTotalRows(activeLogData.total);
        setTotalPages(Math.ceil(activeLogData.total / perPage));
      })
      .catch((error) => {
        if (error.response.data.message) {
          Swal.fire("Error!", error.response.data.message, "error");
        } else {
          toast.error("Failed to fetch Product");
        }
      })
      .finally(() => {
        setLoading(false); // Set loading state to false when data fetching completes (whether success or error)
      });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlePerPageChange = (event) => {
    const perPageValue = event.target.value;
    setPerPage(perPageValue); // Update perPage state
    setCurrentPage(1);
    fetchProducts(1, perPageValue, sortOrder, sortBy);
  };

  const handleSortOrderChange = (event) => {
    const newSortOrder = event.target.value;
    setSortOrder(newSortOrder);
    fetchProducts(1, perPage, newSortOrder, sortBy); // Fetch categories with the updated sort order and sort by values
  };

  const handleSortByChange = (event) => {
    const newSortBy = event.target.value;
    setSortBy(newSortBy);
    fetchProducts(1, perPage, sortOrder, newSortBy); // Fetch categories with the updated sort by value
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleAddButtonClick = () => {
    navigate("/add-product"); // Navigate to '/add-product' route when Add button is clicked
  };

  const handleEditButtonClick = (productId) => {
    navigate(`/update-product/${productId}`); // Navigate to UpdateProductPage with the product ID
  };

  const handleDeleteButtonClick = (productId) => {
    // Show SweetAlert confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this product!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If confirmed, proceed with delete
        deleteProduct(productId);
      }
    });
  };

  // Function to call API for deleting product
  const deleteProduct = (productId) => {
    const token = localStorage.getItem("token");

    axios({
      method: "delete",
      url: `https://development.amomarket.in/api/product/delete_product`,
      data: qs.stringify({ product_id: productId }), // Serialize data to x-www-form-urlencoded format
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded", // Set the content type to x-www-form-urlencoded
      },
    })
      .then((response) => {
        Swal.fire("Deleted!", response.data.message, "success");
        fetchProducts(currentPage, perPage, sortOrder, sortBy); // Refresh product list
      })
      .catch((error) => {
        console.error("Error deleting product:", error);
        Swal.fire(
          "Error!",
          "An error occurred while deleting the product.",
          "error"
        );
      });
  };

  return (
    <Layout>
    <Box sx={{ display: "flex" }}>
      <ToastContainer />
      <MainContent>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 460 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0.4,
                marginTop: 0.5,
              }}
            >
              {loading ? (
                <Skeleton
                  animation="wave"
                  width={40}
                  height={40}
                  sx={{ marginRight: "10px" }}
                />
              ) : (
                <Tooltip title="Return Back">
                  <ArrowBackRoundedIcon
                    color="disabled"
                    onClick={handleBackClick}
                  />
                </Tooltip>
              )}
              {loading ? (
                <Skeleton
                  animation="wave"
                  width={100}
                  height={40}
                  sx={{ marginRight: "700px" }}
                />
              ) : (
                <Typography
                 sx={{
                      marginRight: { xs: "0", sm: "10px", md: "700px" },
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      flexGrow: 1,
                      textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                    }}
                  variant="h6"
                >
                  Product-Information List
                </Typography>
              )}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {loading ? (
                  <Skeleton
                    animation="wave"
                    width={100}
                    height={60}
                    sx={{ marginRight: "10px" }}
                  />
                ) : (
                  <Tooltip title="Add New Product-Information">
                    <Button
                      variant="outlined"
                      color="success"
                      size="large"
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={handleAddButtonClick}
                    >
                      Add
                    </Button>
                  </Tooltip>
                )}
                {/* Skeleton loading for the search TextField */}
                {loading ? (
                  <Skeleton
                    animation="wave"
                    width={200}
                    height={60}
                    sx={{ marginRight: "5px" }}
                  />
                ) : (
                  <Box>
                    <TextField
                      id="outlined-basic"
                      label="Search"
                      size="small"
                      variant="outlined"
                      sx={{ marginLeft: "10px", marginRight: "5px" }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Product Name</StyledTableCell>
                  <StyledTableCell align="center">Type</StyledTableCell>
                  <StyledTableCell align="center">Flavour</StyledTableCell>
                  <StyledTableCell align="center">Ingredients</StyledTableCell>
                  <StyledTableCell align="center">Attraction</StyledTableCell>
                  <StyledTableCell align="center">Key features</StyledTableCell>
                  <StyledTableCell align="center">FSSAI License</StyledTableCell>
                  <StyledTableCell align="center">Manufacturer</StyledTableCell>
                  <StyledTableCell align="center">Available Second Unit</StyledTableCell>
                  <StyledTableCell align="center">Available Third Unit</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Updated At</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading // Conditionally render Skeleton if loading state is true
                  ? Array.from(Array(5).keys()).map(
                    (
                      index // Assuming 5 Skeleton rows for illustration
                    ) => (
                      <TableRow key={index}>
                        {[...Array(13).keys()].map(
                          (
                            cellIndex // Assuming 6 cells per row for illustration
                          ) => (
                            <TableCell key={cellIndex}>
                              <Skeleton animation="wave" />
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    )
                  )
                  : rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Tooltip title={row.product?.name || "Not Assign"}>
                          {row.product?.name
                            ? (row.product.name.length > 20 ? `${row.product.name.substring(0, 20)}...` : row.product.name)
                            : <ExtensionOffTwoToneIcon />}
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={row.product_type || "Not Describe"}>
                          {row.product_type ? (row.product_type.length > 10 ? `${row.product_type.substring(0, 10)}...` : row.product_type) : "-"}
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={row.product_flavour || "Not Describe"}>
                          {row.product_flavour ? (row.product_flavour.length > 20 ? `${row.product_flavour.substring(0, 20)}...` : row.product_flavour) : "-"}
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={row.product_Ingredients || "Not Describe"}>
                          {row.product_Ingredients ? (row.product_Ingredients.length > 20 ? `${row.product_Ingredients.substring(0, 20)}...` : row.product_Ingredients) : "-"}
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={row.product_attraction || "Not Describe"}>
                          {row.product_attraction ? (row.product_attraction.length > 20 ? `${row.product_attraction.substring(0, 20)}...` : row.product_attraction) : "-"}
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={row.key_features || "Not Describe"}>
                          {row.key_features ? (row.key_features.length > 20 ? `${row.key_features.substring(0, 20)}...` : row.key_features) : "-"}
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{row.fssai_license}</TableCell>
                      <TableCell align="center">
                        <Tooltip title={row.manufacturer_details || "Not Describe"}>
                          {row.manufacturer_details ? (row.manufacturer_details.length > 20 ? `${row.manufacturer_details.substring(0, 20)}...` : row.manufacturer_details) : "-"}
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {row.second_unit_weight && row.second_unit_price ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="center">
                        {row.third_unit_weight && row.third_unit_price ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="center">
                        {row.status === 1 ? (
                          <Tooltip title="Active">
                            <ToggleOnTwoToneIcon style={{ color: "green" }} />
                          </Tooltip>
                        ) : row.status === 0 ? (
                          <Tooltip title="Inactive">
                            <ToggleOffTwoToneIcon style={{ color: "red" }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Unknown">
                            <ToggleOffTwoToneIcon
                              style={{ color: "yellow" }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {new Date(row.updated_at).toLocaleDateString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}{" "}
                        {new Date(row.updated_at).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true, // Use 24-hour format
                          }
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "8px", // Add gap for spacing between buttons
                          }}
                        >
                          <Tooltip title="Edit Product-Information">
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              sx={{
                                borderRadius: "50%",
                                minWidth: "32px",
                                minHeight: "32px",
                                width: "32px",
                                height: "32px",
                                padding: 0, // Remove padding for better icon alignment
                              }}
                              onClick={() => handleEditButtonClick(row.id)}
                            >
                              <EditTwoToneIcon fontSize="small" />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete Product-Information">
                            <Button
                              variant="outlined"
                              color="error"
                              size="small"
                              sx={{
                                borderRadius: "50%",
                                minWidth: "32px",
                                minHeight: "32px",
                                width: "32px",
                                height: "32px",
                                padding: 0, // Remove padding for better icon alignment
                              }}
                              onClick={() => handleDeleteButtonClick(row.id)}
                            >
                              <DeleteTwoToneIcon fontSize="small" />
                            </Button>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 0.7,
              paddingBottom: 0.2,
              marginTop: "2px",
              marginBottom: "3px",
            }}
          >
            {/* Left side content */}
            <Box
              sx={{ display: "flex", alignItems: "center", marginLeft: "10px" }}
            >
              {loading ? (
                <React.Fragment>
                  <Skeleton animation="wave" width={100} height={50} />
                  <Skeleton
                    animation="wave"
                    width={100}
                    height={50}
                    sx={{ marginLeft: "10px" }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TextField
                    select
                    label="Sort Order"
                    value={sortOrder}
                    onChange={handleSortOrderChange}
                    sx={{ width: 100 }}
                    size="small"
                  >
                    {["desc", "asc"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option.toUpperCase()}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    label="Sort By"
                    value={sortBy}
                    onChange={handleSortByChange}
                    sx={{ marginLeft: "10px", width: 100 }}
                    size="small"
                  >
                    {["id", "created_at", "updated_at"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option.toUpperCase()}
                      </MenuItem>
                    ))}
                  </TextField>
                </React.Fragment>
              )}
            </Box>
            {/* Right side content */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {loading ? (
                <React.Fragment>
                  <Skeleton animation="wave" width={100} height={50} />
                  <Skeleton
                    animation="wave"
                    width={180}
                    height={50}
                    sx={{ marginLeft: "10px", marginRight: "5px" }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography sx={{ marginRight: "10px" }} variant="body2">
                    Rows per page:{" "}
                  </Typography>
                  <TextField
                    select
                    value={perPage}
                    onChange={handlePerPageChange}
                    sx={{ marginRight: "10px" }}
                    size="small"
                  >
                    {[10, 20, 50].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography
                    sx={{ marginRight: "10px" }}
                    variant="body2"
                  >{`${rangeStart} - ${rangeEnd} of ${totalRows}`}</Typography>
                  <Pagination
                    sx={{ marginRight: "10px" }}
                    shape="rounded"
                    color="primary"
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        component="button"
                        onClick={() => handlePageChange(null, item.page)}
                        {...item}
                      />
                    )}
                  />
                </React.Fragment>
              )}
            </Box>
          </Box>
        </Paper>
      </MainContent>
    </Box>
    </Layout>
  );
};

export default ProductInformationPage;

import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Divider, Grid } from '@mui/material';
import { Assignment, LocationOn, AccountBalanceWallet, Lock, Support, AttachMoney, Info, Notifications, ExitToApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import OrderHistoryPage from './OrderHistoryPage';
import AddressBookPage from './AddressBookPage';

const AccountContentMenu = () => {
    // State to keep track of the selected menu
    const [selectedMenu, setSelectedMenu] = useState('');
    const isMobile = useMediaQuery('(max-width:600px)'); // Detect if screen width is below 600px
    const navigate = useNavigate(); // Navigation hook from React Router

    // Menu items and their respective components
    const menuItems = [
        { text: 'Order History', icon: <Assignment />, component: <Typography><OrderHistoryPage/></Typography> },
        { text: 'Address Book', icon: <LocationOn />, component: <Typography><AddressBookPage/></Typography> },
        { text: 'Wallet Details', icon: <AccountBalanceWallet />, component: <Typography>Wallet Details Content</Typography> },
        { text: 'Account Privacy', icon: <Lock />, component: <Typography>Account Privacy Content</Typography> },
        { text: 'Customer Support & FAQ', icon: <Support />, component: <Typography>Customer Support & FAQ Content</Typography> },
        { text: 'Refunds', icon: <AttachMoney />, component: <Typography>Refunds Content</Typography> },
        { text: 'General Info', icon: <Info />, component: <Typography>General Info Content</Typography> },
        { text: 'Notification', icon: <Notifications />, component: <Typography>Notification Content</Typography> },
        { text: 'Logout', icon: <ExitToApp />, component: <Typography>Logout Content</Typography> },
    ];

    const handleMenuClick = (item) => {
        setSelectedMenu(item.text);
        if (isMobile) {
            // On mobile, navigate to a new page for the selected menu
            navigate(`/${item.text.toLowerCase().replace(' ', '-')}`);
        }
    };

    return (
        <Grid container>
            {/* Sidebar Menu (25% on large screens, 100% on small screens) */}
            <Grid item xs={12} md={3} sx={{ borderRight: { md: '1px solid #e0e0e0' }, backgroundColor: "#fff" }}>
                <Box padding="20px">
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Your Information
                    </Typography>
                    <List>
                        {menuItems.map((item) => (
                            <ListItem
                                key={item.text}
                                button
                                sx={{
                                    borderRadius: 3,
                                    backgroundColor: selectedMenu === item.text ? '#f0f0f0' : 'transparent',
                                    '&:hover': { backgroundColor: '#f0f0f0' }, // Hover effect
                                }}
                                onClick={() => handleMenuClick(item)}
                            >
                                <ListItemIcon>
                                    <Box
                                        sx={{
                                            backgroundColor: '#f5f5f5',
                                            padding: 1.25, // Adjusted padding to match previous style
                                            borderRadius: '50%',
                                        }}
                                    >
                                        {item.icon}
                                    </Box>
                                </ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                </Box>
            </Grid>

            {/* Content Area for Desktop (75% on large screens, hidden on small screens) */}
            {!isMobile && (
                <Grid item xs={12} md={9}>
                    <Box padding="20px" backgroundColor="#f9f9f9">
                        {menuItems.find((item) => item.text === selectedMenu)?.component}
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

export default AccountContentMenu;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Portal Route
import PortalRoutes from './components/Portal/Route/PortalRoutes';
// Admin Route
import AdminRoutes from './components/Admin/Route/AdminRoutes';


const App = () => {
  return (
    <Router>
      <Routes>

        {/* Portal Route Path */}
        <Route exact path="/*" element={<PortalRoutes />} />
        {/* Admin Route Path */}
        <Route path="/*" element={<AdminRoutes />} />
      </Routes>
    </Router>
  );
};

export default App;


// import React, { Component } from "react";
// import { render } from "react-dom";
// import { LoadScript } from "@react-google-maps/api";
// import Map from './components/Portal/Map';


// const lib = ["places"];
// const key = "AIzaSyA9MaTVJlWIWpINjcgyJl5eS6JDhe60238"; // PUT GMAP API KEY HERE
// class App extends React.Component {
//   render() {
//     return (
//       <LoadScript googleMapsApiKey={key} libraries={lib}>
//         <Map />
//       </LoadScript>
//     );
//   }
// }

// export default App;

// import React, { useState, useEffect } from 'react';
// import io from 'socket.io-client';

// const App = () => {
//   const [notifications, setNotifications] = useState([]);
  
//   // Replace this with your Reverb host and port
//   const socket = io('http://127.0.0.1:9000', {
//     transports: ['websocket'],
//     path: '/reverb'
//   });

//   useEffect(() => {
//     // Replace {id} with the logged-in user's ID.
//     const userId = 4;  // Example user ID
    
//     // Listen for the 'notifications' event for this user
//     socket.on(`notifications.${userId}`, (notification) => {
//       // Add the received notification to the state
//       setNotifications(prevNotifications => [...prevNotifications, notification]);
//     });

//     return () => {
//       socket.disconnect();
//     };
//   }, []);

//   return (
//     <div>
//       <h1>Notifications</h1>
//       <ul>
//         {notifications.map((notification, index) => (
//           <li key={index}>{notification.data.title}</li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default App;
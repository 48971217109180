import React from 'react';
import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const OrderHistoryPage = () => {
    const theme = useTheme();

    // Define media queries for different screen sizes
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Mobile (max-width:600px)
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Tablet (600px-960px)
    const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // Desktop and larger (min-width:960px)
    

    return (
        <div>
            {isMobile && <Typography>Order History Content for Mobile</Typography>}
            {isTablet && <Typography>Order History Content for Tablet</Typography>}
            {isDesktop && <Typography>Order History Content for Desktop/Laptop</Typography>}
        </div>
    );
};

export default OrderHistoryPage;

import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

const Map = ({ onLocationSelect }) => {
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState(null);
  const autocompleteService = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setCurrentLocation(pos);
      }
    );
  }, []);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        if (autocompleteService.current && window.google) {
          autocompleteService.current.getPlacePredictions(request, callback);
        }
      }, 400),
    []
  );

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue && newValue.place_id) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId: newValue.place_id }, (results, status) => {
        if (status === "OK" && results[0]) {
          const location = results[0].geometry.location;
          const newMarker = {
            position: { lat: location.lat(), lng: location.lng() },
            description: newValue.description,
          };
          setMarkers([newMarker]);
          setCurrentLocation({ lat: location.lat(), lng: location.lng() });
          if (typeof onLocationSelect === 'function') {
            onLocationSelect(newValue.description, location.lat(), location.lng()); // Call the callback with the selected location description
          } else {
            console.error("onLocationSelect is not a function");
          }
        }
      });
    } else {
      setMarkers([]);
      setInputValue('');
    }
  };

  const loadScript = (src, position, id) => {
    if (!position) return;
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
  };

  useEffect(() => {
    const onLoad = () => {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    };

    if (!window.google) {
      const script = document.querySelector('#google-maps');
      if (!script) {
        const position = document.querySelector('head');
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=AIzaSyA9MaTVJlWIWpINjcgyJl5eS6JDhe60238&libraries=places`,
          position,
          'google-maps'
        );
        script.onload = onLoad;
      }
    } else {
      onLoad();
    }
  }, []);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    fetch({ input: newInputValue }, (results) => {
      if (results) {
        setOptions(results);
      } else {
        setOptions([]);
      }
    });
  };

  return (
    <div>
      <Autocomplete
        id="google-map-demo"
        sx={{ width: 300 }}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={null}
        onChange={handleAutocompleteChange}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField {...params} label="Search location" fullWidth />
        )}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          const matches = option.structured_formatting.main_text_matched_substrings || [];
          const parts = parse(option.structured_formatting.main_text, matches.map((match) => [match.offset, match.offset + match.length]));

          return (
            <li key={key} {...optionProps}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <br />
      <div style={{ height: "300px", width: "100%" }}>
        <GoogleMap
          center={currentLocation}
          zoom={10}
          onLoad={(map) => {
            setCurrentLocation({ lat: map.center.lat(), lng: map.center.lng() });
            setBounds(map.getBounds());
          }}
          mapContainerStyle={{ height: "100%", width: "100%" }}
        >
          {markers.map((mark, index) => (
            <Marker
              key={index}
              position={mark.position}
              title={mark.description}
            />
          ))}
        </GoogleMap>
      </div>
    </div>
  );
};

export default Map;

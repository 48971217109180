import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Layout from "../../NewTemplate/Layout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  paddingTop: theme.spacing(9),
  display: "flex",
  flexDirection: "column",
}));

const AddUserPage = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirm] = useState("");
  const [number, setNumber] = useState("");
  const [status, setStatus] = useState("");
  const [avatar, setAvatar] = useState("/avatar.jpg");

  const [nameError, setNameError] = useState('');
  const [roleError, setRollError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
  const [numberError, setNumberError] = useState('');
  const [statusError, setStatusError] = useState('');
  const [avatarError, setAvatarError] = useState('');
  const [avatarFile, setAvatarFile] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    navigate("/user"); // Replace '/desired/path' with the path you want to navigate to on cancel
  };

  const handleSubmit = () => {
    setNameError('');
    setRollError('');
    setEmailError('');
    setPasswordError('');
    setPasswordConfirmationError('');
    setNumberError('');
    setStatusError('');
    setAvatarError('');
    setLoading(true);

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('name', name);
    formData.append('role_id', role);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('password_confirmation', password_confirmation);
    formData.append('number', number);
    formData.append('status', status);
    formData.append('avatar', avatar ?? null);
    if (avatarFile) {
      formData.append('main_avatar', avatarFile); // Ensure this is a File object
    }

    axios.post('https://development.amomarket.in/api/user/create_user', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        const userData = response.data;
        //console.log(userData);
        toast.success(userData.message);
        setLoading(false);
        navigate('/user'); // Adjust the path to where you want to navigate after success
      })
      .catch(error => {
        setLoading(false);
        if (error.response) {
          if (error.response.data && error.response.data.errors) {
            if (error.response.data.errors.name) {
              setNameError(error.response.data.errors.name[0]);
              toast.error(error.response.data.errors.name[0]);
            }
            if (error.response.data.errors.role_id) {
              setRollError(error.response.data.errors.role_id[0]);
              toast.error(error.response.data.errors.role_id[0]);
            }
            if (error.response.data.errors.email) {
              setEmailError(error.response.data.errors.email[0]);
              toast.error(error.response.data.errors.email[0]);
            }
            if (error.response.data.errors.password) {
              setPasswordError(error.response.data.errors.password[0]);
              toast.error(error.response.data.errors.password[0]);
            }
            if (error.response.data.errors.password_confirmation) {
              setPasswordConfirmationError(error.response.data.errors.password_confirmation[0]);
              toast.error(error.response.data.errors.password_confirmation[0]);
            }
            if (error.response.data.errors.number) {
              setNumberError(error.response.data.errors.number[0]);
              toast.error(error.response.data.errors.number[0]);
            }
            if (error.response.data.errors.status) {
              setStatusError(error.response.data.errors.status[0]);
              toast.error(error.response.data.errors.status[0]);
            }
            if (error.response.data.errors.main_avatar) {
              setAvatarError(error.response.data.errors.main_avatar[0]);
              toast.error(error.response.data.errors.main_avatar[0]);
            }
          } else if (error.response.data.message) {
            toast.error(error.response.data.message);
          }
        } else {
          toast.error('Server error or network issue. Please try again later.');
        }
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setAvatar(URL.createObjectURL(file));
        setAvatarFile(file);
        setAvatarError('');
      } else {
        setAvatarError('The file must be an image.');
      }
    }
  };

  useEffect(() => {
    return () => {
      if (avatar.startsWith('blob:')) {
        URL.revokeObjectURL(avatar); // Clean up URL.createObjectURL()
      }
    };
  }, [avatar]);

  return (
    <Layout>
      <Box sx={{ display: "flex" }}>
        <ToastContainer />
        <MainContent>
          <Paper sx={{ width: "100%", overflow: "hidden", padding: "3px" }}>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
              <Tooltip title="Return Back">
                <ArrowBackRoundedIcon
                  color="disabled"
                  onClick={handleBackClick}
                  sx={{ cursor: "pointer", marginRight: 1 }}
                />
              </Tooltip>
              <Typography sx={{
                      marginRight: { xs: "0", sm: "10px", md: "700px" },
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      flexGrow: 1,
                      textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                    }} variant="h6">
                Add User
              </Typography>
            </Box>
            <Divider sx={{ marginY: 1 }} />

            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <img src={avatar} alt="Avatar" style={{ borderRadius: "50%", width: 200, height: 200, objectFit: "cover", border: '2px solid grey' }} />
                <label htmlFor="avatar-upload" style={{ marginTop: 10 }}>
                  <input
                    style={{ display: 'none' }}
                    id="avatar-upload"
                    name="avatar-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  <Button
                    sx={{ marginTop: 2, backgroundColor: 'grey', color: 'black', '&:hover': { backgroundColor: 'darkgrey' } }}
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadTwoToneIcon />}
                  >
                    Upload Profile Picture
                  </Button>
                </label>
                {avatarError && <Typography variant="body2" color="error">{avatarError}</Typography>}
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                  <Grid item xs={12} md={6}>
                    <InputLabel
                      sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      shrink
                    >
                      Name :
                    </InputLabel>
                    <TextField
                      label="Enter User Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={!!nameError}
                      helperText={nameError}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel
                      sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      shrink
                    >
                      Role :
                    </InputLabel>
                    <TextField
                      label="Select Role"
                      type="text"
                      variant="outlined"
                      fullWidth
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      error={!!roleError}
                      helperText={roleError}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                  <Grid item xs={12} md={12}>
                    <InputLabel
                      sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      shrink
                    >
                      Email :
                    </InputLabel>
                    <TextField
                      label="Enter Email Address"
                      variant="outlined"
                      type="email"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={!!emailError}
                      helperText={emailError}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                  <Grid item xs={12} md={6}>
                    <InputLabel
                      sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      shrink
                    >
                      Password :
                    </InputLabel>
                    <TextField
                      label="Enter Password"
                      variant="outlined"
                      fullWidth
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={!!passwordError}
                      helperText={passwordError}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel
                      sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      shrink
                    >
                      Confirm Password :
                    </InputLabel>
                    <TextField
                      label="Enter Confirm Password"
                      variant="outlined"
                      fullWidth
                      type="password"
                      value={password_confirmation}
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                      error={!!passwordConfirmationError}
                      helperText={passwordConfirmationError}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                  <Grid item xs={12} md={6}>
                    <InputLabel
                      sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      shrink
                    >
                      Phone Number :
                    </InputLabel>
                    <TextField
                      label="Enter Phone Number"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      error={!!numberError}
                      helperText={numberError}
                      InputProps={{
                        startAdornment: <Typography variant="body1" style={{ marginRight: 8 }}>+91</Typography>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel
                      sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      shrink
                    >
                      Status :
                    </InputLabel>
                    <TextField
                      select
                      label="Select Status"
                      variant="outlined"
                      fullWidth
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      error={!!statusError}
                      helperText={statusError}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option selected value={1}>Active</option>
                      <option value={2}>Pending</option>
                      <option value={0}>Inactive</option>
                    </TextField>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 1 }} />
            <Box sx={{ display: "flex", justifyContent: "flex-end", margin:1 }}>
              <Button
                onClick={handleCancelClick}
                color="secondary"
                variant="outlined"
                startIcon={<CancelTwoToneIcon />}
                sx={{ marginRight: 2 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                startIcon={<SendTwoToneIcon />}
                disabled={loading}
              >
                {loading ? "Adding..." : "Add User"}
              </Button>
            </Box>
          </Paper>
        </MainContent>
      </Box>
    </Layout>
  );
};

export default AddUserPage;

import React, { useEffect, useState } from 'react';
import { Container, Card, CardContent, Typography, Button, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: 'none',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
  [theme.breakpoints.down('sm')]: {
    borderRadius: theme.spacing(1), // Reduce border radius for mobile
    width: '95%', // Slightly increase the card width on mobile
  },
}));

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://development.amomarket.in/api/portal/banners')
      .then(response => {
        setBanners(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching banner data: ', error);
      });
  }, []);

  return (
    <Container maxWidth="xl" style={{ marginTop: '20px' }}>
      {loading ? (
        <Skeleton variant="rect" width="100%" height={200} style={{ marginBottom: '20px' }} />
      ) : (
        <div style={{ marginBottom: '20px' }}>
          {banners.content_image ? (
            <StyledCard>
              <img src={banners.content_image} alt={banners.title} style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '10px',
              }} />
            </StyledCard>
          ) : (
            <StyledCard>
              <CardContent style={{ backgroundColor: banners.background_colour, color: 'white', textAlign: 'left', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flex: '1 1 60%', paddingRight: '20px' }}>
                  <Typography
                    variant="h3"
                    component="div"
                    gutterBottom
                    sx={{
                      fontWeight: 'bold',
                      textShadow: '1px 1px 2px black, 0 0 1em black, 0 0 0.2em black',
                    }}
                  >
                    {banners.title}
                  </Typography>
                  <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}>
                    {banners.description}
                  </Typography>
                  <Button variant="contained" style={{ backgroundColor: banners.button_colour, color: '#4caf50', marginTop: '10px' }}>
                    Shop Now
                  </Button>
                </div>
                <img src={banners.image} alt={banners.title} style={{
                  width: '45%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px',
                  boxShadow: '10px 64px 128px rgba(0.8, 0.8, 0.8, 0.8)',
                }} />
              </CardContent>
            </StyledCard>
          )}
        </div>
      )}
    </Container>
  );
};

export default Banner;

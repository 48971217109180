import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Layout from "../../NewTemplate/Layout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import qs from "qs";

const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  paddingTop: theme.spacing(9),
  display: "flex",
  flexDirection: "column",
}));

const UpdatePrivilegePage = () => {
  const navigate = useNavigate();
  const { privilegeId } = useParams();
  const [privilegeGetId, setPrivilegeGetId] = useState("");
  const [prefixPrivilegeName, setPrefixPrivilegeName] = useState("");
  const [suffixPrivilegeName, setSuffixPrivilegeName] = useState("");
  const [prefixDescription, setPrefixDescription] = useState("");
  const [suffixDescription, setSuffixDescription] = useState("");


  const [prefixPrivilegeNameError, setPrefixPrivilegeNameError] = useState("");
  const [suffixPrivilegeNameError, setSuffixPrivilegeNameError] = useState("");
  const [prefixDescriptionError, setPrefixDescriptionError] = useState("");
  const [suffixDescriptionError, setSuffixDescriptionError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch user data based on userId
    const token = localStorage.getItem("token");
    axios
      .put(
        `https://development.amomarket.in/api/privilege/find_privilege`,
        qs.stringify({ privilege_id: privilegeId }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        const privilegeData = response.data.data;
        setPrivilegeGetId(privilegeData.privilege_id);
        setPrefixPrivilegeName(privilegeData.prefix_privilege_name);
        setSuffixPrivilegeName(privilegeData.suffix_privilege_name);
        setPrefixDescription(privilegeData.prefix_description);
        setSuffixDescription(privilegeData.suffix_description);

      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [privilegeId]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    navigate("/privilege"); // Replace '/desired/path' with the path you want to navigate to on cancel
  };

  const handleSubmit = () => {
    setPrefixPrivilegeNameError("");
    setSuffixPrivilegeNameError("");
    setPrefixDescriptionError("");
    setSuffixDescriptionError("");
    setLoading(true);

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("privilege_id", privilegeGetId);
    formData.append("prefix_privilege_name", prefixPrivilegeName);
    formData.append("suffix_privilege_name", suffixPrivilegeName);
    formData.append("prefix_description", prefixDescription);
    formData.append("suffix_description", suffixDescription);

    axios
      .post("https://development.amomarket.in/api/privilege/update_privilege", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const userData = response.data;
        toast.success(userData.message);
        setLoading(false);
        navigate("/privilege"); // Adjust the path to where you want to navigate after success
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.data && error.response.data.errors) {
            if (error.response.data.errors.prefix_privilege_name) {
              setPrefixPrivilegeNameError(error.response.data.errors.prefix_privilege_name[0]);
              toast.error(error.response.data.errors.prefix_privilege_name[0]);
            }
            if (error.response.data.errors.suffix_privilege_name) {
              setSuffixPrivilegeNameError(error.response.data.errors.suffix_privilege_name[0]);
              toast.error(error.response.data.errors.suffix_privilege_name[0]);
            }
            if (error.response.data.errors.prefix_description) {
              setPrefixDescriptionError(error.response.data.errors.prefix_description[0]);
              toast.error(error.response.data.errors.prefix_description[0]);
            }
            if (error.response.data.errors.suffix_description) {
              setSuffixDescriptionError(error.response.data.errors.suffix_description[0]);
              toast.error(error.response.data.errors.suffix_description[0]);
            }
          } else if (error.response.data.message) {
            Swal.fire("Error", error.response.data.message, "error");
          }
        } else {
          toast.error("Server error or network issue. Please try again later.");
        }
      });
  };


  return (
    <Layout>
      <Box sx={{ display: "flex" }}>
        <ToastContainer />
        <MainContent>
          <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
              <Tooltip title="Return Back">
                <ArrowBackRoundedIcon
                  color="disabled"
                  onClick={handleBackClick}
                  sx={{ cursor: "pointer", marginRight: 1 }}
                />
              </Tooltip>
              <Typography sx={{
                marginRight: { xs: "0", sm: "10px", md: "700px" },
                fontWeight: "bold",
                whiteSpace: "nowrap",
                flexGrow: 1,
                textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
              }} variant="h6">
                Update Privilege
              </Typography>
            </Box>
            <Divider sx={{ marginY: 2 }} />

            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                  <Grid item xs={12} md={5}>
                    <InputLabel
                      sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      shrink
                    >
                      Prefix Privilege Name :
                    </InputLabel>
                    <TextField
                      label="Enter Prefix Privilege Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                      value={prefixPrivilegeName}
                      onChange={(e) => setPrefixPrivilegeName(e.target.value)}
                      error={!!prefixPrivilegeNameError}
                      helperText={prefixPrivilegeNameError}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <InputLabel
                      sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      shrink
                    >
                      Suffix Privilege Name :
                    </InputLabel>
                    <TextField
                      label="Enter Suffix Privilege Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                      value={suffixPrivilegeName}
                      onChange={(e) => setSuffixPrivilegeName(e.target.value)}
                      error={!!suffixPrivilegeNameError}
                      helperText={suffixPrivilegeNameError}
                      InputProps={{
                        startAdornment: <Typography variant="body1" style={{ marginRight: 8 }}> - </Typography>
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                  <Grid item xs={12} md={5}>
                    <InputLabel
                      sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      shrink
                    >
                      Prefix Description:
                    </InputLabel>
                    <TextField
                      label="Enter Prefix Description"
                      variant="outlined"
                      type="text"
                      fullWidth
                      value={prefixDescription}
                      onChange={(e) => setPrefixDescription(e.target.value)}
                      error={!!prefixDescriptionError}
                      helperText={prefixDescriptionError}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <InputLabel
                      sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      shrink
                    >
                      Suffix Description :
                    </InputLabel>
                    <TextField
                      label="Enter Suffix Description"
                      variant="outlined"
                      type="text"
                      fullWidth
                      value={suffixDescription}
                      onChange={(e) => setSuffixDescription(e.target.value)}
                      error={!!suffixDescriptionError}
                      helperText={suffixDescriptionError}
                      InputProps={{
                        startAdornment: <Typography variant="body1" style={{ marginRight: 8 }}> @ </Typography>
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider sx={{ marginY: 2 }} />
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", margin: 1 }}
            >
              <Button
                onClick={handleCancelClick}
                color="secondary"
                variant="outlined"
                startIcon={<CancelTwoToneIcon />}
                sx={{ marginRight: 2 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                startIcon={<SendTwoToneIcon />}
                disabled={loading}
              >
                {loading ? "Updating..." : "Update Privilege"}
              </Button>
            </Box>
          </Paper>
        </MainContent>
      </Box>
    </Layout>
  );
};

export default UpdatePrivilegePage;

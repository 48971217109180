import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Layout from "../../NewTemplate/Layout";
import axios from "axios";
import qs from "qs";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  paddingTop: theme.spacing(9),
  display: "flex",
  flexDirection: "column",
  maxWidth: "100vw", // Prevent overflow
  overflowX: "auto", // Allow horizontal scroll if needed
}));

const ShareActiveLogPage = () => {
  const navigate = useNavigate();
  const { activeLogId } = useParams();
  const [ActiveLogAttributes, setActiveLogAttributes] = useState([]);
  const [ActiveLogOld, setActiveLogOld] = useState([]);
  const [ActiveLogData, setActiveLogData] = useState([]);

  useEffect(() => {
    const fetchActiveLog = () => {
      const token = localStorage.getItem("token");
      axios
        .put(
          `https://development.amomarket.in/api/active_log/find_active_log`,
          qs.stringify({ activelog_id: activeLogId }),
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          const activelogByIdData = response.data;
          setActiveLogAttributes(activelogByIdData.data.attributes);
          setActiveLogOld(activelogByIdData.data.old);
          setActiveLogData(activelogByIdData.data.logs.causer_id);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    };

    fetchActiveLog();
  }, [activeLogId]);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <Box sx={{ display: "flex" }}>
        <MainContent>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
            <Tooltip title="Return Back">
                <ArrowBackRoundedIcon
                  color="disabled"
                  onClick={handleBackClick}
                  sx={{ cursor: "pointer", marginRight: 1 }}
                />
              </Tooltip>
              <Typography sx={{
                marginRight: { xs: "0", sm: "10px", md: "700px" },
                fontWeight: "bold",
                whiteSpace: "nowrap",
                flexGrow: 1,
                textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
              }} variant="h6">
                ActiveLog Details
              </Typography>
            </Box>
            <Divider sx={{ marginY: 2 }} />
            {ActiveLogData === null ? (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <Card sx={{ flex: 1, border: "1px solid #ccc" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        borderBottom: "1px solid #ccc",
                        paddingBottom: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      Attributes
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {Object.entries(ActiveLogAttributes).map(
                            ([key, value]) => (
                              <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell>
                                  <Tooltip title={value}>
                                    <span>{value}</span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                  <Card
                    sx={{ flex: 1, border: "1px solid #ccc", marginLeft: "10px" }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        borderBottom: "1px solid #ccc",
                        paddingBottom: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      Old Attributes
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {Object.entries(ActiveLogOld).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>{key}</TableCell>
                              <TableCell>
                                <Tooltip title={value}>
                                  <span>{value}</span>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Box>
              </div>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                }}
              >
                <Card sx={{ flex: 1, border: "1px solid #ccc" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      borderBottom: "1px solid #ccc",
                      paddingBottom: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    Attributes
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {Object.entries(ActiveLogAttributes).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>{key}</TableCell>
                              <TableCell>
                                <Tooltip title={value}>
                                  <span>{value}</span>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
                <Card
                  sx={{ flex: 1, border: "1px solid #ccc", marginLeft: "10px" }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      borderBottom: "1px solid #ccc",
                      paddingBottom: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    Old Attributes
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {Object.entries(ActiveLogOld).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCell>{key}</TableCell>
                            <TableCell>
                              <Tooltip title={value}>
                                <span>{value}</span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Box>
            )}
          </Paper>
        </MainContent>
      </Box>
    </Layout>
  );
};

export default ShareActiveLogPage;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PermissionPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const roleId = localStorage.getItem('role_id');
        if (roleId) {
            switch (roleId) {
                case '1': // Super Admin
                    navigate('/dashboard');
                    break;
                case '2': //Admin
                    navigate('/dashboard');
                    break;
                case '3': //Partner
                    navigate('/partner-dashboard');
                    break;
                case '4': //Retailer
                    navigate('/retailer-dashboard');
                    break;
                case '5': //Delivery
                    navigate('/delivery-dashboard');
                    break;
                case '6': //Sponsor
                    navigate('/sponsor-dashboard');
                    break;
                case '7': //Staff
                    navigate('/staff-dashboard');
                    break;
                default:
                    navigate('/default-page'); // Fallback page
                    break;
            }
        } else {
            navigate('/login'); // Redirect to login if no role_id found
        }
    }, [navigate]);

    return null; // Or a loading spinner if needed
};

export default PermissionPage;

import React from 'react';
import Box from '@mui/material/Box';
import AccountAppBar from './AccountAppBar';
import AccountContentMenu from './AccountContentMenu';

const AccountPage = () => {
    const handleLocationSelect = (description) => {
        // Handle the location selection logic
    };

    return (
        <Box>
            {/* AppBar */}
            <AccountAppBar handleLocationSelect={handleLocationSelect} />
            
            {/* Content Menu */}
            <AccountContentMenu />
        </Box>
    );
};

export default AccountPage;

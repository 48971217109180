// Header.js
import React from 'react';
import { AppBar, Toolbar, IconButton, InputBase, Box, Typography, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CropFreeIcon from '@mui/icons-material/CropFree';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import Profile from './Profile'; // Import the Profile component
import Conversation from './Conversation'; // Import the Conversation component

const Header = ({
  toggleSidebar,
  toggleSidebarCollapse,
  sidebarCollapsed,
  toggleFullScreen,
}) => {
  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{
          zIndex: 1201,
          boxShadow: '0 6px 10px rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(15px)',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', padding: '0 16px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h5"
              noWrap
              style={{ fontWeight: "bold", color: "#2EDF0F" }}
            >
              {/* Amo
              <span style={{ color: "#8c3cca" }}> Market</span> */}
            </Typography>
            <Box
              sx={{
                marginLeft: 2,
                display: 'flex',
                alignItems: 'center',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                bgcolor: 'background.paper',
                color: 'text.secondary',
                '& svg': {
                  m: 1,
                },
              }}
            >
              <MenuIcon onClick={toggleSidebarCollapse} />
              <Divider orientation="vertical" variant="middle" flexItem />
              <CropFreeIcon onClick={toggleFullScreen} />
            </Box>
          </Box>

          {/* Centered Search Bar */}
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '400px', width: '100%' }}>
              <SearchIcon sx={{ marginRight: 1 }} />
              <InputBase
                placeholder="Search now"
                inputProps={{ 'aria-label': 'search' }}
                sx={{ color: 'inherit', width: '100%' }}
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="inherit" sx={{ marginRight: 4 }}>
              <NotificationsActiveTwoToneIcon />
            </IconButton>

            {/* Profile Component for Avatar Popover with margin */}
            <Box sx={{ marginRight: 1 }}>
              <Profile avatarSrc="/avatar.jpg" />
            </Box>

            {/* Conversation Component for Drawer with margin */}
            <Box sx={{ marginLeft: 2 }}>
              <Conversation />
            </Box>
          </Box>


        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;

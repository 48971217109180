import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { GoogleMap } from '@react-google-maps/api';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import RoomTwoToneIcon from '@mui/icons-material/RoomTwoTone';
import MyLocationTwoToneIcon from '@mui/icons-material/MyLocationTwoTone';

const containerStyle = {
    width: '100%',
    height: '610px',
    borderRadius: '10px',
};

const center = {
    lat: 12.9716,
    lng: 77.5946,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const MapAddressModel = ({ open, onClose }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
    const [mapCenter, setMapCenter] = useState(center);
    const [selectedLocation, setSelectedLocation] = useState(center);
    const [locationName, setLocationName] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const mapRef = useRef(null);
    const autocompleteService = useRef(null);

    useEffect(() => {
        if (!window.google) {
            const position = document.querySelector('head');
            const script = document.createElement('script');
            script.setAttribute('async', '');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA9MaTVJlWIWpINjcgyJl5eS6JDhe60238&libraries=places`;
            script.onload = () => {
                autocompleteService.current = new window.google.maps.places.AutocompleteService();
                setGoogleMapsLoaded(true);
            };
            position.appendChild(script);
        } else {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
            setGoogleMapsLoaded(true);
        }
    }, []);

    const geocodeLatLng = (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK' && results[0]) {
                setLocationName(results[0].formatted_address);
            } else {
                setLocationName('Unknown location');
            }
        });
    };

    const handleMapClick = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setSelectedLocation({ lat, lng });
        geocodeLatLng(lat, lng);
    };

    const handleCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                setMapCenter({ lat, lng });
                setSelectedLocation({ lat, lng });
                mapRef.current.panTo({ lat, lng });
                geocodeLatLng(lat, lng);
            });
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        debounce(() => {
            autocompleteService.current.getPlacePredictions({ input: newInputValue }, (results) => {
                setOptions(results || []);
            });
        }, 400)();
    };

    const handleAutocompleteChange = (event, newValue) => {
        if (newValue && newValue.place_id) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ placeId: newValue.place_id }, (results, status) => {
                if (status === 'OK' && results[0]) {
                    const location = results[0].geometry.location;
                    setSelectedLocation({ lat: location.lat(), lng: location.lng() });
                    setMapCenter({ lat: location.lat(), lng: location.lng() });
                    mapRef.current.panTo({ lat: location.lat(), lng: location.lng() });
                    setLocationName(results[0].formatted_address);
                }
            });
        }
    };

    return (
        <Dialog
                fullScreen={isMobile}
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
                keepMounted
                sx={
                    !isMobile
                        ? {
                            '& .MuiDialog-paper': {
                                position: 'fixed',
                                right: 0,
                                margin: 0,
                                width: '30%',
                                height: '100vh',
                                maxHeight: '100vh',
                            },
                        }
                        : {}
                }
            >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <AppBar sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', color: 'black' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
                            Confirm map pin location
                        </Typography>
                        <IconButton edge="start" color="inherit" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Box sx={{ paddingLeft: 2, paddingRight: 2, marginTop: 1 }}>
                    <Autocomplete
                        fullWidth
                        options={options}
                        getOptionLabel={(option) => option.description}
                        onInputChange={handleInputChange}
                        onChange={handleAutocompleteChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Search for an address"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Box>

                {googleMapsLoaded && (
                    <Box sx={{ paddingLeft: 2, paddingRight: 2, height: '520px', marginTop: 1 }}>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={mapCenter}
                            zoom={15}
                            onClick={handleMapClick}
                            onLoad={(map) => (mapRef.current = map)}
                        >
                            {selectedLocation && (
                                <div>
                                    <RoomTwoToneIcon
                                        style={{
                                            position: 'absolute',
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '40px',
                                            color: 'red',
                                        }}
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '12px',
                                            color: '#666',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {/* {`Latitude: ${selectedLocation.lat}, Longitude: ${selectedLocation.lng}`} */}
                                    </div>
                                </div>
                            )}
                        </GoogleMap>
                    </Box>
                )}

                {/* Current Location Button */}
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Button
                        variant="outlined"
                        startIcon={<MyLocationTwoToneIcon />}
                        onClick={handleCurrentLocation}
                        sx={{
                            backgroundColor: '#e0d8ee',
                            color: '#5a1bcc',
                            borderColor: '#5a1bcc',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                borderColor: '#5a1bcc',

                            },
                        }}
                    >
                        Go to current location
                    </Button>
                </Box>

                {/* Delivery Location Information */}
                <Box
                    sx={{
                        mt: 3,
                        p: 2,
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Delivering your order to
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Center the icon and text vertically
                            justifyContent: 'center', // Center the entire content horizontally
                        }}
                    >
                        {/* Box for vertical stacking of text */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography variant="subtitle1">
                              <LocationOnIcon color="primary" /> {locationName ? `${locationName}` : 'Location Is Searching...'}
                            </Typography>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                {`Latitude: ${selectedLocation.lat}, Longitude: ${selectedLocation.lng}`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>


                {/* Confirm Button (fixed at the bottom) */}
                <Box
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: '#fff',
                        padding: '16px',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
                        zIndex: 10,
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            width: '100%',
                            padding: '10px 0',
                            borderRadius: '25px',
                            backgroundColor: 'green',
                            '&:hover': {
                                backgroundColor: '#5a1bcc',
                                borderColor: '#5a1bcc',
                            }
                        }}
                        onClick={() => alert(`Location confirmed: Latitude ${selectedLocation.lat}, Longitude ${selectedLocation.lng}`)}
                    >
                        Confirm location & proceed
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default MapAddressModel;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Layout from "../../NewTemplate/Layout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from "qs";

const MainContent = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(9),
    display: "flex",
    flexDirection: "column",
}));

const UpdateProductPage = () => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const [name, setName] = useState('');
    const [product, setProduct] = useState(null);
    const [subCategoryName, setSubCategoryName] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [aboutProduct, setAboutProduct] = useState('');
    const [weight, setWeight] = useState('');
    const [price, setPrice] = useState('');
    const [originalPrice, setOriginalPrice] = useState('');
    const [discount, setDiscount] = useState('');
    const [deliveryTime, setDeliveryTime] = useState('');
    const [unit, setUnit] = useState('');
    const [productCode, setProductCode] = useState('');
    const [status, setStatus] = useState('');
    const [image, setImage] = useState("/no_image2.png");
    const [imageFile, setImageFile] = useState(null);

    const [nameError, setNameError] = useState('');
    const [subCategoryNameError, setSubCategoryNameError] = useState('');
    const [aboutProductError, setAboutProductError] = useState('');
    const [weightError, setWeightError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [originalPriceError, setOriginalPriceError] = useState('');
    const [discountError, setDiscountError] = useState('');
    const [deliveryTimeError, setDeliveryTimeError] = useState('');
    const [unitError, setUnitError] = useState('');
    const [productCodeError, setProductCodeError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [imageError, setImageError] = useState('');

    const [loading, setLoading] = useState(false);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        navigate("/product"); // Replace '/desired/path' with the path you want to navigate to on cancel
    };

    const calculateDiscount = () => {
        if (price && originalPrice) {
            const discountPercentage = (1 - (originalPrice / price)) * 100;
            if (discountPercentage > 0) {
                const roundedDiscount = Math.round(discountPercentage);
                setDiscount(`${roundedDiscount}%`);
            } else {
                setDiscount(null);
            }
        } else {
            setDiscount(null);
        }
    };

    const handleSubmit = () => {
        setNameError('');
        setSubCategoryNameError('');
        setAboutProductError('');
        setWeightError('');
        setPriceError('');
        setOriginalPriceError('');
        setDiscountError('');
        setDeliveryTimeError('');
        setUnitError('');
        setStatusError('');
        setProductCodeError('');
        setImageError('');
        setLoading(true);

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('product_id', product);
        formData.append('product_name', name);
        formData.append('sub_category', subCategoryName);
        formData.append('about_product', aboutProduct);
        formData.append('product_weight', weight);
        formData.append('selling_price', price);
        formData.append('original_price', originalPrice);
        formData.append('discount', discount);
        formData.append('delivery_time', deliveryTime);
        formData.append('total_unit', unit);
        formData.append('product_code', productCode);
        formData.append('status', status);
        if (imageFile) {
            formData.append('image_file', imageFile); // Ensure this is a File object
        }

        axios.post('https://development.amomarket.in/api/product/update_product', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const productData = response.data;
                //console.log(userData);
                toast.success(productData.message);
                setLoading(false);
                navigate('/product'); // Adjust the path to where you want to navigate after success
            })
            .catch(error => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        if (error.response.data.errors.product_name) {
                            setNameError(error.response.data.errors.product_name[0]);
                            toast.error(error.response.data.errors.product_name[0]);
                        }
                        if (error.response.data.errors.sub_category) {
                            setSubCategoryNameError(error.response.data.errors.sub_category[0]);
                            toast.error(error.response.data.errors.sub_category[0]);
                        }
                        if (error.response.data.errors.about_product) {
                            setAboutProductError(error.response.data.errors.about_product[0]);
                            toast.error(error.response.data.errors.about_product[0]);
                        }
                        if (error.response.data.errors.product_weight) {
                            setWeightError(error.response.data.errors.product_weight[0]);
                            toast.error(error.response.data.errors.product_weight[0]);
                        }
                        if (error.response.data.errors.selling_price) {
                            setPriceError(error.response.data.errors.selling_price[0]);
                            toast.error(error.response.data.errors.selling_price[0]);
                        }
                        if (error.response.data.errors.original_price) {
                            setOriginalPriceError(error.response.data.errors.original_price[0]);
                            toast.error(error.response.data.errors.original_price[0]);
                        }
                        if (error.response.data.errors.discount) {
                            setDiscountError(error.response.data.errors.discount[0]);
                            toast.error(error.response.data.errors.discount[0]);
                        }
                        if (error.response.data.errors.delivery_time) {
                            setDeliveryTimeError(error.response.data.errors.delivery_time[0]);
                            toast.error(error.response.data.errors.delivery_time[0]);
                        }
                        if (error.response.data.errors.total_unit) {
                            setUnitError(error.response.data.errors.total_unit[0]);
                            toast.error(error.response.data.errors.total_unit[0]);
                        }
                        if (error.response.data.errors.product_code) {
                            setProductCodeError(error.response.data.errors.product_code[0]);
                            toast.error(error.response.data.errors.product_code[0]);
                        }
                        if (error.response.data.errors.status) {
                            setStatusError(error.response.data.errors.status[0]);
                            toast.error(error.response.data.errors.status[0]);
                        }
                        if (error.response.data.errors.image_file) {
                            setImageError(error.response.data.errors.image_file[0]);
                            toast.error(error.response.data.errors.image_file[0]);
                        }
                    } else if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.error('Server error or network issue. Please try again later.');
                }
            });
    };

    const handleImageFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(URL.createObjectURL(file));
                setImageFile(file);
                setImageError('');
            } else {
                setImageError('The file must be an image.');
            }
        }
    };

    useEffect(() => {
        // Fetch user data based on categoryId
        const token = localStorage.getItem('token');
        axios.put(
            `https://development.amomarket.in/api/product/find_product`,
            qs.stringify({ product_id: productId }), // Serialize data to x-www-form-urlencoded format
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded', // Set the content type to x-www-form-urlencoded
                },
            }
        )
            .then((response) => {
                const productData = response.data.data;
                setProduct(productData.id);
                setName(productData.name);
                setSubCategoryName(productData.sub_category_id);
                setAboutProduct(productData.about_product);
                setWeight(productData.weight);
                setPrice(productData.price);
                setOriginalPrice(productData.original_price);
                setDiscount(productData.discount);
                setDeliveryTime(productData.delivery_time);
                setProductCode(productData.product_code);
                setUnit(productData.unit);
                setStatus(productData.status);
                setImage(productData.image ?? "/no_image2.png");
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    }, [productId]);

    useEffect(() => {
        return () => {
            if (image.startsWith('blob:')) {
                URL.revokeObjectURL(image); // Clean up URL.createObjectURL()
            }
        };
    }, [image]);

    useEffect(() => {
        const fetchSubCategory = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://development.amomarket.in/api/subcategory/get_all_subcategory', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setSubCategories(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching shops:', error);
                setLoading(false);
            }
        };

        fetchSubCategory();
    }, []);

    const weightRanges = [
        '1 g', '2 g', '5 g', '10 g', '20 g', '25 g', '50 g', '100 g', '150 g', '200 g', '250 g', '500 g', '1 kg', '2 kg', '5 kg', '10 kg', '15 kg', '20 kg', '25 kg', '50 kg',
        '1 ml', '2 ml', '5 ml', '10 ml', '15 ml', '20 ml', '25 ml', '50 ml', '100 ml', '150 ml', '200 ml', '250 ml', '500 ml', '1 l', '2 l', '5 l', '10 l', '15 l', '20 l', '25 l', '50 l',
        '1 mm', '5 mm', '10 mm', '50 mm', '1 cm', '10 cm', '20 cm', '50 cm', '1 m', '2 m', '5 m', '10 m',
        '1 piece', '2 piece', '3 piece', '4 piece', '5 piece', '6 piece', '7 piece', '8 piece', '9 piece', '9 piece',
        '1 strip', '2 strip', '3 strip', '4 strip', '5 strip', '6 strip', '7 strip', '8 strip', '9 strip', '10 strip',
        'one side', 'both side'
    ];

    return (
        <Layout>
            <Box sx={{ display: "flex" }}>
                <ToastContainer />
                <MainContent>
                    <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                            <Tooltip title="Return Back">
                                <ArrowBackRoundedIcon
                                    color="disabled"
                                    onClick={handleBackClick}
                                    sx={{ cursor: "pointer", marginRight: 1 }}
                                />
                            </Tooltip>
                            <Typography sx={{
                                marginRight: { xs: "0", sm: "10px", md: "700px" },
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                                flexGrow: 1,
                                textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                            }} variant="h6">
                                Update Product
                            </Typography>
                        </Box>
                        <Divider sx={{ marginY: 2 }} />

                        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Product Name :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Product Name"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            error={Boolean(nameError)}
                                            helperText={nameError}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Select SubCategory :
                                        </InputLabel>
                                        <Autocomplete
                                            options={subCategories}
                                            getOptionLabel={(option) => option.name} // Display shop name
                                            getOptionSelected={(option, value) => option.id === value.id} // Manage the selected option
                                            loading={loading}
                                            value={subCategories.find(subCategories => subCategories.id === subCategoryName) || null} // Set the selected shop based on shop ID
                                            onChange={(event, newValue) => {
                                                // Store shop ID instead of shop name
                                                setSubCategoryName(newValue ? newValue.id : '');
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Choose SubCategory Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    error={Boolean(subCategoryNameError)}
                                                    helperText={subCategoryNameError}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            About Product :
                                        </InputLabel>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Enter Product Information"
                                            multiline
                                            type="text"
                                            rows={2}
                                            value={aboutProduct}
                                            onChange={(e) => setAboutProduct(e.target.value)}
                                            error={Boolean(aboutProductError)}
                                            helperText={aboutProductError}
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={3}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Product weight :
                                        </InputLabel>
                                        <Autocomplete
                                            options={weightRanges}
                                            value={weight}
                                            onChange={(event, newValue) => setWeight(newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Weight"
                                                    variant="outlined"
                                                    error={Boolean(weightError)}
                                                    helperText={weightError}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Selling Price :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Shelling Price"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)}
                                            error={Boolean(priceError)}
                                            helperText={priceError}
                                            onBlur={calculateDiscount} // call calculateDiscount when focus is lost
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Original Price :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Original Price"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={originalPrice}
                                            onChange={(e) => setOriginalPrice(e.target.value)}
                                            error={Boolean(originalPriceError)}
                                            helperText={originalPriceError}
                                            onBlur={calculateDiscount} // call calculateDiscount when focus is lost
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Discount :
                                        </InputLabel>
                                        <TextField
                                            label="Auto Calculate Discount"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={discount}
                                            onChange={(e) => setDiscount(e.target.value)}
                                            InputProps={{ readOnly: true }}
                                            error={Boolean(discountError)}
                                            helperText={discountError}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Product Ready To Delivery Time:
                                        </InputLabel>
                                        <TextField
                                            label="Enter Delivery Time"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={deliveryTime}
                                            onChange={(e) => setDeliveryTime(e.target.value)}
                                            error={Boolean(deliveryTimeError)}
                                            helperText={deliveryTimeError}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Available Unit For Sell:
                                        </InputLabel>
                                        <TextField
                                            label="Enter Available Unit"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={unit}
                                            onChange={(e) => setUnit(e.target.value)}
                                            error={Boolean(unitError)}
                                            helperText={unitError}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Is Product Code Available ? :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Product Code"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={productCode}
                                            onChange={(e) => setProductCode(e.target.value)}
                                            error={Boolean(productCodeError)}
                                            helperText={productCodeError}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Status :
                                        </InputLabel>
                                        <TextField
                                            select
                                            variant="outlined"
                                            fullWidth
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            error={!!statusError}
                                            helperText={statusError}
                                            SelectProps={{
                                                native: true,
                                            }}
                                        >
                                            <option value="" disabled>Select Status</option>
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold", paddingRight: '190px' }} shrink>
                                    Image :
                                </InputLabel>
                                <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardContent>
                                        <Box sx={{ marginBottom: 1 }}>
                                            <img
                                                src={image}
                                                alt="Main_Image"
                                                style={{ borderRadius: "5%", width: 220, height: 250, objectFit: "cover", border: '2px dotted grey' }}
                                            />
                                        </Box>
                                        <Box sx={{ paddingLeft: "30px" }}>
                                            <label htmlFor="profile-picture-upload">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="profile-picture-upload"
                                                    name="profile-picture-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageFileChange}
                                                />
                                                <Button
                                                    sx={{ backgroundColor: 'grey', color: 'black', '&:hover': { backgroundColor: 'darkgrey' }, whiteSpace: 'nowrap' }}
                                                    variant="contained"
                                                    component="span"
                                                    startIcon={<CloudUploadTwoToneIcon />}
                                                >
                                                    Upload Image
                                                </Button>
                                            </label>
                                            {imageError && <Typography variant="body2" color="error">{imageError}</Typography>}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Divider sx={{ marginY: 2 }} />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 1 }}>
                            <Button
                                onClick={handleCancelClick}
                                color="secondary"
                                variant="outlined"
                                startIcon={<CancelTwoToneIcon />}
                                sx={{ marginRight: 2 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                color="primary"
                                variant="contained"
                                startIcon={<SendTwoToneIcon />}
                                disabled={loading}
                            >
                                {loading ? "Updating..." : "Update Product"}
                            </Button>
                        </Box>
                    </Paper>
                </MainContent>
            </Box>
        </Layout>
    );
};

export default UpdateProductPage;

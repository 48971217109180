import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Layout from "../../NewTemplate/Layout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from "qs";

const MainContent = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(9),
    display: "flex",
    flexDirection: "column",
}));

const UpdateUserPage = () => {
    const navigate = useNavigate();
    const { userId } = useParams(); // Extract the userId from the route parameters
    const [user, setUser] = useState(null);
    const [name, setName] = useState("");
    const [role, setRole] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [status, setStatus] = useState("");
    const [avatar, setAvatar] = useState("/avatar.jpg");

    const [nameError, setNameError] = useState('');
    const [roleError, setRollError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [numberError, setNumberError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [avatarError, setAvatarError] = useState('');
    const [avatarFile, setAvatarFile] = useState(null);

    const [loading, setLoading] = useState(false);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        navigate("/user"); // Replace '/desired/path' with the path you want to navigate to on cancel
    };

    useEffect(() => {
        // Fetch user data based on userId
        const token = localStorage.getItem('token');
        axios.put(
            `https://development.amomarket.in/api/user/find_user`,
            qs.stringify({ user_id: userId }), // Serialize data to x-www-form-urlencoded format
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded', // Set the content type to x-www-form-urlencoded
                },
            }
        )
            .then((response) => {
                const userData = response.data.data;
                setUser(userData.id);
                setName(userData.name);
                setRole(userData.role_id);
                setEmail(userData.email);
                setNumber(userData.number);
                setStatus(userData.status);
                setAvatar(userData.avatar ?? "/avatar.jpg");
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    }, [userId]);

    const handleUpdate = () => {
        setNameError('');
        setRollError('');
        setEmailError('');
        setNumberError('');
        setStatusError('');
        setAvatarError('');
        setLoading(true);

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('user_id', user);
        formData.append('name', name);
        formData.append('role_id', role);
        formData.append('email', email);
        formData.append('number', number);
        formData.append('status', status);
        formData.append('avatar', avatar ?? null);
        if (avatarFile) {
            formData.append('main_avatar', avatarFile); // Ensure this is a File object
        }

        axios.post('https://development.amomarket.in/api/user/update_user', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                const userData = response.data;
                //console.log(userData);
                toast.success(userData.message);
                setLoading(false);
                navigate('/user'); // Adjust the path to where you want to navigate after success
            })
            .catch(error => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        if (error.response.data.errors.name) {
                            setNameError(error.response.data.errors.name[0]);
                            toast.error(error.response.data.errors.name[0]);
                        }
                        if (error.response.data.errors.role_id) {
                            setRollError(error.response.data.errors.role_id[0]);
                            toast.error(error.response.data.errors.role_id[0]);
                        }
                        if (error.response.data.errors.email) {
                            setEmailError(error.response.data.errors.email[0]);
                            toast.error(error.response.data.errors.email[0]);
                        }
                        if (error.response.data.errors.number) {
                            setNumberError(error.response.data.errors.number[0]);
                            toast.error(error.response.data.errors.number[0]);
                        }
                        if (error.response.data.errors.status) {
                            setStatusError(error.response.data.errors.status[0]);
                            toast.error(error.response.data.errors.status[0]);
                        }
                        if (error.response.data.errors.main_avatar) {
                            setAvatarError(error.response.data.errors.main_avatar[0]);
                            toast.error(error.response.data.errors.main_avatar[0]);
                        }
                    } else if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.error('Server error or network issue. Please try again later.');
                }
            });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setAvatar(URL.createObjectURL(file));
                setAvatarFile(file);
                setAvatarError('');
            } else {
                setAvatarError('The file must be an image.');
            }
        }
    };

    useEffect(() => {
        return () => {
            if (avatar.startsWith('blob:')) {
                URL.revokeObjectURL(avatar); // Clean up URL.createObjectURL()
            }
        };
    }, [avatar]);

    return (
        <Layout>
            <Box sx={{ display: "flex" }}>
                <ToastContainer />
                <MainContent>
                    <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                            <Tooltip title="Return Back">
                                <ArrowBackRoundedIcon
                                    color="disabled"
                                    onClick={handleBackClick}
                                    sx={{ cursor: "pointer", marginRight: 1 }}
                                />
                            </Tooltip>
                            <Typography sx={{
                                marginRight: { xs: "0", sm: "10px", md: "700px" },
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                                flexGrow: 1,
                                textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                            }} variant="h6">
                                Update User
                            </Typography>
                        </Box>
                        <Divider sx={{ marginY: 2 }} />

                        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <img src={avatar} alt="Avatar" style={{ borderRadius: "50%", width: 200, height: 200, objectFit: "cover", border: '2px solid grey' }} />
                                <label htmlFor="avatar-upload" style={{ marginTop: 10 }}>
                                    <input
                                        style={{ display: 'none' }}
                                        id="avatar-upload"
                                        name="avatar-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                    <Button
                                        sx={{ marginTop: 2, backgroundColor: 'grey', color: 'black', '&:hover': { backgroundColor: 'darkgrey' } }}
                                        variant="contained"
                                        component="span"
                                        startIcon={<CloudUploadTwoToneIcon />}
                                    >
                                        Upload Profile Picture
                                    </Button>
                                </label>
                                {avatarError && <Typography variant="body2" color="error">{avatarError}</Typography>}
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Name :
                                        </InputLabel>
                                        <TextField
                                            label="Enter User Name"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            error={!!nameError}
                                            helperText={nameError}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Role :
                                        </InputLabel>
                                        <TextField
                                            label="Select Role"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                            error={!!roleError}
                                            helperText={roleError}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Email :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Email Address"
                                            variant="outlined"
                                            type="email"
                                            fullWidth
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            error={!!emailError}
                                            helperText={emailError}
                                        />
                                    </Grid>
                                </Grid>
                                {/* <Grid container spacing={2} sx={{ marginBottom: 3 }}>  
                            </Grid> */}
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Phone Number :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Phone Number"
                                            variant="outlined"
                                            fullWidth
                                            type="number"
                                            value={number}
                                            onChange={(e) => setNumber(e.target.value)}
                                            error={!!numberError}
                                            helperText={numberError}
                                            InputProps={{
                                                startAdornment: <Typography variant="body1" style={{ marginRight: 8 }}>+91</Typography>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Status :
                                        </InputLabel>
                                        <TextField
                                            select
                                            label="Select Status"
                                            variant="outlined"
                                            fullWidth
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            error={!!statusError}
                                            helperText={statusError}
                                            SelectProps={{
                                                native: true,
                                            }}
                                        >
                                            <option selected value={1}>Active</option>
                                            <option value={2}>Pending</option>
                                            <option value={0}>Inactive</option>
                                        </TextField>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>

                        <Divider sx={{ marginY: 2 }} />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 2 }}>
                            <Button
                                onClick={handleCancelClick}
                                color="secondary"
                                variant="outlined"
                                startIcon={<CancelTwoToneIcon />}
                                sx={{ marginRight: 2 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleUpdate}
                                color="primary"
                                variant="contained"
                                startIcon={<SendTwoToneIcon />}
                                disabled={loading}
                            >
                                {loading ? "Updating..." : "Update User"}
                            </Button>
                        </Box>
                    </Paper>
                </MainContent>
            </Box>
        </Layout>
    );
};

export default UpdateUserPage;

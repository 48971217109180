import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';
import OtherHousesTwoToneIcon from '@mui/icons-material/OtherHousesTwoTone';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddAddressModel from './AddAddressModel';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const AddressModel = ({ open, onClose }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [openAddAddressModel, setOpenAddAddressModel] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpenAddAddressModel = () => {
        setOpenAddAddressModel(true);
    };

    const handleCloseAddAddressModel = () => {
        setOpenAddAddressModel(false);
    };
    return (
        <>
            <Dialog
                fullScreen={isMobile}
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
                keepMounted
                sx={
                    !isMobile
                        ? {
                            '& .MuiDialog-paper': {
                                position: 'fixed',
                                right: 0,
                                margin: 0,
                                width: '30%',
                                height: '100vh',
                                maxHeight: '100vh',
                            },
                        }
                        : {}
                }
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {/* Top bar with back button and title */}
                    <AppBar
                        sx={{
                            position: 'sticky',
                            top: 0,
                            backgroundColor: '#fff',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            color: 'transparent',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                            zIndex: 10,
                        }}
                    >
                        <Toolbar>
                            <IconButton edge="start" onClick={onClose} aria-label="back">
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1, color: 'black', fontWeight: 'bold' }} variant="h6">
                                Select delivery address
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    {/* Add new address button */}
                    <Box sx={{ mt: 2, backgroundColor: '#fff', borderRadius: '12px', padding: '16px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
                        <Button
                            startIcon={<AddIcon />}
                            variant="outlined"
                            sx={{
                                width: '100%',
                                borderRadius: '12px',
                                borderColor: 'green',
                                color: 'green',
                                mb: 1,
                                padding: '12px 0',
                                '&:hover': {
                                    borderColor: 'green',
                                    backgroundColor: 'transparent',
                                },
                            }}
                            onClick={handleOpenAddAddressModel}
                        >
                            Add a new address
                        </Button>
                    </Box>

                    {/* Saved addresses */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            backgroundColor: '#f5f5f5',
                            padding: '10px',
                        }}
                    >
                        <Typography variant="body2" sx={{ mb: 2, ml: 1, color: 'gray' }}>
                            Your saved address
                        </Typography>

                        {/* Address Card 1 */}
                        <Box
                            sx={{
                                backgroundColor: '#fff',
                                borderRadius: '12px',
                                padding: '16px',
                                mb: 2,
                                display: 'flex',
                                alignItems: 'flex-start',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <HomeTwoToneIcon sx={{ fontSize: 40, color: 'goldenrod', mr: 2 }} />
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    Home
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Room no 401, Building no 22 Kasavanahalli Village, Munnekollal, Bengaluru
                                </Typography>
                            </Box>
                            {/* More icon button with dropdown menu */}
                            <IconButton onClick={handleClick} sx={{ p: 0 }}>
                                <MoreHorizTwoToneIcon sx={{ fontSize: 20, color: 'gray' }} />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <EditTwoToneIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Edit</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <DeleteTwoToneIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Remove</ListItemText>
                                </MenuItem>
                            </Menu>
                        </Box>

                        {/* Address Card 2 */}
                        <Box
                            sx={{
                                backgroundColor: '#fff',
                                borderRadius: '12px',
                                padding: '16px',
                                mb: 2,
                                display: 'flex',
                                alignItems: 'flex-start',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <HomeWorkTwoToneIcon sx={{ fontSize: 40, color: 'goldenrod', mr: 2 }} />
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    Office
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    2nd floor, Building no:10, room no:201, AJR layout, marathalli, Near friends bakery
                                    Kasavanahalli Village, Marathahalli, Bengaluru
                                </Typography>
                            </Box>
                            {/* More icon button with dropdown menu */}
                            <IconButton onClick={handleClick} sx={{ p: 0 }}>
                                <MoreHorizTwoToneIcon sx={{ fontSize: 20, color: 'gray' }} />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <EditTwoToneIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Edit</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <DeleteTwoToneIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Remove</ListItemText>
                                </MenuItem>
                            </Menu>
                        </Box>
                        {/* Address Card 3 */}
                        <Box
                            sx={{
                                backgroundColor: '#fff',
                                borderRadius: '12px',
                                padding: '16px',
                                mb: 2,
                                display: 'flex',
                                alignItems: 'flex-start',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <OtherHousesTwoToneIcon sx={{ fontSize: 40, color: 'goldenrod', mr: 2 }} />
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    Other
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Room no 401, Building no 22 Kasavanahalli Village, Munnekollal, Bengaluru
                                </Typography>
                            </Box>
                             {/* More icon button with dropdown menu */}
                             <IconButton onClick={handleClick} sx={{ p: 0 }}>
                                <MoreHorizTwoToneIcon sx={{ fontSize: 20, color: 'gray' }} />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <EditTwoToneIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Edit</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <DeleteTwoToneIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Remove</ListItemText>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>

                    {/* Proceed button at the bottom */}
                    <Box
                        sx={{
                            position: 'sticky',
                            bottom: 0,
                            backgroundColor: '#fff',
                            padding: '16px',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                width: '100%',
                                padding: '10px 0',
                                borderRadius: '25px',
                                backgroundColor: 'green',
                                '&:hover': {
                                    backgroundColor: '#5a1bcc',
                                },
                            }}
                        >
                            Process to Checkout
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <AddAddressModel open={openAddAddressModel} onClose={handleCloseAddAddressModel} />
        </>
    );
};

export default AddressModel;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Card, CardContent, Typography, CardMedia, Skeleton } from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '180px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.3)',
  },
  [theme.breakpoints.down('sm')]: {
    height: '150px',
  },
  [theme.breakpoints.down('xs')]: {
    height: '120px',
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  [theme.breakpoints.down('sm')]: {
    height: '80%',
    objectFit: 'contain',
  },
  [theme.breakpoints.down('xs')]: {
    height: '70%',
    objectFit: 'contain',
  },
}));

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://development.amomarket.in/api/portal/category');
        setCategories(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching the categories:', error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <Container style={{ marginTop: '30px', marginBottom: '10px', maxWidth: '99%' }}>
      <Grid container spacing={1}>
        {loading ? (
          Array.from(new Array(12)).map((_, index) => (
            <Grid item xs={3} sm={4} md={1.2} key={index}>
              <Skeleton variant="rectangular" height={180} />
            </Grid>
          ))
        ) : (
          categories.map((category) => (
            <Grid item xs={3} sm={4} md={1.2} key={category.id}>
              <StyledCard>
                {category.content_image ? (
                  <StyledCardMedia
                    component="img"
                    image={category.content_image}
                    alt={category.name}
                  />
                ) : (
                  <>
                    <StyledCardMedia
                      component="img"
                      style={{ height: '100px', marginTop: '8px' }}
                      image={category.image}
                      alt={category.name}
                    />
                    <CardContent style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="body2" component="div" align="center" style={{ fontWeight: 'bold' }}>
                        {category.name}
                      </Typography>
                    </CardContent>
                  </>
                )}
              </StyledCard>
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
}

export default Category;

import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';

function Layout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleSidebarCollapse = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        overflow: fullScreen ? 'hidden' : 'auto',
      }}
    >
      {fullScreen ? null : (
        <Sidebar open={sidebarOpen} collapsed={sidebarCollapsed} />
      )}
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: fullScreen ? '100vw' : 'auto',
        }}
      >
        <Header
          toggleSidebar={toggleSidebar}
          toggleSidebarCollapse={toggleSidebarCollapse}
          sidebarCollapsed={sidebarCollapsed}
          toggleFullScreen={toggleFullScreen}
        />
        <div>
          {children} {/* Render the child component */}
        </div>
      </div>
    </div>
  );
}

export default Layout;

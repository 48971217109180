import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Layout from "../../NewTemplate/Layout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainContent = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(9),
    display: "flex",
    flexDirection: "column",
}));

const AddCategoryPage = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [shopName, setShopName] = useState('');
    const [shops, setShops] = useState([]);
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('');
    const [contentImage, setContentImage] = useState("/no_image2.png");
    const [contentImageFile, setContentImageFile] = useState(null);
    const [image, setImage] = useState("/no_image2.png");
    const [imageFile, setImageFile] = useState(null);

    const [nameError, setNameError] = useState('');
    const [shopNameError, setShopNameError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [contentImageError, setContentImageError] = useState('');
    const [imageError, setImageError] = useState('');

    const [loading, setLoading] = useState(false);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        navigate("/category"); // Replace '/desired/path' with the path you want to navigate to on cancel
    };

    const handleSubmit = () => {
        setNameError('');
        setShopNameError('');
        setDescriptionError('');
        setStatusError('');
        setContentImageError('');
        setImageError('');
        setLoading(true);

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('category_name', name);
        formData.append('shop_name', shopName);
        formData.append('description', description);
        formData.append('status', status);
        formData.append('content_image', contentImage ?? null);
        formData.append('image', image ?? null);
        if (contentImageFile) {
            formData.append('content_image_file', contentImageFile); // Ensure this is a File object
        }
        if (imageFile) {
            formData.append('image_file', imageFile); // Ensure this is a File object
        }

        axios.post('https://development.amomarket.in/api/category/create_category', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const categoryData = response.data;
                //console.log(userData);
                toast.success(categoryData.message);
                setLoading(false);
                navigate('/category'); // Adjust the path to where you want to navigate after success
            })
            .catch(error => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        if (error.response.data.errors.category_name) {
                            setNameError(error.response.data.errors.category_name[0]);
                            toast.error(error.response.data.errors.category_name[0]);
                        }
                        if (error.response.data.errors.shop_name) {
                            setShopNameError(error.response.data.errors.shop_name[0]);
                            toast.error(error.response.data.errors.shop_name[0]);
                        }
                        if (error.response.data.errors.description) {
                            setDescriptionError(error.response.data.errors.description[0]);
                            toast.error(error.response.data.errors.description[0]);
                        }
                        if (error.response.data.errors.status) {
                            setStatusError(error.response.data.errors.status[0]);
                            toast.error(error.response.data.errors.status[0]);
                        }
                        if (error.response.data.errors.content_image_file) {
                            setContentImageError(error.response.data.errors.content_image_file[0]);
                            toast.error(error.response.data.errors.content_image_file[0]);
                        }
                        if (error.response.data.errors.image_file) {
                            setImageError(error.response.data.errors.image_file[0]);
                            toast.error(error.response.data.errors.image_file[0]);
                        }
                    } else if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.error('Server error or network issue. Please try again later.');
                }
            });
    };

    const handleContentImageFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setContentImage(URL.createObjectURL(file));
                setContentImageFile(file);
                setContentImageError('');
            } else {
                setContentImageError('The file must be an image.');
            }
        }
    };
    const handleImageFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(URL.createObjectURL(file));
                setImageFile(file);
                setImageError('');
            } else {
                setImageError('The file must be an image.');
            }
        }
    };

    useEffect(() => {
        return () => {
            if (contentImage.startsWith('blob:')) {
                URL.revokeObjectURL(contentImage); // Clean up URL.createObjectURL()
            }
        };
    }, [contentImage]);

    useEffect(() => {
        return () => {
            if (image.startsWith('blob:')) {
                URL.revokeObjectURL(image); // Clean up URL.createObjectURL()
            }
        };
    }, [image]);

    useEffect(() => {
        const fetchShops = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://development.amomarket.in/api/shop/get_all_shop', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setShops(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching shops:', error);
                setLoading(false);
            }
        };

        fetchShops();
    }, []);

    return (
        <Layout>
            <Box sx={{ display: "flex" }}>
                <ToastContainer />
                <MainContent>
                    <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                            <Tooltip title="Return Back">
                                <ArrowBackRoundedIcon
                                    color="disabled"
                                    onClick={handleBackClick}
                                    sx={{ cursor: "pointer", marginRight: 1 }}
                                />
                            </Tooltip>
                            <Typography sx={{
                                marginRight: { xs: "0", sm: "10px", md: "700px" },
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                                flexGrow: 1,
                                textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                            }} variant="h6">
                                Add Category
                            </Typography>
                        </Box>
                        <Divider sx={{ marginY: 2 }} />

                        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Category Name :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Category Name"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            error={Boolean(nameError)}
                                            helperText={nameError}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Shop Name :
                                        </InputLabel>
                                        <Autocomplete
                                            options={shops}
                                            getOptionLabel={(option) => option.name} // Display shop name
                                            getOptionSelected={(option, value) => option.id === value.id} // Manage the selected option
                                            loading={loading}
                                            value={shops.find(shop => shop.id === shopName) || null} // Set the selected shop based on shop ID
                                            onChange={(event, newValue) => {
                                                // Store shop ID instead of shop name
                                                setShopName(newValue ? newValue.id : '');
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Choose Shop Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    error={Boolean(shopNameError)}
                                                    helperText={shopNameError}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Category Description :
                                        </InputLabel>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Enter Category Description"
                                            multiline
                                            type="text"
                                            rows={11}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            error={Boolean(descriptionError)}
                                            helperText={descriptionError}
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Status :
                                        </InputLabel>
                                        <TextField
                                            select
                                            variant="outlined"
                                            fullWidth
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            error={!!statusError}
                                            helperText={statusError}
                                            SelectProps={{
                                                native: true,
                                            }}
                                        >
                                            <option value="" disabled>Select Status</option>
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold", paddingRight: '130px' }} shrink>
                                    Content Image :
                                </InputLabel>
                                <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
                                    <CardContent>
                                        <Box sx={{ marginBottom: 1 }}>
                                            <img
                                                src={contentImage}
                                                alt="Content_Image"
                                                style={{ borderRadius: "5%", width: 220, height: 150, objectFit: "cover", border: '2px dotted grey' }}
                                            />
                                        </Box>
                                        <Box>
                                            <label htmlFor="main-image-upload">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="main-image-upload"
                                                    name="main-image-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleContentImageFileChange}
                                                />
                                                <Button
                                                    sx={{ backgroundColor: 'grey', color: 'black', '&:hover': { backgroundColor: 'darkgrey' }, whiteSpace: 'nowrap' }}
                                                    variant="contained"
                                                    component="span"
                                                    startIcon={<CloudUploadTwoToneIcon />}
                                                >
                                                    Upload Content Image
                                                </Button>
                                            </label>
                                            {contentImageError && <Typography variant="body2" color="error">{contentImageError}</Typography>}
                                        </Box>
                                    </CardContent>
                                </Card>
                                <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold", paddingRight: '190px' }} shrink>
                                    Image :
                                </InputLabel>
                                <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardContent>
                                        <Box sx={{ marginBottom: 1 }}>
                                            <img
                                                src={image}
                                                alt="Main_Image"
                                                style={{ borderRadius: "5%", width: 220, height: 150, objectFit: "cover", border: '2px dotted grey' }}
                                            />
                                        </Box>
                                        <Box>
                                            <label htmlFor="profile-picture-upload">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="profile-picture-upload"
                                                    name="profile-picture-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageFileChange}
                                                />
                                                <Button
                                                    sx={{ backgroundColor: 'grey', color: 'black', '&:hover': { backgroundColor: 'darkgrey' }, whiteSpace: 'nowrap' }}
                                                    variant="contained"
                                                    component="span"
                                                    startIcon={<CloudUploadTwoToneIcon />}
                                                >
                                                    Upload Image
                                                </Button>
                                            </label>
                                            {imageError && <Typography variant="body2" color="error">{imageError}</Typography>}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Divider sx={{ marginY: 2 }} />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 1 }}>
                            <Button
                                onClick={handleCancelClick}
                                color="secondary"
                                variant="outlined"
                                startIcon={<CancelTwoToneIcon />}
                                sx={{ marginRight: 2 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                color="primary"
                                variant="contained"
                                startIcon={<SendTwoToneIcon />}
                                disabled={loading}
                            >
                                {loading ? "Adding..." : "Add Category"}
                            </Button>
                        </Box>
                    </Paper>
                </MainContent>
            </Box>
        </Layout>
    );
};

export default AddCategoryPage;

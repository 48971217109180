import React, { useEffect, useState } from 'react';
import { Container, Card, CardMedia, CardContent, Typography, Button, Skeleton,Link } from '@mui/material';
import { Star, AccessTime } from '@mui/icons-material';
import FilterListTwoToneIcon from '@mui/icons-material/FilterListTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from "@mui/material/Tooltip";
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RecommendShop = () => {
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchShops = async () => {
      try {
        const response = await axios.get('https://development.amomarket.in/api/portal/shops');
        setShops(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching shops:', error);
        setLoading(false);
      }
    };

    fetchShops();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    swipeToSlide: true,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 3000, // Auto slide every 3 seconds
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          swipeToSlide: true,
          draggable: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          arrows: false,
          swipeToSlide: true,
          draggable: true,
        },
      },
    ],
  };

  return (
    <Container maxWidth="lg" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom style={{ marginBottom: '15px', fontWeight: 'bold' }}>
          Local shop In your location
        </Typography>
        <Link to={`#`} style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}>
          See All
        </Link>
      </div>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
        <Button variant="outlined" size="small" endIcon={<FilterListTwoToneIcon />} sx={{ color: '#787373', borderColor: '#787373' }}>
          Filter
        </Button>
        <Button variant="outlined" size="small" endIcon={<ArrowDropDownIcon />} sx={{ color: '#787373', borderColor: '#787373' }}>
          Sort By
        </Button>
      </div>
      <Slider {...settings}>
        {loading ? (
          Array.from(new Array(5)).map((_, index) => (
            <div key={index}>
              <Card
                sx={{
                  margin: '0 4px',
                  height: '100%',
                  cursor: 'pointer',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                    transform: 'scale(1.02)',
                  },
                }}
              >
                <Skeleton variant="rectangular" height={140} />
                <CardContent>
                  <Skeleton variant="text" height={30} width="80%" />
                  <Skeleton variant="text" height={20} width="60%" />
                  <Skeleton variant="text" height={20} width="60%" />
                </CardContent>
              </Card>
            </div>
          ))
        ) : (
          shops.map((shop, index) => (
            <div key={index}>
              <Card
                sx={{
                  margin: '0 4px',
                  height: '100%',
                  cursor: 'pointer',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                    transform: 'scale(1.02)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={shop.image}
                  alt={shop.name}
                  title={shop.name}
                  sx={{
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{
                      color: '#4d4d33',
                      fontWeight: 'bold',
                    }}
                  >
                    <Tooltip title={shop.name}>
                      <span>{shop.name}</span>
                    </Tooltip>
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <Star style={{ color: '#32d032', marginRight: '5px', fontSize: '20px' }} />
                    <Typography variant="body2" color="textSecondary" style={{ lineHeight: '24px' }}>
                      {shop.rating}  •  <AccessTime style={{ marginRight: '5px', fontSize: '20px', verticalAlign: 'sub' }} /> {shop.time}
                    </Typography>
                  </div>
                  <Typography variant="body2" color="textSecondary">
                    <Tooltip title={shop.description}>
                      <span>{shop.description}</span>
                    </Tooltip>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <Tooltip title={shop.location}>
                      <span>{shop.location}</span>
                    </Tooltip>
                  </Typography>
                </CardContent>
              </Card>
            </div>
          ))
        )}
      </Slider>
    </Container>
  );
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        height: '30px',
        background: '#bdbdbd',
        borderRadius: '50%',
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        height: '30px',
        background: '#bdbdbd',
        borderRadius: '50%',
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
}

export default RecommendShop;

import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CartModel from './CartModel';
import axios from 'axios';

const CartButton = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [openCartModel, setOpenCartModel] = useState(false);
    const [cartSummary, setCartSummary] = useState({ totalQuantity: 0, totalAmount: 0 });
    const [cartVisible, setCartVisible] = useState(false); // New state to track cart visibility

    useEffect(() => {
        // Fetch cart summary from API
        const fetchCartSummary = async () => {
            const token = localStorage.getItem('token'); // Retrieve token from localStorage

            try {
                const response = await axios.get('https://development.amomarket.in/api/portal/cart/summary', {
                    headers: {
                        Authorization: `Bearer ${token}` // Pass token in the request headers
                    }
                });

                if (response.data.status) {
                    const { totalQuantity, totalAmount } = response.data.data;

                    // Check if cart data is valid
                    if (totalQuantity && totalAmount) {
                        setCartSummary({ totalQuantity, totalAmount });
                        setCartVisible(true); // Set cart to visible
                    } else {
                        setCartVisible(false); // Hide the cart if no data
                    }
                } else {
                    console.error('Failed to fetch cart summary:', response.data.message);
                    setCartVisible(false); // Hide cart if response status is false
                }
            } catch (error) {
                console.error('Error fetching cart summary:', error);
                setCartVisible(false); // Hide cart if there's an error
            }
        };

        fetchCartSummary();
    }, []); // Empty dependency array means this useEffect runs once on mount

    const handleOpenCartModal = () => {
        setOpenCartModel(true);
    };

    const handleCloseCartModal = () => {
        setOpenCartModel(false);
    };

    return (
        <>
            {cartVisible && isMobile && !openCartModel && (  // Add cartVisible condition to hide the cart button
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 32, // Adjust the bottom value
                        right: 16, // Adjust the right value
                        width: '83%',
                        backgroundColor: 'green',
                        padding: '12px 24px',
                        borderRadius: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        zIndex: 2000,
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            padding: '8px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '10px',
                        }}
                    >
                        <ShoppingCartIcon sx={{ color: 'white', fontSize: '24px' }} />
                    </Box>
                    <Box sx={{ color: 'white', marginRight: '16px' }}>
                        {cartSummary.totalQuantity} item(s) - ₹{cartSummary.totalAmount}
                    </Box>
                    <Link onClick={handleOpenCartModal} style={{ textDecoration: 'none', marginLeft: 60 }}>
                        <Button size="small" variant="contained" sx={{ backgroundColor: 'white', color: 'green' }}>
                            View Cart
                        </Button>
                    </Link>
                </Box>
            )}
            <CartModel open={openCartModel} onClose={handleCloseCartModal} />
        </>
    );
};

export default CartButton;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Layout from "../NewTemplate/Layout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from '@mui/material';
import { InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MyLocationTwoToneIcon from '@mui/icons-material/MyLocationTwoTone';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { LoadScript } from "@react-google-maps/api";
import Map from "../../Portal/Map";
import qs from "qs";

const lib = ["places"];
const key = "AIzaSyA9MaTVJlWIWpINjcgyJl5eS6JDhe60238"; // Replace with your actual API key

const MainContent = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(9),
    display: "flex",
    flexDirection: "column",
}));

const UpdateShopPage = () => {
    const navigate = useNavigate();
    const { shopId } = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMapModal, setOpenMapModal] = useState(false);
    const [shop, setShop] = useState('');
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [location, setLocation] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [description, setDescription] = useState('');
    const [deliveryTime, setDeliveryTime] = useState(null);
    const [offer, setOffer] = useState('');
    const [onlineStatus, setOnlineStatus] = useState('');
    const [status, setStatus] = useState('');
    const [mainImage, setMainImage] = useState("/no_image2.png");
    const [mainImageFile, setMainImageFile] = useState(null);
    const [profilePicture, setProfilePicture] = useState("/no_image2.png");
    const [profilePictureFile, setProfilePictureFile] = useState(null);

    const [nameError, setNameError] = useState('');
    const [numberError, setNumberError] = useState('');
    const [locationError, setLocationError] = useState('');
    const [latitudeError, setLatitudeError] = useState('');
    const [longitudeError, setLongitudeError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [deliveryTimeError, setDeliveryTimeError] = useState('');
    const [offerError, setOfferError] = useState('');
    const [onlineStatusError, setOnlineStatusError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [mainImageError, setMainImageError] = useState('');
    const [profilePictureError, setProfilePictureError] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetch user data based on userId
        const token = localStorage.getItem('token');
        axios.put(
            `https://development.amomarket.in/api/shop/find_shop`,
            qs.stringify({ shop_id: shopId }), // Serialize data to x-www-form-urlencoded format
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded', // Set the content type to x-www-form-urlencoded
                },
            }
        )
            .then((response) => {
                const userData = response.data.data;
                setShop(userData.id);
                setName(userData.name);
                setNumber(userData.number);
                setLocation(userData.location);
                setLatitude(userData.latitude);
                setLongitude(userData.longitude);
                setDescription(userData.description);
                setDeliveryTime(userData.time);
                setOffer(userData.offer);
                setOnlineStatus(userData.online_status);
                setStatus(userData.status);
                setMainImage(userData.image ?? "/no_image2.png");
                setProfilePicture(userData.profile_picture ?? "/no_image2.png");
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    }, [shopId]);

    const handleLocationSelect = (description, latitude, longitude) => {
        setLocation(description);
        setLatitude(latitude);
        setLongitude(longitude);
        setAnchorEl(null); // Close Popover
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        navigate("/shop"); // Replace '/desired/path' with the path you want to navigate to on cancel
    };

    const fetchLocationData = async (latitude, longitude) => {
        const token = localStorage.getItem("token");
        setLoading(true);
        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`,
            );

            if (!response.ok) {
                const secondResponse = await fetch(
                    `https://development.amomarket.in/api/portal/city_location?latitude=${latitude}&longitude=${longitude}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (!secondResponse.ok) {
                    throw new Error("Error fetching current location");
                }

                const data = await secondResponse.json();
                setLocation(
                    data.data.display_name || "Unable to fetch address"
                );
                setLatitude(data.data.lat);
                setLongitude(data.data.lon);

            } else {
                const data = await response.json();
                setLocation(
                    data.display_name || "Unable to fetch address"
                );
                setLatitude(data.lat);
                setLongitude(data.lon);
            }
        } catch (error) {
            console.error("Error fetching address:", error);
            setLocation("Unable to fetch address");
        }
        setLoading(false);
    };

    const handleIconClick = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetchLocationData(latitude, longitude);
                },
                (error) => {
                    console.error("Error getting current location:", error);
                    setLocationError("Unable to get current location");
                }
            );
        } else {
            setLocationError("Geolocation is not supported by this browser.");
        }
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpenMapModal = () => {
        setOpenMapModal(true);
        setAnchorEl(null); // Close Popover
    };

    const handleCloseMapModal = () => {
        setOpenMapModal(false);
    };
    const timeRanges = [
        '10-15 min',
        '15-20 min',
        '20-25 min',
        '25-30 min',
        '30-35 min',
        '35-40 min',
        '40-45 min',
        '45-50 min',
        '50-55 min',
        '55-60 min',
    ];

    const handleUpdate = () => {
        setNameError('');
        setNumberError('');
        setLocationError('');
        setLatitudeError('');
        setLongitudeError('');
        setDescriptionError('');
        setDeliveryTimeError('');
        setOfferError('');
        setOnlineStatusError('');
        setStatusError('');
        setMainImageError('');
        setProfilePictureError('');
        setLoading(true);

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('shop_id', shop);
        formData.append('shop_name', name);
        formData.append('contact_number', number);
        formData.append('location', location);
        formData.append('latitude', latitude);
        formData.append('longitude', longitude);
        formData.append('product_description', description);
        formData.append('delivery_time', deliveryTime);
        formData.append('any_offer', offer);
        formData.append('online_status', onlineStatus);
        formData.append('status', status);
        formData.append('main_image', mainImage ?? null);
        formData.append('profile_picture', profilePicture ?? null);
        if (mainImageFile) {
            formData.append('main_image_file', mainImageFile); // Ensure this is a File object
        }
        if (profilePictureFile) {
            formData.append('profile_picture_file', profilePictureFile); // Ensure this is a File object
        }

        axios.post('https://development.amomarket.in/api/shop/update_shop', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const shopData = response.data;
                //console.log(userData);
                toast.success(shopData.message);
                setLoading(false);
                navigate('/shop'); // Adjust the path to where you want to navigate after success
            })
            .catch(error => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        if (error.response.data.errors.shop_name) {
                            setNameError(error.response.data.errors.shop_name[0]);
                            toast.error(error.response.data.errors.shop_name[0]);
                        }
                        if (error.response.data.errors.contact_number) {
                            setNumberError(error.response.data.errors.contact_number[0]);
                            toast.error(error.response.data.errors.contact_number[0]);
                        }
                        if (error.response.data.errors.location) {
                            setLocationError(error.response.data.errors.location[0]);
                            toast.error(error.response.data.errors.location[0]);
                        }
                        if (error.response.data.errors.latitude) {
                            setLatitudeError(error.response.data.errors.latitude[0]);
                            toast.error(error.response.data.errors.latitude[0]);
                        }
                        if (error.response.data.errors.longitude) {
                            setLongitudeError(error.response.data.errors.longitude[0]);
                            toast.error(error.response.data.errors.longitude[0]);
                        }
                        if (error.response.data.errors.product_description) {
                            setDescriptionError(error.response.data.errors.product_description[0]);
                            toast.error(error.response.data.errors.product_description[0]);
                        }
                        if (error.response.data.errors.delivery_time) {
                            setDeliveryTimeError(error.response.data.errors.delivery_time[0]);
                            toast.error(error.response.data.errors.delivery_time[0]);
                        }
                        if (error.response.data.errors.any_offer) {
                            setOfferError(error.response.data.errors.any_offer[0]);
                            toast.error(error.response.data.errors.any_offer[0]);
                        }
                        if (error.response.data.errors.online_status) {
                            setOnlineStatusError(error.response.data.errors.online_status[0]);
                            toast.error(error.response.data.errors.online_status[0]);
                        }
                        if (error.response.data.errors.status) {
                            setStatusError(error.response.data.errors.status[0]);
                            toast.error(error.response.data.errors.status[0]);
                        }
                        if (error.response.data.errors.main_image_file) {
                            setMainImageError(error.response.data.errors.main_image_file[0]);
                            toast.error(error.response.data.errors.main_image_file[0]);
                        }
                        if (error.response.data.errors.profile_picture_file) {
                            setProfilePictureError(error.response.data.errors.profile_picture_file[0]);
                            toast.error(error.response.data.errors.profile_picture_file[0]);
                        }
                    } else if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.error('Server error or network issue. Please try again later.');
                }
            });
    };

    const handleMainImageFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setMainImage(URL.createObjectURL(file));
                setMainImageFile(file);
                setMainImageError('');
            } else {
                setMainImageError('The file must be an image.');
            }
        }
    };
    const handleProfilePictureFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setProfilePicture(URL.createObjectURL(file));
                setProfilePictureFile(file);
                setProfilePictureError('');
            } else {
                setProfilePictureError('The file must be an image.');
            }
        }
    };

    useEffect(() => {
        return () => {
            if (mainImage.startsWith('blob:')) {
                URL.revokeObjectURL(mainImage); // Clean up URL.createObjectURL()
            }
        };
    }, [mainImage]);

    useEffect(() => {
        return () => {
            if (profilePicture.startsWith('blob:')) {
                URL.revokeObjectURL(profilePicture); // Clean up URL.createObjectURL()
            }
        };
    }, [profilePicture]);

    return (
        <Layout>
            <Box sx={{ display: "flex" }}>
                <ToastContainer />
                <MainContent>
                    <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                            <Tooltip title="Return Back">
                                <ArrowBackRoundedIcon
                                    color="disabled"
                                    onClick={handleBackClick}
                                    sx={{ cursor: "pointer", marginRight: 1 }}
                                />
                            </Tooltip>
                            <Typography sx={{
                                marginRight: { xs: "0", sm: "10px", md: "700px" },
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                                flexGrow: 1,
                                textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                            }} variant="h6">
                                Update Shop
                            </Typography>
                        </Box>
                        <Divider sx={{ marginY: 2 }} />

                        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Shop Name :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Shop Name"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            error={Boolean(nameError)}
                                            helperText={nameError}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Contact Number :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Contact Number"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={number}
                                            onChange={(e) => setNumber(e.target.value)}
                                            error={Boolean(numberError)}
                                            helperText={numberError}
                                            InputProps={{
                                                startAdornment: <Typography variant="body1" style={{ marginRight: 8 }}>+91</Typography>
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Location :
                                        </InputLabel>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Enter Shop Location"
                                            multiline
                                            type="text"
                                            rows={2}
                                            value={location}
                                            onChange={(e) => setLocation(e.target.value)}
                                            error={Boolean(locationError)}
                                            helperText={locationError}
                                            sx={{ width: '100%' }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleMenuClick} style={{ cursor: 'pointer' }}>
                                                            <MyLocationTwoToneIcon />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={anchorEl}
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleClose}
                                                            keepMounted
                                                        >
                                                            <MenuItem onClick={handleIconClick}>
                                                                <PlaceTwoToneIcon /> Use current location
                                                            </MenuItem>
                                                            <MenuItem onClick={handleOpenMapModal}>
                                                                <MapTwoToneIcon /> Choose from map
                                                            </MenuItem>
                                                        </Menu>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Latitude :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Shop Latitude"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={latitude}
                                            onChange={(e) => setLatitude(e.target.value)}
                                            error={Boolean(latitudeError)}
                                            helperText={latitudeError}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Longitude :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Shop Longitude"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={longitude}
                                            onChange={(e) => setLongitude(e.target.value)}
                                            error={Boolean(longitudeError)}
                                            helperText={longitudeError}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Product Description :
                                        </InputLabel>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Enter Product Description"
                                            multiline
                                            rows={4}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            sx={{ width: '100%' }}
                                            error={Boolean(descriptionError)}
                                            helperText={descriptionError}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Delivery Time:
                                        </InputLabel>
                                        <Autocomplete
                                            options={timeRanges}
                                            value={deliveryTime}
                                            onChange={(event, newValue) => setDeliveryTime(newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Approximate Delivery Time"
                                                    variant="outlined"
                                                    error={Boolean(deliveryTimeError)}
                                                    helperText={deliveryTimeError}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Any Offer ? :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Offer Shop providing"
                                            variant="outlined"
                                            fullWidth
                                            type="text"
                                            value={offer}
                                            onChange={(e) => setOffer(e.target.value)}
                                            error={Boolean(offerError)}
                                            helperText={offerError}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Online Status :
                                        </InputLabel>
                                        <TextField
                                            select
                                            variant="outlined"
                                            fullWidth
                                            value={onlineStatus}
                                            onChange={(e) => setOnlineStatus(e.target.value)}
                                            error={!!onlineStatusError}
                                            helperText={onlineStatusError}
                                            SelectProps={{
                                                native: true,
                                            }}
                                        >
                                            <option value="" disabled>Select Online Status</option>
                                            <option value={1}>Online</option>
                                            <option value={0}>Offline</option>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Status :
                                        </InputLabel>
                                        <TextField
                                            select
                                            variant="outlined"
                                            fullWidth
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            error={!!statusError}
                                            helperText={statusError}
                                            SelectProps={{
                                                native: true,
                                            }}
                                        >
                                            <option value="" disabled>Select Status</option>
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold", paddingRight: '160px' }} shrink>
                                    Main Image :
                                </InputLabel>
                                <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
                                    <CardContent>
                                        <Box sx={{ marginBottom: 2 }}>
                                            <img
                                                src={mainImage}
                                                alt="Main_Image"
                                                style={{ borderRadius: "5%", width: 200, height: 200, objectFit: "cover", border: '4px dotted grey' }}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop in case the fallback image also fails
                                                    e.target.src = '/no_image2.png';
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <label htmlFor="main-image-upload">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="main-image-upload"
                                                    name="main-image-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleMainImageFileChange}
                                                />
                                                <Button
                                                    sx={{ backgroundColor: 'grey', color: 'black', '&:hover': { backgroundColor: 'darkgrey' }, whiteSpace: 'nowrap' }}
                                                    variant="contained"
                                                    component="span"
                                                    startIcon={<CloudUploadTwoToneIcon />}
                                                >
                                                    Upload Main Image
                                                </Button>
                                            </label>
                                            {mainImageError && <Typography variant="body2" color="error">{mainImageError}</Typography>}
                                        </Box>
                                    </CardContent>
                                </Card>
                                <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold", paddingRight: '130px' }} shrink>
                                    Profile Picture :
                                </InputLabel>
                                <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardContent>
                                        <Box sx={{ marginBottom: 2 }}>
                                            <img
                                                src={profilePicture}
                                                alt="Profile_Picture"
                                                style={{ borderRadius: "5%", width: 230, height: 150, objectFit: "cover", border: '4px dotted grey' }}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop in case the fallback image also fails
                                                    e.target.src = '/no_image2.png';
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <label htmlFor="profile-picture-upload">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="profile-picture-upload"
                                                    name="profile-picture-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleProfilePictureFileChange}
                                                />
                                                <Button
                                                    sx={{ backgroundColor: 'grey', color: 'black', '&:hover': { backgroundColor: 'darkgrey' }, whiteSpace: 'nowrap' }}
                                                    variant="contained"
                                                    component="span"
                                                    startIcon={<CloudUploadTwoToneIcon />}
                                                >
                                                    Upload Profile Picture
                                                </Button>
                                            </label>
                                            {profilePictureError && <Typography variant="body2" color="error">{profilePictureError}</Typography>}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Divider sx={{ marginY: 2 }} />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 1 }}>
                            <Button
                                onClick={handleCancelClick}
                                color="secondary"
                                variant="outlined"
                                startIcon={<CancelTwoToneIcon />}
                                sx={{ marginRight: 2 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleUpdate}
                                color="primary"
                                variant="contained"
                                startIcon={<SendTwoToneIcon />}
                                disabled={loading}
                            >
                                {loading ? "Updating..." : "Update Shop"}
                            </Button>
                        </Box>
                    </Paper>
                </MainContent>
                <Dialog
                    open={openMapModal}
                    onClose={handleCloseMapModal}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle>Choose Location on Map</DialogTitle>
                    <DialogContent>
                        <LoadScript googleMapsApiKey={key} libraries={lib}>
                            <Map onLocationSelect={handleLocationSelect} />
                        </LoadScript>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseMapModal} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Layout>
    );
};

export default UpdateShopPage;

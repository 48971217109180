import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
// Admin Route
import WelcomePage from '../Auth/WelcomePage';
import OtpVerificationPage from '../Auth/OtpVerificationPage';
import LoginPage from '../Auth/LoginPage';
import RegisterPage from '../Auth/RegisterPage';
import ForgotPasswordPage from '../Auth/ForgotPasswordPage';
import ResetPasswordPage from '../Auth/ResetPasswordPage';
import DashboardPage from '../Home/DashboardPage';
import UserPage from '../Account/User/UserPage';
import AddUserPage from '../Account/User/AddUserPage';
import UpdateUserPage from '../Account/User/UpdateUserPage';
import RolePage from '../Account/Role/RolePage';
import AddRolePage from '../Account/Role/AddRolePage';
import UpdateRolePage from '../Account/Role/UpdateRolePage';
import PrivilegePage from '../Account/Privilege/PrivilegePage';
import AddPrivilegePage from '../Account/Privilege/AddPrivilegePage';
import UpdatePrivilegePage from '../Account/Privilege/UpdatePrivilegePage';
import ActiveLogPage from '../Account/ActiveLog/ActiveLogPage';
import CompareActiveLogPage from '../Account/ActiveLog/CompareActiveLogPage';
import ShareActiveLogPage from '../Account/ActiveLog/ShareActiveLogPage';
import ShopPage from '../Shop/ShopPage';
import AddShopPage from '../Shop/AddShopPage';
import UpdateShopPage from '../Shop/UpdateShopPage';
import CategoryPage from '../Product/Category/CategoryPage';
import AddCategoryPage from '../Product/Category/AddCategoryPage';
import UpdateCategoryPage from '../Product/Category/UpdateCategoryPage';
import SubCategoryPage from '../Product/SubCategory/SubCategoryPage';
import AddSubCategoryPage from '../Product/SubCategory/AddSubCategoryPage';
import UpdateSubCategoryPage from '../Product/SubCategory/UpdateSubCategoryPage';
import ProductPage from '../Product/Product/ProductPage';
import AddProductPage from '../Product/Product/AddProductPage';
import UpdateProductPage from '../Product/Product/UpdateProductPage';
import ProductInformationPage from '../Product/ProductInformation/ProductInformationPage';
import Sidebar from '../NewTemplate/Sidebar';
import Header from '../NewTemplate/Header';
import Layout from '../NewTemplate/Layout';
import PermissionPage from '../Permit/PermissionPage';
import PrivateRoute from './PrivateRoute';
import NotFoundPage from './NotFoundPage';


const AdminRoutes = () => {
    return (
        <Routes>
            {/* Admin public Route Path */}
            {/* <Route exact path="/admin" element={<WelcomePage />} /> */}
            {/* <Route exact path="/otp_verification" element={<OtpVerificationPage />} /> */}
            
            {/* Redirect from /admin to /login */}
            <Route path="/admin" element={<Navigate to="/login" replace />} />
            {/* Define the /login route */}
            <Route exact path="/login" element={<LoginPage />} />
            
            <Route exact path="/register" element={<RegisterPage />} />
            <Route exact path="/forgotPassword" element={<ForgotPasswordPage />} />
            <Route exact path="/api/auth/reset_password" element={<ResetPasswordPage />} />

            {/* Admin Private Routes */}
            <Route exact path="/dashboard" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
            <Route exact path="/sidebar" element={<PrivateRoute><Sidebar /></PrivateRoute>} />
            <Route exact path="/header" element={<PrivateRoute><Header /></PrivateRoute>} />
            <Route exact path="/layout" element={<PrivateRoute><Layout /></PrivateRoute>} />
            <Route exact path="/permission" element={<PrivateRoute><PermissionPage /></PrivateRoute>} />

            <Route exact path="/user" element={<PrivateRoute><UserPage /></PrivateRoute>} />
            <Route exact path="/add-user" element={<PrivateRoute><AddUserPage /></PrivateRoute>} />
            <Route exact path="/update-user/:userId" element={<PrivateRoute><UpdateUserPage /></PrivateRoute>} />

            <Route exact path="/role" element={<PrivateRoute><RolePage /></PrivateRoute>} />
            <Route exact path="/add-role" element={<PrivateRoute><AddRolePage /></PrivateRoute>} />
            <Route exact path="/update-role/:roleId" element={<PrivateRoute><UpdateRolePage /></PrivateRoute>} />

            <Route exact path="/privilege" element={<PrivateRoute><PrivilegePage /></PrivateRoute>} />
            <Route exact path="/add-privilege" element={<PrivateRoute><AddPrivilegePage /></PrivateRoute>} />
            <Route exact path="/update-privilege/:privilegeId" element={<PrivateRoute><UpdatePrivilegePage /></PrivateRoute>} />

            <Route exact path="/activelog" element={<PrivateRoute><ActiveLogPage /></PrivateRoute>} />
            <Route exact path="/compare-activelog/:activeLogId" element={<PrivateRoute><CompareActiveLogPage /></PrivateRoute>} />
            <Route exact path="/share-activelog/:activeLogId" element={<PrivateRoute><ShareActiveLogPage /></PrivateRoute>} />

            <Route exact path="/shop" element={<PrivateRoute><ShopPage /></PrivateRoute>} />
            <Route exact path="/add-shop" element={<PrivateRoute><AddShopPage /></PrivateRoute>} />
            <Route exact path="/update-shop/:shopId" element={<PrivateRoute><UpdateShopPage /></PrivateRoute>} />

            <Route exact path="/category" element={<PrivateRoute><CategoryPage /></PrivateRoute>} />
            <Route exact path="/add-category" element={<PrivateRoute><AddCategoryPage /></PrivateRoute>} />
            <Route exact path="/update-category/:categoryId" element={<PrivateRoute><UpdateCategoryPage /></PrivateRoute>} />


            <Route exact path="/subcategory" element={<PrivateRoute><SubCategoryPage /></PrivateRoute>} />
            <Route exact path="/add-subcategory" element={<PrivateRoute><AddSubCategoryPage /></PrivateRoute>} />
            <Route exact path="/update-subcategory/:subCategoryId" element={<PrivateRoute><UpdateSubCategoryPage /></PrivateRoute>} />

            <Route exact path="/product" element={<PrivateRoute><ProductPage /></PrivateRoute>} />
            <Route exact path="/add-product" element={<PrivateRoute><AddProductPage /></PrivateRoute>} />
            <Route exact path="/update-product/:productId" element={<PrivateRoute><UpdateProductPage /></PrivateRoute>} />

            <Route exact path="/product-information" element={<PrivateRoute><ProductInformationPage /></PrivateRoute>} />

            {/* Catch-all route for 404 */}
            <Route path="*" element={<NotFoundPage />} />

        </Routes>
    );
};

export default AdminRoutes;
import * as React from 'react';
import Layout from '../NewTemplate/Layout';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Skeleton } from '@mui/material';
import { CardActionArea, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  paddingTop: theme.spacing(9), // Adjust padding top to accommodate header height
  display: 'flex', // Add display flex to make children flex items
  justifyContent: 'space-around', // Evenly distribute children along the main axis
}));

// Function to convert Kelvin to Celsius
const kelvinToCelsius = (kelvin) => {
  return (kelvin - 273.15).toFixed(2); // Round to two decimal places
};

const DashboardPage = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const paramToken = searchParams.get('token');
    console.log('paramToken:', paramToken);
    if (paramToken) {
      // If token exists in query parameters, set it and clear localStorage token
      localStorage.removeItem('token');
      localStorage.setItem('token', paramToken);
      setToken(paramToken);
    } else {
      // If token does not exist in query parameters, get it from localStorage
      const localStorageToken = localStorage.getItem('token');
      console.log('localStorageToken:', localStorageToken);
      setToken(localStorageToken);
    }
  }, [searchParams]); // Only run this effect when searchParams change

  useEffect(() => {
    const fetchWeatherAndLocationData = async () => {
      if (token) {
        const getPosition = () => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                fetchWeatherData(latitude, longitude);
                fetchLocationData(latitude, longitude);
              },
              (error) => {
                console.error('Error getting user location:', error);
              }
            );
          } else {
            console.error('Geolocation is not supported by this browser.');
          }
        };
        getPosition();
      }
    };

    const fetchWeatherData = async (latitude, longitude) => {
      try {
        const response = await fetch(`https://development.amomarket.in/api/auth/weather?latitude=${latitude}&longitude=${longitude}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const weatherData = await response.json();
          setWeatherData(weatherData.data);
          setLoading(false);
        } else {
          console.error('Failed to fetch weather data');
        }
      } catch (error) {
        console.error('Error fetching weather data:', error);
      }
    };

    const fetchLocationData = async (latitude, longitude) => {
      // try {
      //   const response = await fetch(`https://development.amomarket.in/api/auth/location?latitude=${latitude}&longitude=${longitude}`, {
      //     headers: {
      //       'Authorization': `Bearer ${token}`
      //     }
      //   });
      //   if (response.ok) {
      //     const locationData = await response.json();
      //     console.log("locationData");
      //     setLocationData(locationData.data);
      //     setLoading(false);
      //   } else {
      //     console.error('Failed to fetch location data');
      //   }
      // } catch (error) {
      //   console.error('Error fetching location data:', error);
      // }
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`,
        );

        if (!response.ok) {
          const secondResponse = await fetch(
            `https://development.amomarket.in/api/user/city_location?latitude=${latitude}&longitude=${longitude}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Replace 'token' with your actual token variable
              },
            }
          );

          if (!secondResponse.ok) {
            throw new Error("Error fetching current location");
          }

          const data = await secondResponse.json();
          setLocationData(
            data || data.data || "Unable to fetch address"
          );
        } else {
          const data = await response.json();
          setLocationData(
            data || "Unable to fetch address"
          );
        }
      } catch (error) {
        console.error("Error fetching address:", error);
        setLocationData("Unable to fetch address");
      }

    };

    fetchWeatherAndLocationData();
  }, [token]); // Run this effect only when token changes

  // Define the weather icon URL
  const weatherIconUrl = weatherData ? `http://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png` : '';

  return (
    <Layout>
      <Box sx={{ display: 'flex' }}>

        <MainContent>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ width: '100%', height: '100%' }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="100%"
                    image="/weather.png"
                    alt="weather"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Weather Report
                    </Typography>
                    {loading ? (
                      <>
                        <Skeleton height={40} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                      </>
                    ) : (
                      weatherData && (
                        <>
                          <Typography variant="body1" color="text.secondary">
                            Weather: {weatherData.weather[0].description}{' '}
                            <img
                              src={weatherIconUrl}
                              alt="weather_icon"
                              style={{ maxWidth: '15%' }}
                            />
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            Temperature: {kelvinToCelsius(weatherData.main.feels_like)}°C
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            Humidity: {weatherData.main.humidity}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            Wind Speed: {weatherData.wind.speed}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            Wind deg: {weatherData.wind.deg}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            latitude: {weatherData.coord.lat}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            longitude: {weatherData.coord.lon}
                          </Typography>
                        </>
                      )
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ width: '100%', height: '100%' }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="100%"
                    image="/location.png"
                    alt="Location"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Location Details
                    </Typography>
                    {loading ? (
                      <>
                        <Skeleton height={40} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                      </>
                    ) : (
                      locationData && (
                        <>
                          <Typography variant="body1" color="text.secondary">
                            Address Type: {locationData.addresstype}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            Road: {locationData.address.road}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            Neighbourhood: {locationData.address.neighbourhood}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            Suburb: {locationData.address.suburb}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            County: {locationData.address.county}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            State_district: {locationData.address.state_district}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            Postcode: {locationData.address.postcode}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            Country: {locationData.address.country}
                          </Typography>
                        </>
                      )
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ width: '100%', height: '100%' }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="100%"
                    image="/datareport.png"
                    alt="datareport"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Data Report
                    </Typography>
                    <>
                      <Skeleton height={40} />
                      <Skeleton height={30} />
                      <Skeleton height={30} />
                      <Skeleton height={30} />
                      <Skeleton height={30} />
                      <Skeleton height={30} />
                      <Skeleton height={30} />
                    </>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </MainContent>
      </Box>
    </Layout>
  );
};

export default DashboardPage;

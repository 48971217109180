import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MapIcon from "@mui/icons-material/Map";
import { LoadScript } from "@react-google-maps/api";
import Map from "./Map";

const lib = ["places"];
const key = "AIzaSyA9MaTVJlWIWpINjcgyJl5eS6JDhe60238"; // Replace with your actual API key

const Location = ({ onLocationSelect }) => {

  const [location, setLocation] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentAddress, setCurrentAddress] = useState("");
  const [loading, setLoading] = useState(true);
  const [openMapModal, setOpenMapModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLocationSelect = (description) => {
    setLocation(description);
    setAnchorEl(null); // Close Popover
    setIsDropdownOpen(false); // Close Dropdown
  };

  const fetchCurrentLocation = async () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          let response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
          );
    
          if (!response.ok) {
            response = await fetch(
              `https://development.amomarket.in/api/portal/city_location?latitude=${latitude}&longitude=${longitude}`
            );
    
            if (!response.ok) {
              throw new Error("Error fetching current location");
            }
          }
    
          const data = await response.json();
          setCurrentAddress(
            data.display_name || data.data?.display_name || "Unable to fetch address"
          );
        } catch (error) {
          console.error("Error fetching address:", error);
          setCurrentAddress("Unable to fetch address");
        } finally {
          setLoading(false);
          setAnchorEl(null); // Close Popover
          setIsDropdownOpen(false); // Close Dropdown
        }
      },
      (error) => {
        console.error("Error getting current location:", error);
        setLoading(false);
        setAnchorEl(null); // Close Popover
        setIsDropdownOpen(false); // Close Dropdown
      }
    );    
  };

  const fetchGeocodeLocation = async () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${key}`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch address");
          }
          const data = await response.json();
          if (data.results && data.results.length > 0) {
            setCurrentAddress(data.results[0].formatted_address);
          } else {
            setCurrentAddress("Unable to fetch address");
          }
        } catch (error) {
          console.error("Error fetching address:", error);
          setCurrentAddress("Unable to fetch address");
        } finally {
          setLoading(false);
          setAnchorEl(null); // Close Popover
          setIsDropdownOpen(false); // Close Dropdown
        }
      },
      (error) => {
        console.error("Error getting current location:", error);
        setLoading(false);
        setAnchorEl(null); // Close Popover
        setIsDropdownOpen(false); // Close Dropdown
      }
    );
  };

  useEffect(() => {
    fetchCurrentLocation();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsDropdownOpen((prev) => !prev); // Toggle Dropdown
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsDropdownOpen(false); // Close Dropdown
  };

  const handleCurrentLocationClick = () => {
    setLoading(true);
    fetchGeocodeLocation();
  };

  const handleOpenMapModal = () => {
    setOpenMapModal(true);
    setAnchorEl(null); // Close Popover
    setIsDropdownOpen(false); // Close Dropdown
  };

  const handleCloseMapModal = () => {
    setOpenMapModal(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            style: { borderRadius: 20, width: 340, padding: "20px 20px" },
          }}
        >
          <div>
            <Typography
              variant="h6"
              style={{ fontWeight: "bold", fontSize: 17, paddingBottom:"15px" }}
            >
              Select delivery location
            </Typography>
            <Tooltip title="Use Current Location">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<LocationOnIcon />}
                onClick={handleCurrentLocationClick}
                style={{
                  borderRadius: 15,
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: 12,
                  marginBottom: 10,
                  width: "100%",
                }}
              >
                Current Location
              </Button>
            </Tooltip>
            <Tooltip title="Pick location from map">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<MapIcon />}
                onClick={handleOpenMapModal}
                style={{
                  borderRadius: 15,
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: 12,
                  marginBottom: 10,
                  width: "100%",
                }}
              >
                Select From Map
              </Button>
            </Tooltip>
          </div>
        </Popover>
        <div style={{ marginLeft: 10 }}>
          <Typography
            variant="body1"
            noWrap
            style={{ fontWeight: "bold", marginTop: 10, color:"black" }}
          >
            Delivery in 10 minutes
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" noWrap style={{ color: "#212121", marginRight: 8 }}>
              {loading ? (
                <Skeleton width={200} />
              ) : (
                <Tooltip
                  title={
                    location || currentAddress || "Unable to fetch address"
                  }
                  arrow
                >
                  <span>
                    {truncate(
                      location || currentAddress || "Unable to fetch address",
                      30
                    )}
                  </span>
                </Tooltip>
              )}
            </Typography>
            {isDropdownOpen ? (
              <ArrowDropUpIcon 
                style={{ color: "#000", cursor: "pointer" }}
                onClick={handleClick}
              />
            ) : (
              <ArrowDropDownIcon 
                style={{ color: "#000", cursor: "pointer" }}
                onClick={handleClick}
              />
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={openMapModal}
        onClose={handleCloseMapModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Choose Location on Map</DialogTitle>
        <DialogContent>
          <LoadScript googleMapsApiKey={key} libraries={lib}>
            <Map onLocationSelect={handleLocationSelect} />
          </LoadScript>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMapModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Location;

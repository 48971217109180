import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import EventSeatTwoToneIcon from '@mui/icons-material/EventSeatTwoTone';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CategoryIcon from '@mui/icons-material/Category';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import AllInboxTwoToneIcon from '@mui/icons-material/AllInboxTwoTone';
import { ExpandLess, ExpandMore, BarChart as BarChartIcon, TableChart as TableChartIcon, Person as PersonIcon, Assignment as AssignmentIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';

const drawerWidth = 240;
const collapsedWidth = 60;

const StyledDrawer = styled(Drawer)(({ theme, collapsed }) => ({
  width: collapsed ? collapsedWidth : drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: collapsed ? collapsedWidth : drawerWidth,
    backgroundColor: '#f9f9f9',
    top: '64px',
    overflowY: 'auto',
    // Scrollbar styles
    '&::-webkit-scrollbar': {
      width: '4px', // Decrease the width of the scrollbar
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'linear-gradient(to right, #c8d0d8, #a3a4a4)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1', // Scrollbar track color
    },
  },
}));

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: '#dbbef1',
    color: '#10d915',
    '& .MuiListItemIcon-root': {
      color: '#10d915',
    },
  }),
  borderRadius: '8px',
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: '#dbbef1',
  },
}));

function Sidebar({ open, collapsed }) {
  const [expandedMenu, setExpandedMenu] = React.useState(null);
  const [roleId, setRoleId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const role = localStorage.getItem('role_id');
    setRoleId(role);  // Save role from localStorage
  }, []);

  const handleListItemClick = (path) => {
    navigate(path);
  };

  const handleMenuClick = (menu) => {
    setExpandedMenu(expandedMenu === menu ? null : menu);
  };

  React.useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/user') || path.startsWith('/role') || path.startsWith('/privilege') || path.startsWith('/activelog')) {
      setExpandedMenu('account');
    } else if (path.startsWith('/category') || path.startsWith('/subcategory') || path.startsWith('/product') || path.startsWith('/product-information')) {
      setExpandedMenu('products');
    } else {
      setExpandedMenu(null);
    }
  }, [location.pathname]);

  const isSubmenuSelected = (submenuPaths) => {
    return submenuPaths.some((submenuPath) => location.pathname.startsWith(submenuPath));
  };

  return (
    <StyledDrawer variant="persistent" open={open} collapsed={collapsed}>
    {roleId === '1' ? (
      <List>
        <StyledListItem
          button
          selected={location.pathname === '/dashboard'}
          onClick={() => handleListItemClick('/dashboard')}
        >
          <ListItemIcon>
            <SpaceDashboardIcon />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Dashboard" />}
        </StyledListItem>

        <StyledListItem
          button
          selected={isSubmenuSelected(['/user', '/role', '/privilege', '/activelog'])}
          onClick={() => handleMenuClick('account')}
        >
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          {!collapsed && (
            <>
              <ListItemText primary="Account" />
              {expandedMenu === 'account' ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </StyledListItem>
        <Collapse in={expandedMenu === 'account'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/user'}
              onClick={() => handleListItemClick('/user')}
            >
              <ListItemIcon>
                <SwitchAccountIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Users" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/role'}
              onClick={() => handleListItemClick('/role')}
            >
              <ListItemIcon>
                <EventSeatTwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Roles" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/privilege'}
              onClick={() => handleListItemClick('/privilege')}
            >
              <ListItemIcon>
                <AdminPanelSettingsTwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Privileges" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/activelog'}
              onClick={() => handleListItemClick('/activelog')}
            >
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Active Log" />}
            </StyledListItem>
          </List>
        </Collapse>

        <StyledListItem
          button
          selected={location.pathname === '/shop'}
          onClick={() => handleListItemClick('/shop')}
        >
          <ListItemIcon>
            <StorefrontIcon />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Shops" />}
        </StyledListItem>

        <StyledListItem
          button
          selected={isSubmenuSelected(['/category', '/subcategory', '/product', '/product-information'])}
          onClick={() => handleMenuClick('products')}
        >
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          {!collapsed && (
            <>
              <ListItemText primary="Products" />
              {expandedMenu === 'products' ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </StyledListItem>
        <Collapse in={expandedMenu === 'products'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/category'}
              onClick={() => handleListItemClick('/category')}
            >
              <ListItemIcon>
                <VideoLabelIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Category" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/subcategory'}
              onClick={() => handleListItemClick('/subcategory')}
            >
              <ListItemIcon>
                <DynamicFeedIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Subcategory" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/product'}
              onClick={() => handleListItemClick('/product')}
            >
              <ListItemIcon>
                <Inventory2TwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Product" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/product-information'}
              onClick={() => handleListItemClick('/product-information')}
            >
              <ListItemIcon>
                <AllInboxTwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="ProductInfo" />}
            </StyledListItem>
          </List>
        </Collapse>

        <StyledListItem
          button
          selected={location.pathname === '/charts'}
          onClick={() => handleListItemClick('/charts')}
        >
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Charts" />}
        </StyledListItem>

        <StyledListItem
          button
          selected={location.pathname === '/tables'}
          onClick={() => handleListItemClick('/tables')}
        >
          <ListItemIcon>
            <TableChartIcon />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Tables" />}
        </StyledListItem>

        <StyledListItem
          button
          selected={location.pathname === '/icons'}
          onClick={() => handleListItemClick('/icons')}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Icons" />}
        </StyledListItem>

        <StyledListItem
          button
          selected={location.pathname === '/form'}
          onClick={() => handleListItemClick('/form')}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Form" />}
        </StyledListItem>
      </List>
    ) : null}
    </StyledDrawer>
  );
}

export default Sidebar;

import React from 'react';
import Header from './Header';
import Banner from './Banner';
import Category from './Category';
import RecommendShop from './RecommendShop';
import Products from './Products';
import Footer from './Footer';
import Advisement from './Advisement';

function Main() {
    return (
      <div className="Main">
        <Header />
        <Banner />
        <Advisement/>
        <Category />
        <RecommendShop />
        <Products />
        <Footer />
        
      </div>
    );
  }
  
  export default Main;
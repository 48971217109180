import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Typography, Card, CardContent, CardMedia, Button, IconButton, Skeleton } from '@mui/material';
import { AccessTime, Add, Remove, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { styled } from '@mui/system';
import { addToCart } from './Cart/cartService'; // Import the addToCart function from the service

const StyledCard = styled(Card)(({ theme }) => ({
  height: '280px',
  display: 'flex',
  margin: '8px 4px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  position: 'relative',
  minWidth: '200px',
  cursor: 'pointer',
}));

const ScrollContainer = styled('div')({
  display: 'flex',
  overflowX: 'auto',
  paddingBottom: '10px',
  position: 'relative',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: '#fff',
  zIndex: 2,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

const Products = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState({});
  const [loading, setLoading] = useState(true);
  const [scrollPosition, setScrollPosition] = useState({});
  const scrollRefs = useRef({});

  useEffect(() => {
    fetch('https://development.amomarket.in/api/portal/products')
      .then(response => response.json())
      .then(data => {
        setProducts(data.data);
        setLoading(false);
        const initialScrollPositions = Object.keys(data.data).reduce((acc, category) => {
          acc[category] = 0;
          return acc;
        }, {});
        setScrollPosition(initialScrollPositions);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
        setLoading(false);
      });
  }, []);

  const handleScroll = (categoryName, direction) => {
    const scrollContainer = scrollRefs.current[categoryName];
    const scrollAmount = 200;
    const newScrollPosition = direction === 'left'
      ? scrollPosition[categoryName] - scrollAmount
      : scrollPosition[categoryName] + scrollAmount;

    scrollContainer.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth',
    });

    setScrollPosition(prev => ({
      ...prev,
      [categoryName]: newScrollPosition,
    }));
  };

  const handleCardClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };

  const handleAdd = (id) => {
    setProducts(prevSubcategories =>
      Object.keys(prevSubcategories).reduce((acc, category) => {
        acc[category] = prevSubcategories[category].map(subcategory =>
          subcategory.id === id ? { ...subcategory, count: 1 } : subcategory
        );
        return acc;
      }, {})
    );
    addToCart(id, 1); // Use the imported addToCart function
  };

  const handleIncrease = (id) => {
    setProducts(prevSubcategories =>
      Object.keys(prevSubcategories).reduce((acc, category) => {
        acc[category] = prevSubcategories[category].map(subcategory =>
          subcategory.id === id ? { ...subcategory, count: subcategory.count + 1 } : subcategory
        );
        return acc;
      }, {})
    );
    addToCart(id, 1); // Increment quantity in cart
  };

  const handleDecrease = (id) => {
    setProducts(prevSubcategories =>
      Object.keys(prevSubcategories).reduce((acc, category) => {
        acc[category] = prevSubcategories[category].map(subcategory =>
          subcategory.id === id && subcategory.count > 0 ? { ...subcategory, count: subcategory.count - 1 } : subcategory
        );
        return acc;
      }, {})
    );
    addToCart(id, -1); // Decrease quantity in cart
  };

  return (
    <Container style={{ marginTop: '30px', marginBottom: '15px', maxWidth: '99%' }}>
      {Object.keys(products).map(categoryName => {
        const firstSubcategory = products[categoryName][0];
        const categoryId = firstSubcategory?.sub_category?.category?.id ?? null;

        return (
          <div key={categoryName} style={{ marginBottom: '30px', position: 'relative' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" gutterBottom style={{ marginBottom: '15px', fontWeight: 'bold' }}>
                {categoryName}
              </Typography>
              {categoryId && (
                <Link to={`/all_product/${categoryId}`} style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}>
                  See All
                </Link>
              )}
            </div>
            <div style={{ position: 'relative' }}>
              {scrollPosition[categoryName] > 0 && (
                <ArrowButton onClick={() => handleScroll(categoryName, 'left')} style={{ left: 0 }}>
                  <ArrowBackIos />
                </ArrowButton>
              )}
              <ScrollContainer ref={el => (scrollRefs.current[categoryName] = el)}>
                {loading ? (
                  Array.from(new Array(5)).map((_, index) => (
                    <StyledCard key={index}>
                      <Skeleton variant="rectangular" height={120} />
                      <CardContent style={{ padding: '10px', flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Skeleton variant="text" height={24} width="80%" />
                        <Skeleton variant="text" height={20} width="60%" />
                        <Skeleton variant="text" height={20} width="60%" />
                        <Skeleton variant="text" height={36} width="50%" style={{ marginTop: '8px' }} />
                      </CardContent>
                    </StyledCard>
                  ))
                ) : (
                  products[categoryName].map(subcategory => (
                    <StyledCard key={subcategory.id} onClick={() => handleCardClick(subcategory.id)}>
                      {subcategory.discount && (
                        <Typography
                          style={{
                            position: 'absolute',
                            background: 'rgba(0, 123, 255, 0.9)',
                            color: 'white',
                            padding: '2px 5px',
                            borderRadius: '0 0 5px 5px',
                            top: 0,
                            right: 0,
                          }}
                        >
                          {subcategory.discount} OFF
                        </Typography>
                      )}
                      <CardMedia
                        component="img"
                        height="120"
                        image={subcategory.image}
                        alt={subcategory.name}
                        style={{ objectFit: 'contain' }}
                      />
                      <CardContent style={{ padding: '10px', flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                          <Typography variant="body2" color="textSecondary" style={{ lineHeight: '24px', display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            <AccessTime style={{ marginRight: '5px', fontSize: '20px', verticalAlign: 'sub' }} /> {subcategory.delivery_time} min.
                          </Typography>
                          <Typography variant="body2" component="div" style={{ fontWeight: 'bold' }}>
                            {subcategory.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {subcategory.weight}
                          </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }}>
                          <Typography variant="body2" color="text.primary">
                            ₹{subcategory.price}
                          </Typography>
                          {subcategory.count > 0 ? (
                            <div
                              style={{ display: 'flex', alignItems: 'center', background: 'green', borderRadius: '4px', padding: '2px 4px', color: 'white' }}
                              onClick={(e) => e.stopPropagation()} // Prevent card click when interacting with the buttons
                            >
                              <IconButton size="small" onClick={() => handleDecrease(subcategory.id)} style={{ color: 'white', padding: '4px' }}>
                                <Remove />
                              </IconButton>
                              <Typography variant="body2" component="div" style={{ margin: '0 8px', color: 'white' }}>
                                {subcategory.count}
                              </Typography>
                              <IconButton size="small" onClick={() => handleIncrease(subcategory.id)} style={{ color: 'white', padding: '4px' }}>
                                <Add />
                              </IconButton>
                            </div>
                          ) : (
                            <Button
                              variant="outlined"
                              size="small"
                              color="success"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent card click when clicking Add button
                                handleAdd(subcategory.id);
                              }}
                              style={{ height: '36px', minWidth: '64px' }}
                            >
                              Add
                            </Button>
                          )}
                        </div>
                      </CardContent>
                    </StyledCard>
                  ))
                )}
              </ScrollContainer>
              {scrollPosition[categoryName] < scrollRefs.current[categoryName]?.scrollWidth - scrollRefs.current[categoryName]?.clientWidth && (
                <ArrowButton onClick={() => handleScroll(categoryName, 'right')} style={{ right: 0 }}>
                  <ArrowForwardIos />
                </ArrowButton>
              )}
            </div>
          </div>
        );
      })}
    </Container>
  );
};

export default Products;

import React, { useState } from 'react';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from '@mui/icons-material/Facebook';
import { ToastContainer, toast } from "react-toastify";
import OTPVerificationModal from "./OTPVerificationModal";

const LoginModal = ({ open, onClose }) => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);

  const handleGoogleLogin = () => {
    try {
      window.location.href = "https://development.amomarket.in/api/auth/google";
    } catch (error) {
      console.error("Error while logging in with Google:", error);
    }
  }

  const handleFacebookLogin = () => {
    try {
      window.location.href = "https://development.amomarket.in/api/auth/facebook";
    } catch (error) {
      console.error("Error while logging in with Google:", error);
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setNameError("");
    setNumberError("");
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://development.amomarket.in/api/auth/auth_mobile",
        { name, number }
      );

      console.log("Response from server:", response.data);
      toast.success(response.data.message);
      localStorage.setItem("token", response.data.token);
      onClose(false);
      setOtpOpen(true);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        if (error.response.data.errors.name) {
          setNameError(error.response.data.errors.name[0]);
          toast.error(error.response.data.errors.name[0]);
        }
        if (error.response.data.errors.number) {
          setNumberError(error.response.data.errors.number[0]);
          toast.error(error.response.data.errors.number[0]);
        }
      } else {
        toast.error(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* Mobile Verification Dialog */}
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        sx={{ borderRadius: 6 }} // Increase border radius
        PaperProps={{ style: { borderRadius: '15px' } }}
      >
        <ToastContainer />
        <DialogTitle sx={{ textAlign: 'center' }}>
          <Typography variant="h6" fontWeight="bold">India's 10 minute app</Typography>
          <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleLogin}>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 0 }}>
            <Typography variant="body1" gutterBottom>
              Log in or Sign up
            </Typography>

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Enter your name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!!nameError}
              helperText={nameError}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="mobile"
              label="Enter mobile number"
              type="tel"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              error={!!numberError}
              helperText={numberError}
              InputProps={{
                startAdornment: <Typography variant="body1" style={{ marginRight: 8 }}>+91</Typography>
              }}
            />
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center', marginLeft: '18px', marginRight: '18px' }}>
            <Button
              type="submit"
              variant="contained"
              color="success"
              fullWidth
              size="large"
              sx={{ borderRadius: "4px", height: 50 }}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Continue"}
            </Button>
          </DialogActions>
        </form>

        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          mt={1}
          mb={1}
          px={2}
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: "50%",
              padding: 0,
              width: 65,
              height: 65,
              borderColor: "#e53935",
              color: "#e53935",
              '&:hover': {
                borderColor: "#e53935",
                backgroundColor: "rgba(255, 0, 0, 0.1)"
              }
            }}
            onClick={handleGoogleLogin}
          >
            <GoogleIcon fontSize="large" sx={{ color: "#e53935" }} />
          </Button>

          <Button
            variant="outlined"
            sx={{ borderRadius: "50%", padding: 0, width: 65, height: 65 }}
            onClick={handleFacebookLogin}
          >
            <FacebookIcon fontSize="large" />
          </Button>
        </Box>

        <div style={{ padding: 16, textAlign: 'center' }}>
          <Typography variant="body2">
            By continuing, you agree to our <button style={{ background: 'none', border: 'none', color: 'grey', textDecoration: 'underline', cursor: 'pointer' }}>Terms of Service</button> & <button style={{ background: 'none', border: 'none', color: 'grey', textDecoration: 'underline', cursor: 'pointer' }}>Privacy Policy</button>
          </Typography>
        </div>
      </Dialog>

      {/* OTP Verification Dialog */}
      <OTPVerificationModal
        otpOpen={otpOpen}
        setOtpOpen={setOtpOpen}
        mobileNumber={number} // Passing the mobile number for display
      />
    </>
  );
};

export default LoginModal;

import React, { useState, useEffect } from 'react';
import { Drawer, Box, Tabs, Tab, Divider, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Avatar, Typography, Tooltip, Button, TextField, MenuItem} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ChangeCircleTwoToneIcon from '@mui/icons-material/ChangeCircleTwoTone';
import MarkAsUnreadTwoToneIcon from '@mui/icons-material/MarkAsUnreadTwoTone';
import PermIdentityTwoToneIcon from '@mui/icons-material/PermIdentityTwoTone';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Conversation = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [todos, setTodos] = useState([]);
  const [loading, setLoading] = useState(true);

  // State for text fields
  const [newTitle, setNewTitle] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [isCompleted, setIsCompleted] = useState(0);
  const [editingTodoId, setEditingTodoId] = useState(null);

  const toggleChatDrawer = () => {
    setIsChatOpen((prev) => !prev);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleUpdateTodo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://development.amomarket.in/api/update_todos',
        {
          id: editingTodoId,
          title: newTitle,
          description: newDescription,
          is_completed: isCompleted,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status) {
        const updatedTodo = response.data.data;
        setTodos((prevTodos) =>
          prevTodos.map((todo) =>
            todo.id === updatedTodo.id ? updatedTodo : todo
          )
        );
        toast.success(response.data.message);
      } else {
        console.error('Failed to update todo:', response.data.message);
      }
    } catch (error) {
      console.error('Error updating todo:', error);
    }
  };

  const handleDelete = async (todo) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`https://development.amomarket.in/api/todos/destroy/${todo.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.status) {
        setTodos(todos.filter(item => item.id !== todo.id));
        toast.success('Todo deleted successfully');
      } else {
        toast.error('Failed to delete todo');
      }
    } catch (error) {
      toast.error('Error deleting todo');
      console.error('Error deleting todo:', error);
    }
  };

  const handleEdit = async (todo) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://development.amomarket.in/api/todos/${todo.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      if (response.data.status) {
        setEditingTodoId(response.data.data.id);
        setNewTitle(response.data.data.title);
        setNewDescription(response.data.data.description);
        setIsCompleted(response.data.data.is_completed);
      } else {
        toast.error('Failed to update todo');
      }
    } catch (error) {
      toast.error('Error updating todo');
      console.error('Error updating todo:', error);
    }
  };

  const handleAddTodo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://development.amomarket.in/api/todos',
        {
          title: newTitle,
          description: newDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status) {
        setTodos([...todos, response.data.data]);
        setNewTitle(''); // Clear the input fields after adding
        setNewDescription('');
        toast.success(response.data.message);
      } else {
        console.error('Failed to add todo:', response.data.message);
      }
    } catch (error) {
      console.error('Error adding todo:', error);
    }
  };

  useEffect(() => {
    const fetchTodos = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://development.amomarket.in/api/todos', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.status) {
          setTodos(response.data.data);
        } else {
          console.error('Failed to fetch todos:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching todos:', error);
      } finally {
        setLoading(false);
      }
    };

    if (activeTab === 0) {
      fetchTodos();
    }
  }, [activeTab]);

  const fetchNotifications = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://development.amomarket.in/api/notifications/unread', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setNotifications(response.data.notifications);
    } catch (error) {
      console.error('Failed to fetch notifications', error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (activeTab === 2) {
      fetchNotifications(); // Now accessible here
    }
  }, [activeTab]);

  
  const handleEditButtonClick = (userId) => {
    navigate(`/update-user/${userId}`); // Navigate to UpdateUserPage with the user ID
  };

  const handleMarkNotificationAsRead = async (notificationId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `https://development.amomarket.in/api/notifications/read/${notificationId}`,
        {}, // Empty body, since you're only passing headers
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass token here correctly
          },
        }
      );
  
      if (response.status === 200) {
        fetchNotifications();
        toast.success(response.data.message);
      } else {
        console.error('Error marking notification as read');
      }
    } catch (error) {
      toast.error('Error marking notification as read');
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <>
      <IconButton color="inherit" onClick={toggleChatDrawer}>
        <MoreVertIcon />
      </IconButton>
      <ToastContainer />
      <Drawer
        anchor="right"
        open={isChatOpen}
        onClose={toggleChatDrawer}
        sx={{ '& .MuiDrawer-paper': { width: 360 } }}
      >
        <Box mt={7} p={2} role="presentation">
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="chat or to-do tabs">
            <Tab label="To Do List" />
            <Tab label="Chats" />
            <Tab label="Notification" />
          </Tabs>
          <Divider />
          {activeTab === 0 && (
            <Box>
              {!editingTodoId && (
                <>
                  <Box mb={2} mt={1}>
                    <TextField
                      label="Title"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                      sx={{ mb: 1 }}
                    />
                    <TextField
                      label="Description"
                      variant="outlined"
                      fullWidth
                      value={newDescription}
                      onChange={(e) => setNewDescription(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: "bold" }} variant="body">To Do List</Typography>
                    <Tooltip title="Add New Todo">
                      <Button
                        variant="outlined"
                        color="success"
                        size="small"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleAddTodo}
                      >
                        Add
                      </Button>
                    </Tooltip>
                  </Box>
                </>
              )}

              {editingTodoId && (
                <>
                  <Box mb={2} mt={1}>
                    <TextField
                      label="Title"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                      sx={{ mb: 1 }}
                    />
                    <TextField
                      label="Description"
                      variant="outlined"
                      fullWidth
                      value={newDescription}
                      onChange={(e) => setNewDescription(e.target.value)}
                    />
                    <TextField
                      label="Is Completed"
                      variant="outlined"
                      select
                      fullWidth
                      size='small'
                      value={isCompleted}
                      onChange={(e) => setIsCompleted(e.target.value)}
                      sx={{ mt: 1 }}
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </TextField>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: "bold" }} variant="body">To Do List</Typography>
                    <Tooltip title="Update Todo">
                      <Button
                        variant="outlined"
                        color="success"
                        size="small"
                        startIcon={<ChangeCircleTwoToneIcon />}
                        onClick={handleUpdateTodo}
                      >
                        Update
                      </Button>
                    </Tooltip>
                  </Box>
                </>
              )}

              <List>
                {loading ? (
                  <ListItem>
                    <ListItemText primary="Loading..." />
                  </ListItem>
                ) : (
                  todos.map(todo => (
                    <ListItem key={todo.id} button>
                      <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                        <ListItemText
                          primary={
                            <Tooltip title={todo.title || 'No Title'} arrow>
                              <span>
                                {todo.title ? (todo.title.length > 10 ? `${todo.title.substring(0, 10)}...` : todo.title) : 'No Title'}
                              </span>
                            </Tooltip>
                          }
                          secondary={
                            <Tooltip title={todo.description || 'No Description'} arrow>
                              <span>
                                {todo.description ? (todo.description.length > 20 ? `${todo.description.substring(0, 20)}...` : todo.description) : 'No Description'}
                              </span>
                            </Tooltip>
                          }
                          sx={{ flex: 1, textDecoration: todo.is_completed ? 'line-through' : 'none' }} // Line-through if completed
                        />
                        <ListItemSecondaryAction sx={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip title="Edit Todo">
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              sx={{
                                borderRadius: '50%',
                                minWidth: '32px',
                                minHeight: '32px',
                                width: '32px',
                                height: '32px',
                                padding: 0,
                                margin: '0 1px',
                              }}
                              onClick={() => handleEdit(todo)}
                            >
                              <EditTwoToneIcon fontSize="small" />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete Todo">
                            <Button
                              variant="outlined"
                              color="error"
                              size="small"
                              sx={{
                                borderRadius: '50%',
                                minWidth: '32px',
                                minHeight: '32px',
                                width: '32px',
                                height: '32px',
                                padding: 0,
                                margin: '0 1px',
                              }}
                              onClick={() => handleDelete(todo)}
                            >
                              <DeleteTwoToneIcon fontSize="small" />
                            </Button>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </Box>
                    </ListItem>
                  ))
                )}
              </List>
            </Box>
          )}
          {activeTab === 1 && (
            <List>
              <ListItem>
                <Avatar alt="avatar" src="/avatar.jpg" />
                <ListItemText primary="sameer" secondary="hello" />
                <Typography variant="body2" color="text.secondary">
                  8-28_2024
                </Typography>
              </ListItem>
            </List>
          )}
          {activeTab === 2 && (
            <Box sx={{ width: '100%', maxWidth: 700, margin: 'auto', textAlign: 'center' }}>
              <Typography variant="h6" mt={1} gutterBottom>
                Unread Notifications
              </Typography>
              <List>
                {notifications.length === 0 ? (
                  <ListItem sx={{ width: '100%', maxWidth: 700, margin: 'auto', textAlign: 'center' }}>
                    <ListItemText primary="No unread notifications" />
                  </ListItem>
                ) : (
                  notifications.map((notification) => (
                    <ListItem
                      key={notification.id}
                      sx={{ backgroundColor: '#ffff0085', marginBottom: 1, borderRadius: 3 }}
                    >
                      <ListItemText
                        primary={notification.data.title}
                        secondary={`Received at: ${new Date(notification.created_at).toLocaleString()}`}
                      />
                      <ListItemSecondaryAction
                        sx={{
                          display: 'flex',
                          flexDirection: 'column', // Change the direction to column for vertical alignment
                          alignItems: 'center', // Center the buttons horizontally
                        }}
                      >
                        <Tooltip title="Mark Notification As Read">
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{
                              borderRadius: '50%',
                              minWidth: '32px',
                              minHeight: '32px',
                              width: '32px',
                              height: '32px',
                              padding: 0,
                              margin: '5px 0', // Add vertical margin between the buttons
                            }}
                            onClick={() => handleMarkNotificationAsRead(notification.id)}
                          >
                            <MarkAsUnreadTwoToneIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Account Activate Now">
                          <Button
                            variant="outlined"
                            color="success"
                            size="small"
                            sx={{
                              borderRadius: '50%',
                              minWidth: '32px',
                              minHeight: '32px',
                              width: '32px',
                              height: '32px',
                              padding: 0,
                              margin: '5px 0', // Same vertical margin
                            }}
                            onClick={() => handleEditButtonClick(notification.data.user_id)}
                          >
                            <PermIdentityTwoToneIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                )}
              </List>
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default Conversation;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Layout from "../../NewTemplate/Layout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from "qs";

const MainContent = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(9),
    display: "flex",
    flexDirection: "column",
}));

const UpdateSubCategoryPage = () => {
    const navigate = useNavigate();
    const { subCategoryId } = useParams();
    const [name, setName] = useState('');
    const [subCategory, setSubCategory] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [categories, setCategories] = useState([]);
    const [description, setDescription] = useState('');
    const [productCode, setProductCode] = useState('');
    const [status, setStatus] = useState('');
    const [image, setImage] = useState("/no_image2.png");
    const [imageFile, setImageFile] = useState(null);

    const [nameError, setNameError] = useState('');
    const [categoryNameError, setCategoryNameError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [productCodeError, setProductCodeError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [imageError, setImageError] = useState('');

    const [loading, setLoading] = useState(false);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        navigate("/subcategory"); // Replace '/desired/path' with the path you want to navigate to on cancel
    };

    const handleSubmit = () => {
        setNameError('');
        setCategoryNameError('');
        setDescriptionError('');
        setStatusError('');
        setProductCodeError('');
        setImageError('');
        setLoading(true);

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('subcategory_id', subCategory);
        formData.append('subcategory_name', name);
        formData.append('category', categoryName);
        formData.append('description', description);
        formData.append('product_code', productCode);
        formData.append('status', status);
        if (imageFile) {
            formData.append('image_file', imageFile); // Ensure this is a File object
        }

        axios.post('https://development.amomarket.in/api/subcategory/update_subcategory', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const subCategoryData = response.data;
                //console.log(userData);
                toast.success(subCategoryData.message);
                setLoading(false);
                navigate('/subcategory'); // Adjust the path to where you want to navigate after success
            })
            .catch(error => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        if (error.response.data.errors.subcategory_name) {
                            setNameError(error.response.data.errors.subcategory_name[0]);
                            toast.error(error.response.data.errors.subcategory_name[0]);
                        }
                        if (error.response.data.errors.category) {
                            setCategoryNameError(error.response.data.errors.category[0]);
                            toast.error(error.response.data.errors.category[0]);
                        }
                        if (error.response.data.errors.description) {
                            setDescriptionError(error.response.data.errors.description[0]);
                            toast.error(error.response.data.errors.description[0]);
                        }
                        if (error.response.data.errors.product_code) {
                            setProductCodeError(error.response.data.errors.product_code[0]);
                            toast.error(error.response.data.errors.product_code[0]);
                        }
                        if (error.response.data.errors.status) {
                            setStatusError(error.response.data.errors.status[0]);
                            toast.error(error.response.data.errors.status[0]);
                        }
                        if (error.response.data.errors.image_file) {
                            setImageError(error.response.data.errors.image_file[0]);
                            toast.error(error.response.data.errors.image_file[0]);
                        }
                    } else if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.error('Server error or network issue. Please try again later.');
                }
            });
    };

    const handleImageFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(URL.createObjectURL(file));
                setImageFile(file);
                setImageError('');
            } else {
                setImageError('The file must be an image.');
            }
        }
    };

    useEffect(() => {
        // Fetch user data based on categoryId
        const token = localStorage.getItem('token');
        axios.put(
            `https://development.amomarket.in/api/subcategory/find_subcategory`,
            qs.stringify({ subcategory_id: subCategoryId }), // Serialize data to x-www-form-urlencoded format
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded', // Set the content type to x-www-form-urlencoded
                },
            }
        )
            .then((response) => {
                const subCategoryData = response.data.data;
                setSubCategory(subCategoryData.id);
                setName(subCategoryData.name);
                setCategoryName(subCategoryData.category_id);
                setDescription(subCategoryData.description);
                setProductCode(subCategoryData.product_code);
                setStatus(subCategoryData.status);
                setImage(subCategoryData.image ?? "/no_image2.png");
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    }, [subCategoryId]);

    useEffect(() => {
        return () => {
            if (image.startsWith('blob:')) {
                URL.revokeObjectURL(image); // Clean up URL.createObjectURL()
            }
        };
    }, [image]);

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://development.amomarket.in/api/category/get_all_category', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setCategories(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching shops:', error);
                setLoading(false);
            }
        };

        fetchCategory();
    }, []);


    return (
        <Layout>
            <Box sx={{ display: "flex" }}>
                <ToastContainer />
                <MainContent>
                    <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                            <Tooltip title="Return Back">
                                <ArrowBackRoundedIcon
                                    color="disabled"
                                    onClick={handleBackClick}
                                    sx={{ cursor: "pointer", marginRight: 1 }}
                                />
                            </Tooltip>
                            <Typography sx={{
                                marginRight: { xs: "0", sm: "10px", md: "700px" },
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                                flexGrow: 1,
                                textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                            }} variant="h6">
                                Update SubCategory
                            </Typography>
                        </Box>
                        <Divider sx={{ marginY: 2 }} />

                        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            SubCategory Name :
                                        </InputLabel>
                                        <TextField
                                            label="Enter SubCategory Name"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            error={Boolean(nameError)}
                                            helperText={nameError}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Select Category :
                                        </InputLabel>
                                        <Autocomplete
                                            options={categories}
                                            getOptionLabel={(option) => option.name} // Display shop name
                                            getOptionSelected={(option, value) => option.id === value.id} // Manage the selected option
                                            loading={loading}
                                            value={categories.find(categories => categories.id === categoryName) || null} // Set the selected shop based on shop ID
                                            onChange={(event, newValue) => {
                                                // Store shop ID instead of shop name
                                                setCategoryName(newValue ? newValue.id : '');
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Choose Category Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    error={Boolean(categoryNameError)}
                                                    helperText={categoryNameError}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Description :
                                        </InputLabel>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Enter Description"
                                            multiline
                                            type="text"
                                            rows={3}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            error={Boolean(descriptionError)}
                                            helperText={descriptionError}
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Is Product Code Available ? :
                                        </InputLabel>
                                        <TextField
                                            label="Enter Product Code"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={productCode}
                                            onChange={(e) => setProductCode(e.target.value)}
                                            error={Boolean(productCodeError)}
                                            helperText={productCodeError}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                            Status :
                                        </InputLabel>
                                        <TextField
                                            select
                                            variant="outlined"
                                            fullWidth
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            error={!!statusError}
                                            helperText={statusError}
                                            SelectProps={{
                                                native: true,
                                            }}
                                        >
                                            <option value="" disabled>Select Status</option>
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold", paddingRight: '190px' }} shrink>
                                    Image :
                                </InputLabel>
                                <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardContent>
                                        <Box sx={{ marginBottom: 1 }}>
                                            <img
                                                src={image}
                                                alt="Main_Image"
                                                style={{ borderRadius: "5%", width: 220, height: 225, objectFit: "cover", border: '2px dotted grey' }}
                                            />
                                        </Box>
                                        <Box sx={{ paddingLeft: "30px" }}>
                                            <label htmlFor="profile-picture-upload">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="profile-picture-upload"
                                                    name="profile-picture-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageFileChange}
                                                />
                                                <Button
                                                    sx={{ backgroundColor: 'grey', color: 'black', '&:hover': { backgroundColor: 'darkgrey' }, whiteSpace: 'nowrap' }}
                                                    variant="contained"
                                                    component="span"
                                                    startIcon={<CloudUploadTwoToneIcon />}
                                                >
                                                    Upload Image
                                                </Button>
                                            </label>
                                            {imageError && <Typography variant="body2" color="error">{imageError}</Typography>}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Divider sx={{ marginY: 2 }} />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 1 }}>
                            <Button
                                onClick={handleCancelClick}
                                color="secondary"
                                variant="outlined"
                                startIcon={<CancelTwoToneIcon />}
                                sx={{ marginRight: 2 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                color="primary"
                                variant="contained"
                                startIcon={<SendTwoToneIcon />}
                                disabled={loading}
                            >
                                {loading ? "Updating..." : "Update SubCategory"}
                            </Button>
                        </Box>
                    </Paper>
                </MainContent>
            </Box>
        </Layout>
    );
};

export default UpdateSubCategoryPage;

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Container, Typography, TextField, Button, Grid, Box } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#23DC0D', // Your custom color
        },
    },
});

const OtpVerificationPage = () => {
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [isLoading, setIsLoading] = useState(false); // State to track loading

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setOtpError('');

        // Prepare form data
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('otp', otp);

        setIsLoading(true);

        // Make API call to verify OTP
        axios.post('https://development.amomarket.in/api/portal/verify_Otp', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data', // Set content type for formData
            },
        })
            .then(response => {
                toast.success(response.data.message);
                navigate('/dashboard');
            })
            .catch(error => {
                setIsLoading(false); // Set loading to false in case of error
                if (error.response && error.response.data && error.response.data.errors) {
                    if (error.response.data.errors.otp) {
                        setOtpError(error.response.data.errors.otp[0]);
                        toast.error(error.response.data.errors.otp[0]);
                    }
                } else {
                    // Handle other errors
                    console.error('Server error:', error.response.data.message);
                }
            })
            .finally(() => {
                setIsLoading(false); // Reset loading after request completes
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <ToastContainer />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} order={1}>
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <img src="/otpverification.png" alt="otp verification" style={{ maxWidth: '100%' }} />
                        </Box>
                    </Grid>
                    <Grid 
                        item 
                        xs={12} 
                        md={6} 
                        order={2} 
                        display="flex" 
                        justifyContent="center" 
                        alignItems="center"
                    >
                        <Card elevation={3} sx={{ maxWidth: '400px', width: '100%' }}>
                            <CardContent>
                                <div>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Enter OTP to continue..
                                    </Typography>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        label="OTP"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        error={!!otpError}
                                        helperText={otpError}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        style={{ marginTop: '1rem' }}
                                        disabled={isLoading} // Disable button when loading
                                    >
                                        {isLoading ? 'Loading...' : 'Submit'} {/* Change button text based on loading state */}
                                    </Button>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default OtpVerificationPage;

// Layout.js
import React from 'react';
import CartButton from './CartButton';

const CartButtonLayout = ({ children }) => {
  return (
    <div>
      <CartButton />
      {children}
    </div>
  );
};

export default CartButtonLayout;
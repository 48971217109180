import axios from 'axios';

export const addToCart = async (productId, quantity) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.log('User is not authenticated');
    return;
  }

  try {
    const response = await axios.post(
      'https://development.amomarket.in/api/portal/cart/add',
      { product_id: productId, quantity },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log('Product added to cart', response.data);
    
    return response.data; // Optional, in case you need the response data
  } catch (err) {
    console.error('Error adding product to cart:', err);
    throw err; // Re-throw the error to handle it in the calling component if needed
  }
};

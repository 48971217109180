import React from 'react';
import { Container, Typography, Grid, Link, IconButton, Box, Skeleton } from '@mui/material';
import { Facebook, Instagram, YouTube, LinkedIn } from '@mui/icons-material';
import { styled } from '@mui/system';

const FooterContainer = styled(Container)(({ theme }) => ({
    backgroundColor: 'white',
    marginTop: '35px',
    padding: '40px 20px',
    borderTopLeftRadius: '22px', // Add border radius to top-left corner
    borderTopRightRadius: '22px', // Add border radius to top-right corner
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.6)', // Add shadow effect
    overflow: 'visible', // Ensure shadow effect is rendered correctly outside the content boundaries
}));

const SocialIcon = styled(IconButton)(({ theme }) => ({
    color: 'linear-gradient(to right, orange, green)', // Use gradient for color
    '& svg': {
        fontSize: '2.2rem', // Increase icon size
    },
    transition: 'transform 0.3s ease-in-out', // Add smooth transition effect for transform property
    '&:hover': {
        transform: 'scale(1.1)', // Slightly increase the size on hover
    },
}));

const FooterLink = styled(Link)(({ theme }) => ({
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

const Divider = styled('div')(({ theme }) => ({
    height: '4px',
    background: 'linear-gradient(to right, #2EDF0F, #7528FA)',
    margin: '8px 0',
    width: '85%', // Adjust the width as per your design
}));

const CategoryHeader = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .seeAll': {
        marginLeft: 'auto', // Align "See All" to the right
    },
}));

const Footer = () => {
    const loading = false; // Change this to true to simulate loading

    return (
        <FooterContainer maxWidth={false}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h5" noWrap style={{ fontWeight: 'bold', color: '#2EDF0F' }}>
                            Amo
                            <span style={{ color: '#7528FA' }}> Market</span>
                        </Typography>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>Smart delivery for your Location.</Typography>
                        <Box mt={3}>
                            <SocialIcon href="#"><Facebook /></SocialIcon>
                            <SocialIcon href="#"><Instagram /></SocialIcon>
                            <SocialIcon href="#"><YouTube /></SocialIcon>
                            <SocialIcon href="#"><LinkedIn /></SocialIcon>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CategoryHeader variant="h6" gutterBottom>
                            Category
                            <Typography variant="body1" className="seeAll">See All</Typography>
                        </CategoryHeader>
                        <Divider style={{ width: '100%' }} />
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Vegetables & Fruits</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Dairy & Breakfast</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Cold Drinks</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Bakery & Biscuits</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Paan Corner</Typography>}
                            </Grid>
                            <Grid item xs={6}>
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Pharma & Wellness</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Beauty & Cosmetics</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Print Store</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Cleaning Essentials</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Sweet Tooth</Typography>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" gutterBottom>Contact Us</Typography>
                        <Divider />
                        <Box mb={1}>
                            {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Amo Market, Hatyagand Choka,</Typography>}
                        </Box>
                        <Box mb={1}>
                            {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Remuna, Balasore, 756019, Odisha</Typography>}
                        </Box>
                        <Box mb={1}>
                            {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">+91 7008392889, 7381883483</Typography>}
                        </Box>
                        {loading ? <Skeleton variant="text" width="100%" /> : <FooterLink style={{ fontWeight: 'bold' }} href="mailto:amomarket@gmail.com">amomarket@gmail.com</FooterLink>}
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h6" gutterBottom>Useful Links</Typography>
                        <Divider />
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">About</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Careers</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Blog</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Deliver</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Seller</Typography>}
                            </Grid>
                            <Grid item xs={6}>
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Privacy</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Terms</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">FAQs</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Security</Typography>}
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Partner</Typography>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </FooterContainer>
    );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Box, Typography, Button, Grid, Paper, Chip, IconButton, Avatar } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { AccessTime } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Divider from '@mui/material/Divider';
import axios from "axios";
import Header from './Header';
import Footer from './Footer';

const ProductDetails = () => {
    const { productId } = useParams();
    const [categoryName, setCategoryName] = useState('');
    const [visibleImages, setVisibleImages] = useState(0);
    const [mainImage, setMainImage] = useState(null);
    const [productName, setProductName] = useState(null);
    const [aboutProduct, setAboutProduct] = useState('');
    const [subCategoryName, setSubCategoryName] = useState('');
    const [deliveryTime, setDeliveryTime] = useState(0);
    const [sellingPrice, setSellingPrice] = useState(null);
    const [originalPrice, setOriginalPrice] = useState(null);
    const [discount, setDiscount] = useState(null);
    const [weight, setWeight] = useState(null);
    const [productIngredients, setProductIngredients] = useState('');
    const [productAttraction, setProductAttraction] = useState('');
    const [sellingPriceUnitTwo, setSellingPriceUnitTwo] = useState('');
    const [originalPriceUnitTwo, setOriginalPriceUnitTwo] = useState('');
    const [discountUnitTwo, setDiscountUnitTwo] = useState('');
    const [weightUnitTwo, setWeightUnitTwo] = useState('');
    const [sellingPriceUnitThree, setSellingPriceUnitThree] = useState('');
    const [originalPriceUnitThree, setOriginalPriceUnitThree] = useState('');
    const [discountUnitThree, setDiscountUnitThree] = useState('');
    const [weightUnitThree, setWeightUnitThree] = useState('');
    const [productType, setProductType] = useState('');
    const [productKeyFeatures, setProductKeyFeatures] = useState('');
    const [productFssaiLicense, setProductFssaiLicense] = useState('');
    const [productOtherLicense, setProductOtherLicense] = useState('');
    const [productSelfLife, setProductSelfLife] = useState('');
    const [productManufacturerDetails, setProductManufacturerDetails] = useState('');
    const [productCountryOfOrigin, setProductCountryOfOrigin] = useState('');
    const [productStateOfOrigin, setProductStateOfOrigin] = useState('');
    const [productSeller, setProductSeller] = useState('');
    const [productSellerFssai, setProductSellerFssai] = useState('');
    const [productReturnPolicy, setProductReturnPolicy] = useState('');
    const [productDisclaimer, setProductDisclaimer] = useState('');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const fetchCategory = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://development.amomarket.in/api/portal/product_details', {
                    params: {
                        product_id: productId,
                    }
                });
                setMainImage(response.data.data.image);
                setProductName(response.data.data.name);
                setAboutProduct(response.data.data.about_product);
                setSubCategoryName(response.data.data.sub_category.name);
                setCategoryName(response.data.data.sub_category.category.name);
                setDeliveryTime(response.data.data.delivery_time);
                setSellingPrice(response.data.data.price);
                setOriginalPrice(response.data.data.original_price);
                setDiscount(response.data.data.discount)
                setWeight(response.data.data.weight);
                setProductIngredients(response.data.data.product_information[0].product_Ingredients);
                setProductAttraction(response.data.data.product_information[0].product_attraction);
                setSellingPriceUnitTwo(response.data.data.product_information[0].second_unit_price);
                setOriginalPriceUnitTwo(response.data.data.product_information[0].second_unit_original_price);
                setDiscountUnitTwo(response.data.data.product_information[0].second_unit_discount);
                setWeightUnitTwo(response.data.data.product_information[0].second_unit_weight);
                setSellingPriceUnitThree(response.data.data.product_information[0].third_unit_price);
                setOriginalPriceUnitThree(response.data.data.product_information[0].third_unit_original_price);
                setDiscountUnitThree(response.data.data.product_information[0].third_unit_discount);
                setWeightUnitThree(response.data.data.product_information[0].third_unit_weight);
                setProductType(response.data.data.product_information[0].product_type);
                setProductKeyFeatures(response.data.data.product_information[0].key_features);
                setProductFssaiLicense(response.data.data.product_information[0].fssai_license);
                setProductOtherLicense(response.data.data.product_information[0].other_license);
                setProductSelfLife(response.data.data.product_information[0].shelf_life);
                setProductManufacturerDetails(response.data.data.product_information[0].manufacturer_details);
                setProductCountryOfOrigin(response.data.data.product_information[0].country_of_origin);
                setProductStateOfOrigin(response.data.data.product_information[0].state_of_origin);
                setProductSeller(response.data.data.product_information[0].seller);
                setProductSellerFssai(response.data.data.product_information[0].seller_fssai);
                setProductReturnPolicy(response.data.data.product_information[0].return_policy);
                setProductDisclaimer(response.data.data.product_information[0].disclaimer);
                const allImages = [
                    response.data.data.product_information[0].product_image_one,
                    response.data.data.product_information[0].product_image_two,
                    response.data.data.product_information[0].product_image_three,
                    response.data.data.product_information[0].product_image_four,
                    response.data.data.product_information[0].product_image_five,
                    ...response.data.data.product_information[0].product_extra_image.split(','),
                ];
                setImages(allImages);
            } catch (error) {
                console.error('Error fetching category:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategory();
    }, [productId]);

    const handleNext = () => {
        if (visibleImages < images.length - 5) {
            setVisibleImages(visibleImages + 1);
        }
    };

    const handlePrev = () => {
        if (visibleImages > 0) {
            setVisibleImages(visibleImages - 1);
        }
    };

    const handleThumbnailClick = (img) => {
        setMainImage(img);
    };

    const handleAdd = () => {
        setCount(1); // Set count to 1 when "Add" is clicked
    };

    const handleIncrease = () => {
        setCount(prevCount => prevCount + 1); // Increment count
    };

    const handleDecrease = () => {
        if (count > 0) {
            setCount(prevCount => prevCount - 1); // Decrement count if greater than 0
        }
    };
    return (
        <>
            <Header />
            <Box sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6.5}>
                        <Paper elevation={1} sx={{ position: 'relative', padding: '16px', textAlign: 'center' }}>
                            <img
                                src={mainImage} // Main image
                                alt="Main Product"
                                style={{
                                    width: '350px',  // Set fixed width
                                    height: '370px', // Set fixed height
                                    objectFit: 'cover',
                                    borderRadius: '4px',
                                    display: 'block',  // Ensure the image is treated as a block element
                                    margin: 'auto',    // Center the image horizontally
                                }}
                            />
                            <Box mt={2} position="relative" sx={{ overflow: 'hidden', width: '100%' }}>
                                {visibleImages > 0 && (
                                    <IconButton
                                        onClick={handlePrev}
                                        sx={{
                                            position: 'absolute',
                                            left: 0,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            zIndex: 2,
                                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        }}
                                    >
                                        <ArrowBack />
                                    </IconButton>
                                )}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        overflow: 'hidden',
                                        width: '100%',
                                        padding: '4px',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            transition: 'transform 0.3s ease-in-out',
                                            transform: `translateX(-${visibleImages * 108}px)`, // Adjusted for image width + margin
                                        }}
                                    >
                                        {images.map((img, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    minWidth: '80px',
                                                    height: '80px',
                                                    marginRight: '8px',
                                                    border: '1px solid #ddd',
                                                    borderRadius: '4px',
                                                    overflow: 'hidden',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        border: '1px solid #333',
                                                    },
                                                }}
                                                onClick={() => handleThumbnailClick(img)}
                                            >
                                                <img
                                                    src={img}
                                                    alt={`sub-${index}`}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                {visibleImages < images.length - 5 && (
                                    <IconButton
                                        onClick={handleNext}
                                        sx={{
                                            position: 'absolute',
                                            right: 0,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            zIndex: 2,
                                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        }}
                                    >
                                        <ArrowForward />
                                    </IconButton>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={5.5}>
                        <Box display="flex" alignItems="center" mb={2}>
                            <Typography variant="body2" color="textSecondary">
                                Home / {categoryName} / {subCategoryName} / {productName}
                            </Typography>
                        </Box>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }} gutterBottom>
                            {productName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" style={{ lineHeight: '24px', display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            <AccessTime style={{ marginRight: '5px', fontSize: '20px', verticalAlign: 'sub' }} /> {deliveryTime} min.
                        </Typography>
                        <Typography variant="body1" gutterBottom mb={1}>
                            {productIngredients}
                        </Typography>

                        <Chip label={productAttraction} color="success" sx={{ mr: 1 }} />
                        <Chip label="High Protein" color="secondary" />

                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} color="success.main" mt={2} mb={1}>
                            View All By Yoga Bar <ArrowRightIcon style={{ marginLeft: '3px', fontSize: '20px', verticalAlign: 'sub' }} />
                        </Typography>

                        <Divider sx={{ color: '#616161f5', fontWeight: 'bold' }}>Price Section</Divider>

                        <Box mt={2}>
                            <Typography variant="body1" sx={{ color: '#363237', fontWeight: 'bold', marginBottom: '15px' }} mt={1}>
                                Select Unit
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            border: '1px solid',
                                            borderColor: 'success.main',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            textAlign: 'center',
                                            height: '60px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            position: 'relative',
                                        }}
                                    >
                                        <Chip
                                            label={`${discount} OFF`}
                                            color="success"
                                            sx={{
                                                position: 'absolute',
                                                top: '-12px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                fontSize: '10px',
                                                height: '20px',
                                            }}
                                        />
                                        <Typography variant="body1" sx={{ color: '#616161f5', fontWeight: 'bold' }} color="textPrimary" mt={1.5}>
                                            {weight}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#616161f5', fontWeight: 'bold' }} color="textSecondary">
                                            ₹{sellingPrice} MRP <s>₹{originalPrice}</s>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            border: '1px solid',
                                            borderColor: 'success.main',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            textAlign: 'center',
                                            height: '60px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            position: 'relative',
                                        }}
                                    >
                                        <Chip
                                            label={`${discountUnitTwo} OFF`}
                                            color="success"
                                            sx={{
                                                position: 'absolute',
                                                top: '-12px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                fontSize: '10px',
                                                height: '20px',
                                            }}
                                        />
                                        <Typography variant="body1" sx={{ color: '#616161f5', fontWeight: 'bold' }} color="textPrimary" mt={1.5}>
                                            {weightUnitTwo}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#616161f5', fontWeight: 'bold' }} color="textSecondary">
                                            ₹{sellingPriceUnitTwo} MRP <s>₹{originalPriceUnitTwo}</s>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            border: '1px solid',
                                            borderColor: 'success.main',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            textAlign: 'center',
                                            height: '60px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            position: 'relative',
                                        }}
                                    >
                                        <Chip
                                            label={`${discountUnitThree} OFF`}
                                            color="success"
                                            sx={{
                                                position: 'absolute',
                                                top: '-12px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                fontSize: '10px',
                                                height: '20px',
                                            }}
                                        />
                                        <Typography variant="body1" sx={{ color: '#616161f5', fontWeight: 'bold' }} color="textPrimary" mt={1.5}>
                                            {weightUnitThree}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#616161f5', fontWeight: 'bold' }} color="textSecondary">
                                            ₹{sellingPriceUnitThree} MRP <s>₹{originalPriceUnitThree}</s>
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Typography variant="body2" color="textSecondary" mt={2} mb={2}>
                                Inclusive of all taxes
                            </Typography>
                            <Box mt={2} display="flex">
                                {count > 0 ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            background: 'green',
                                            borderRadius: '4px',
                                            padding: '2px 4px',
                                            color: 'white',
                                            width: '140px', // Fixed width same as the Add button
                                            justifyContent: 'space-between', // Ensures even spacing
                                        }}
                                    >
                                        <IconButton
                                            size="small"
                                            onClick={handleDecrease}
                                            style={{ color: 'white', padding: '4px' }}
                                        >
                                            <Remove />
                                        </IconButton>
                                        <Typography
                                            variant="body2"
                                            component="div"
                                            style={{ margin: '0 8px', color: 'white' }}
                                        >
                                            {count}
                                        </Typography>
                                        <IconButton
                                            size="small"
                                            onClick={handleIncrease}
                                            style={{ color: 'white', padding: '4px' }}
                                        >
                                            <Add />
                                        </IconButton>
                                    </Box>
                                ) : (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="success"
                                        onClick={handleAdd}
                                        style={{ height: '36px', minWidth: '150px' }}
                                    >
                                        Add
                                    </Button>
                                )}
                            </Box>

                        </Box>

                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6.5}>
                        <Divider sx={{ color: '#616161f5', fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>About Product</Divider>
                        <Typography variant="h5" sx={{ color: '#363237', fontWeight: 'bold' }} gutterBottom>
                            Product Details :
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {aboutProduct}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={5.5}>
                                <Box>
                                    <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                        Product Type :
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {productType}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                        Key Features :
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {productKeyFeatures}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                        Unit :
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {weight}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                        Ingredients :
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {productIngredients}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                        Country Of Origin :
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {productCountryOfOrigin}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                        State Of Origin :
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {productStateOfOrigin}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <Divider orientation="vertical" variant="middle" />
                            </Grid>
                            <Grid item xs={12} sm={5.5}>
                                <Box>
                                    <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                        FSSAI License :
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {productFssaiLicense}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                        Other License :
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {productOtherLicense}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                        Shelf Life / Expire :
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {productSelfLife}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                        Manufacturer Details :
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {productManufacturerDetails}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                        Seller :
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {productSeller}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                        Seller FSSAI :
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {productSellerFssai}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                            Return Policy :
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {productReturnPolicy}
                        </Typography>
                        <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                            Disclaimer :
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {productDisclaimer}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5.5}>
                        <Divider sx={{ color: '#616161f5', fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>Need Help / Support ?</Divider>
                        <Box sx={{ marginLeft: '10px' }}>
                            <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                Customer Care Details :
                            </Typography>
                            <Typography variant="body2" color="textSecondary" display="block">
                                Email : info@amomarket.com
                            </Typography>
                            <Typography variant="body2" color="textSecondary" display="block">
                                Phone : +91 70083 92889
                            </Typography>
                            <Typography variant="body2" color="textSecondary" display="block">
                                Whatsapp(B) : +91 70083 92889
                            </Typography>
                            <Typography variant="body2" color="textSecondary" display="block">
                                Telegram(B) : +91 70083 92889
                            </Typography>
                        </Box>
                        <Box sx={{ marginLeft: '10px', marginTop: '20px' }}>
                            <Typography variant="h6" sx={{ color: '#363237', fontWeight: 'bold', marginTop: '10px', marginBottom: '4px' }} gutterBottom>
                                Why shop from us?
                            </Typography>

                            {/* Feature 1 */}
                            <Box display="flex" alignItems="center" mt={1} mb={2}>
                                <Avatar
                                    src="/delivery_box_1.png"
                                    sx={{ width: 70, height: 70, marginRight: '16px', borderRadius: '2px', }}
                                />
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        Superfast Delivery
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Get your order delivered to your doorstep at the earliest from shop near you.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" mt={1} mb={2}>
                                <Avatar src="/best_Price_2.png" sx={{ width: 70, height: 70, marginRight: '16px', borderRadius: '2px', }} />
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        Best Prices & Offers
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Best price destination with offers directly from the manufacturers.
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Feature 3 */}
                            <Box display="flex" alignItems="center" mt={1} mb={2}>
                                <Avatar src="/wide_3.png" sx={{ width: 70, height: 70, marginRight: '16px', borderRadius: '2px', }} />
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        Wide Assortment
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Choose from 5000+ products across food, personal care, household & other categories.
                                    </Typography>
                                </Box>
                            </Box>
                            {/* Feature 3 */}
                            <Box display="flex" alignItems="center" mt={1} mb={2}>
                                <Avatar src="/cashback_4.png" sx={{ width: 70, height: 70, marginRight: '16px', borderRadius: '2px', }} />
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        Cash Back !
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Earn cash back rewards on every order you place, making your shopping experience more rewarding and cost-effective. Shop more, save more!
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Box for Refer and Earn */}
                            <Box display="flex" alignItems="center" mt={1} mb={2}>
                                <Avatar src="/rewards_5.png" sx={{ width: 70, height: 70, marginRight: '16px', borderRadius: '2px', }} />
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        Refer and Earn !
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Earn rewards by referring your friends and family. Share the joy of shopping, and get exclusive bonuses for every successful referral. It's a win-win for everyone!
                                    </Typography>
                                </Box>
                            </Box>
                            {/* 5 Deliveries free  */}
                            <Box display="flex" alignItems="center" mt={1} mb={2}>
                                <Avatar src="/five_delivery_6.png" sx={{ width: 70, height: 70, marginRight: '16px', borderRadius: '2px', }} />
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        First 5 Deliveries Free!
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Enjoy the convenience of having your first 5 orders delivered for free! Start shopping now and take advantage of this limited-time offer.
                                    </Typography>
                                </Box>
                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Footer />
        </>
    );
};

export default ProductDetails;

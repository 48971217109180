import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Grid, List, ListItem, ListItemText, ListItemAvatar, Avatar, Card, CardMedia, CardContent, Typography, Button, Box, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { AccessTime, Add, Remove } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import Header from './Header';
import Footer from './Footer';

const StyledCard = styled(Card)(({ theme }) => ({
    height: '280px',
    display: 'flex',
    margin: '8px 4px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    position: 'relative',
}));

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
});

const CategoryHeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#dbdbdb',
    padding: '10px 40px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
        height: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: 'linear-gradient(to right, #2EDF0F, #7528FA)',
        borderRadius: '4px',
    },
    whiteSpace: 'nowrap',
    borderRadius: '15px',
}));

const CategoryHeader = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    marginRight: '20px',
    '& > *': {
        marginRight: '20px',
        cursor: 'pointer',
        fontSize: '16px',
        color: '#333',
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
}));

function SubCategoryWiseProduct() {
    const navigate = useNavigate();
    const { categoryId } = useParams();
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [subcategoryProducts, setSubcategoryProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [sortBy, setSortBy] = useState('Relevance');
    const [loading, setLoading] = useState(false);
    const [noProductsMessage, setNoProductsMessage] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);

    useEffect(() => {
        const fetchCategory = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://development.amomarket.in/api/portal/category');
                setCategories(response.data.data);
            } catch (error) {
                console.error('Error fetching category:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategory();
    }, []);

    const fetchProductsByCategoryId = async (categoryId, subcategoryId = null, sortBy = 'Relevance') => {
        setLoading(true);
        try {
            const response = await axios.get('https://development.amomarket.in/api/portal/product_by_group', {
                params: {
                    category_id: categoryId,
                    subcategory_id: subcategoryId,
                    sort_by: sortBy,
                }
            });

            if (response.data.status) {
                const data = response.data.data;
                const categoryName = response.data.data[0].name || '';
                const products = data.flatMap(item => item.sub_category.flatMap(sub => sub.product));
                const subCategories = data.flatMap(item => item.sub_category);
                subCategories.sort((a, b) => a.name.localeCompare(b.name));

                if (subcategoryId === null && subCategories.length > 0) {
                    subcategoryId = subCategories[0].id;  // Default to the first subcategory
                }

                setAllProducts(products);
                setSubcategories(subCategories);
                setSelectedCategory(categoryName);
                setSelectedSubcategory(subcategoryId);  // Ensure the first subcategory is selected
                setNoProductsMessage('');
            } else {
                setAllProducts([]);
                setNoProductsMessage('No Products Found');
            }
        } catch (error) {
            console.error('Error fetching products:', error);
            setAllProducts([]);
            setNoProductsMessage('No Products Found');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (categoryId) {
            fetchProductsByCategoryId(categoryId, selectedSubcategory, sortBy);
        }
    }, [categoryId, selectedSubcategory, sortBy]);

    useEffect(() => {
        const filteredProducts = allProducts.filter(product =>
            product.sub_category_id === selectedSubcategory
        );
        setSubcategoryProducts(filteredProducts);
    }, [selectedSubcategory, allProducts]);

    const handleSubcategoryClick = (subcategoryId) => {
        setSelectedSubcategory(subcategoryId);
    };

    const handleAdd = (id) => {
        setSubcategoryProducts(prevProducts =>
            prevProducts.map(product =>
                product.id === id ? { ...product, count: 1 } : product
            )
        );
    };

    const handleIncrease = (id) => {
        setSubcategoryProducts(prevProducts =>
            prevProducts.map(product =>
                product.id === id ? { ...product, count: product.count + 1 } : product
            )
        );
    };

    const handleDecrease = (id) => {
        setSubcategoryProducts(prevProducts =>
            prevProducts.map(product =>
                product.id === id && product.count > 0
                    ? { ...product, count: product.count - 1 } : product
            )
        );
    };

    // Find the selected subcategory's name
    const selectedSubcategoryObject = subcategories.find(subcategory => subcategory.id === selectedSubcategory);
    const selectedSubcategoryName = selectedSubcategoryObject ? selectedSubcategoryObject.name : '';

    return (
        <>
            <Header />
            <ThemeProvider theme={theme}>
                <CategoryHeaderContainer>
                    <CategoryHeader>
                        {categories.map((category) => (
                            <Typography
                                key={category.id}
                                variant="body1"
                                onClick={() => {
                                    navigate(`/all_product/subcategory/${category.id}`);
                                }}
                            >
                                {category.name}
                            </Typography>
                        ))}
                    </CategoryHeader>
                </CategoryHeaderContainer>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <List>
                            {subcategories.map((subcategory) => (
                                <ListItem
                                    key={subcategory.id}
                                    button
                                    selected={selectedSubcategory === subcategory.id}
                                    onClick={() => handleSubcategoryClick(subcategory.id)}
                                >
                                    <ListItemAvatar>
                                        <Avatar src={subcategory.image} />
                                    </ListItemAvatar>
                                    <ListItemText primary={subcategory.name} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={9}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 3, mb: 2 }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                Buy {selectedSubcategoryName} Online
                            </Typography>
                            <Box display="flex" gap={2}>
                                <FormControl variant="outlined" sx={{ minWidth: 250 }}>
                                    {loading ? (
                                        <Skeleton variant="rectangular" width={250} height={40} />
                                    ) : (
                                        <Autocomplete
                                            size="small"
                                            options={categories}
                                            getOptionLabel={(option) => option.name}
                                            loading={loading}
                                            value={categories.find(category => category.name === selectedCategory) || null}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSelectedCategory(newValue.name);
                                                    fetchProductsByCategoryId(newValue.id);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Choose Other Category"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />

                                    )}
                                </FormControl>
                                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                    {loading ? (
                                        <Skeleton variant="rectangular" width={120} height={40} />
                                    ) : (
                                        <>
                                            <InputLabel>Sort By</InputLabel>
                                            <Select
                                                size="small"
                                                value={sortBy}
                                                onChange={(e) => setSortBy(e.target.value)}
                                                label="Sort By"
                                            >
                                                <MenuItem value="Relevance">Relevance</MenuItem>
                                                <MenuItem value="Price(L-H)">Price(Low to High)</MenuItem>
                                                <MenuItem value="Price(H-L)">Price(High to Low)</MenuItem>
                                                <MenuItem value="discount">Discount(High to Low)</MenuItem>
                                            </Select>
                                        </>
                                    )}
                                </FormControl>
                            </Box>
                        </Box>
                        <Grid container spacing={2}>
                            {loading ? (
                                Array.from(new Array(12)).map((_, index) => (
                                    <Grid item xs={6} md={3} key={index}>
                                        <StyledCard>
                                            <Skeleton variant="rectangular" height={120} />
                                            <CardContent style={{ padding: '10px', flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                <Skeleton variant="text" height={24} width="80%" />
                                                <Skeleton variant="text" height={20} width="60%" />
                                                <Skeleton variant="text" height={20} width="60%" />
                                                <Skeleton variant="text" height={36} width="50%" style={{ marginTop: '8px' }} />
                                            </CardContent>
                                        </StyledCard>
                                    </Grid>
                                ))
                            ) : subcategoryProducts.length > 0 ? (
                                subcategoryProducts.map((product) => (
                                    <Grid item xs={6} md={3} key={product.id}>
                                        <StyledCard>
                                            {product.discount && (
                                                <Typography
                                                    style={{
                                                        position: 'absolute',
                                                        background: 'rgba(0, 123, 255, 0.9)',
                                                        color: 'white',
                                                        padding: '2px 5px',
                                                        borderRadius: '0 0 5px 5px',
                                                        top: 0,
                                                        right: 0
                                                    }}
                                                >
                                                    {product.discount} OFF
                                                </Typography>
                                            )}
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={product.image}
                                                alt={product.name}
                                                style={{ objectFit: 'contain' }}
                                            />
                                            <CardContent style={{ padding: '10px', flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                <div>
                                                    <Typography variant="body2" color="textSecondary" style={{ lineHeight: '24px', display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                        <AccessTime style={{ marginRight: '5px', fontSize: '20px', verticalAlign: 'sub' }} /> {product.delivery_time} min.
                                                    </Typography>
                                                    <Tooltip title={product.name}>
                                                        <Typography
                                                            variant="body2"
                                                            component="div"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                        >
                                                            {product.name}
                                                        </Typography>
                                                    </Tooltip>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {product.volume}
                                                    </Typography>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }}>
                                                    <Typography variant="body2" color="text.primary">
                                                        {product.price}
                                                    </Typography>
                                                    {product.count > 0 ? (
                                                        <div style={{ display: 'flex', alignItems: 'center', background: 'green', borderRadius: '4px', padding: '2px 4px', color: 'white' }}>
                                                            <IconButton size="small" onClick={() => handleDecrease(product.id)} style={{ color: 'white', padding: '4px' }}>
                                                                <Remove />
                                                            </IconButton>
                                                            <Typography variant="body2" component="div" style={{ margin: '0 8px', color: 'white' }}>
                                                                {product.count}
                                                            </Typography>
                                                            <IconButton size="small" onClick={() => handleIncrease(product.id)} style={{ color: 'white', padding: '4px' }}>
                                                                <Add />
                                                            </IconButton>
                                                        </div>
                                                    ) : (
                                                        <Button variant="outlined" size="small" color="success" onClick={() => handleAdd(product.id)} style={{ height: '36px', minWidth: '64px' }}>
                                                            Add
                                                        </Button>
                                                    )}
                                                </div>
                                            </CardContent>
                                        </StyledCard>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Typography variant="h4" color="textSecondary">
                                        {noProductsMessage}
                                    </Typography>
                                    <img src='/not_found.png' alt="No Products Found" style={{ maxWidth: '100%', maxHeight: '400px', margin: '20px 0' }} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </ThemeProvider>
            <Footer />
        </>
    );
}

export default SubCategoryWiseProduct;

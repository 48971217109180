import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';
import OtherHousesTwoToneIcon from '@mui/icons-material/OtherHousesTwoTone';
import AddLocationAltTwoToneIcon from '@mui/icons-material/AddLocationAltTwoTone';
import MapAddressModel from './MapAddressModel';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const AddAddressModel = ({ open, onClose }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [showTextField, setShowTextField] = useState(false);
    const [openMapAddressModel, setOpenMapAddressModel] = useState(false);

    const handleButtonClick = () => {
        setShowTextField(!showTextField); // Toggle the state
    };

    const handleOpenMapAddressModel = () => {
        setOpenMapAddressModel(true);
    };

    const handleCloseMapAddressModel = () => {
        setOpenMapAddressModel(false);
    };

    return (
        <>
        <Dialog
            fullScreen={isMobile} // Fullscreen on mobile
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            sx={
                !isMobile
                    ? {
                        '& .MuiDialog-paper': {
                            position: 'fixed',
                            right: 0,
                            margin: 0,
                            width: '30%', // Adjust the width of the dialog for desktop
                            height: '100vh', // Ensure the dialog takes full height of the viewport
                            maxHeight: '100vh', // Make sure it doesn't shrink
                        },
                    }
                    : {}
            }
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {/* AppBar with close button (fixed at the top) */}
                <AppBar
                    sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#fff',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        zIndex: 10, // ensure it stays above scrollable content
                    }}
                >
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1, color: "black", fontWeight: "bold" }} variant="h6" component="div">
                            Add Delivery Address
                        </Typography>
                        <IconButton
                            edge="start"
                            color="black"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                {/* Form content */}
                <Box
                    sx={{
                        flexGrow: 1, // take up remaining space
                        overflowY: 'auto', // make this section scrollable
                        padding: '16px',
                        backgroundColor: '#fff',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth required label="Full Name (Required)" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth required label="Phone Number (Required)" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Add Alternate Phone Number" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth required label="Pincode (Required)" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{
                                    backgroundColor: "#7134df",
                                    '&:hover': {
                                        backgroundColor: '#5a1bcc',
                                    },
                                    height: '53px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                startIcon={<AddLocationAltTwoToneIcon />} // Adding the icon here
                                onClick={handleOpenMapAddressModel}
                            >
                                Use my location
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField fullWidth required label="State" variant="outlined" defaultValue="Karnataka" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth required label="City (Required)" variant="outlined" defaultValue="Bangalore" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth required label="Village / Colony (Required)" variant="outlined" defaultValue="Marathalli" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth required label="House No., Building Name (Required)" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth required label="Road Name, Area, Colony (Required)" variant="outlined" />
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Button
                                    variant="text"
                                    fullWidth
                                    sx={{ textTransform: 'none', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    onClick={handleButtonClick}
                                >
                                    + Add Nearby Famous Landmark/Temple/Shop/Other...
                                </Button>
                            </Grid>
                            {showTextField && (
                                <Grid item xs={12} sx={{marginLeft:2}}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={1.4}
                                        label="Nearby Famous Landmark"
                                        variant="outlined"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Box>

                {/* Type of address */}
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        padding: '16px',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <FormLabel component="legend">Type of Address</FormLabel>
                    <RadioGroup row defaultValue="home" sx={{ mb: 2 }}>
                        <FormControlLabel
                            value="home"
                            control={<Radio />}
                            label={<Box display="flex" alignItems="center"><HomeTwoToneIcon sx={{ mr: 0.5 }} /> Home</Box>}
                        />
                        <FormControlLabel
                            value="work"
                            control={<Radio />}
                            label={<Box display="flex" alignItems="center"><HomeWorkTwoToneIcon sx={{ mr: 0.5 }} /> Work</Box>}
                        />
                        <FormControlLabel
                            value="other"
                            control={<Radio />}
                            label={<Box display="flex" alignItems="center"><OtherHousesTwoToneIcon sx={{ mr: 0.5 }} /> Other</Box>}
                        />
                    </RadioGroup>

                    {/* Save Address button */}
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            width: '100%',
                            padding: '10px 0',
                            borderRadius: '25px',
                            backgroundColor: 'green',
                            '&:hover': {
                                backgroundColor: '#5a1bcc',
                            },
                        }}
                    >
                        Save Address
                    </Button>
                </Box>
            </Box>
        </Dialog>
        <MapAddressModel open={openMapAddressModel} onClose={handleCloseMapAddressModel} />
        </>
    );
};

export default AddAddressModel;

import React, { useEffect, useState } from 'react';
import { Container, Card, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: 'none',
  textAlign: 'center',
  margin: '0 10px',
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  overflow: 'hidden',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    width: '90%',
    height: 'auto',
  },
  [theme.breakpoints.up('sm')]: {
    width: 290,
    height: 200,
  },
}));

const Advisement = () => {
  const [advisements, setAdvisements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://development.amomarket.in/api/portal/advisement')
      .then(response => {
        setAdvisements(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching advisements data: ', error);
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <Container maxWidth="xl" style={{ marginTop: '20px' }}>
      {loading ? (
        <Slider {...settings}>
          {Array(4).fill(0).map((_, index) => (
            <div key={index} style={{ margin: '0 10px' }}>
              <Skeleton variant="rect" width="100%" height={200} style={{ borderRadius: '10px' }} />
            </div>
          ))}
        </Slider>
      ) : (
        <Slider {...settings}>
          {advisements.map((advisement, index) => (
            <div key={index} style={{ margin: '0 10px' }}>
              {advisement.content_image && (
                <StyledCard style={{ backgroundColor: advisement.background_colour }}>
                  <img
                    src={advisement.content_image}
                    alt={advisement.title}
                    style={{ width: '100%', height: '100%', borderRadius: '10px' }}
                  />
                </StyledCard>
              )}
            </div>
          ))}
        </Slider>
      )}
    </Container>
  );
};

export default Advisement;

import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import MopedTwoToneIcon from '@mui/icons-material/MopedTwoTone';
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import PhonelinkTwoToneIcon from '@mui/icons-material/PhonelinkTwoTone';
import VolunteerActivismTwoToneIcon from '@mui/icons-material/VolunteerActivismTwoTone';
import { Add, Remove } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from 'axios';
import { addToCart } from './cartService';
import AddressModel from '../Address/AddressModel';

// Slide transition for the full-screen dialog
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const CartModal = ({ open, onClose }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [openAddressModel, setOpenAddressModel] = useState(false);
    const [products, setProducts] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const [platformCharge, setPlatformCharge] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [emptyCart, setEmptyCart] = useState(false);
    const [feedingIndiaDonation, setFeedingIndiaDonation] = useState(0);
    const [tipAmount, setTipAmount] = useState(0);
    const [loading, setLoading] = useState(true);

    // Fetch cart items from API when modal opens
    useEffect(() => {
        if (open) {
            fetchCartItems();
        }
    }, [open]);

    const fetchCartItems = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await axios.get('https://development.amomarket.in/api/portal/cart', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const data = response.data.data;
            setProducts(data.cartItems ?? []);
            setTotalAmount(data.totalAmount ?? 0);
            setDeliveryCharge(data.deliveryCharge ?? 0);
            setPlatformCharge(data.platformCharge ?? 0);
            setGrandTotal(data.grandTotal ?? 0);
            setEmptyCart(false);

        } catch (error) {
            const errorData = error.response.data;
            if (errorData.status === false || errorData.message === 'Your cart is empty') {
                setEmptyCart(true)
            } 
        } finally {
            setLoading(false);
        }
    };

    const handleAdd = (id) => {
        setProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.id === id
                    ? (addToCart(product.product_id, 1), { ...product, quantity: product.quantity + 1 })
                    : product
            )
        );
        if (open) {
            fetchCartItems();
        }
    };

    const handleIncrease = (id) => {
        setProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.id === id
                    ? (addToCart(product.product_id, 1), { ...product, quantity: product.quantity + 1 })
                    : product
            )
        );
        if (open) {
            fetchCartItems();
        }
    };

    const handleDecrease = (id) => {
        if (id === "") {
            setProducts([]);
        } else {
            setProducts((prevProducts) =>
                prevProducts.map((product) =>
                    product.id === id && product.quantity > 0
                        ? (addToCart(product.product_id, -1), { ...product, quantity: product.quantity - 1 })
                        : product
                )
            );
        }
        if (open) {
            fetchCartItems();
        }
    };
    const handleOpenAddressModel = () => {
        setOpenAddressModel(true);
    };

    const handleCloseAddressModel = () => {
        setOpenAddressModel(false);
    };
    return (
        <>
            <Dialog
                fullScreen={isMobile} // Fullscreen on mobile
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
                keepMounted
                sx={
                    !isMobile
                        ? {
                            '& .MuiDialog-paper': {
                                position: 'fixed',
                                right: 0,
                                margin: 0,
                                width: '30%', // Adjust the width of the dialog for desktop
                                height: '100vh', // Ensure the dialog takes full height of the viewport
                                maxHeight: '100vh', // Make sure it doesn't shrink
                            },
                        }
                        : {}
                }
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {/* AppBar with close button (fixed at the top) */}
                    <AppBar
                        sx={{
                            position: 'sticky',
                            top: 0,
                            backgroundColor: '#fff',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            color: 'transparent',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                            overflow: 'hidden',
                            zIndex: 10, // ensure it stays above scrollable content
                        }}
                    >
                        <Toolbar style={{ marginBottom: 5, position: 'relative', zIndex: 1, overflow: 'hidden' }}>
                            <Typography sx={{ ml: 2, flex: 1, color: "black", fontWeight: "bold" }} variant="h6" component="div">
                                My Cart
                            </Typography>
                            <IconButton
                                edge="start"
                                color="black"
                                onClick={onClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {emptyCart === true ? (
                        <Box
                            sx={{
                                flexGrow: 1, // take up remaining space
                                overflowY: 'auto', // make this section scrollable
                                padding: '10px',
                                backgroundColor: '#f5f5f5',
                            }}
                        >
                            {/* Cart Empty Section */}
                            <Box sx={{
                                mt: 2,
                                backgroundColor: '#fff',
                                borderRadius: '12px',
                                padding: '16px',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}>
                                <img
                                    src="/empty_cart.png" // Replace with the actual path to your image
                                    alt="No Cart"
                                    style={{
                                        width: '50%', // Adjust the size as needed
                                        height: '50%',
                                        marginBottom: '16px'
                                    }}
                                />
                                <h3 variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    Your cart is Empty
                                </h3>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Explore our ever growing selection of products and exciting new offers today!
                                </Typography>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        marginTop: 3,
                                        width: '50%',
                                        backgroundColor: '#7528FA',
                                        color: '#fff',
                                        borderColor: '#7528FA',
                                        '&:hover': {
                                            backgroundColor: '#5a1bcc',
                                            borderColor: '#5a1bcc',
                                        },
                                    }}
                                >
                                    Start Shopping
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <div>
                            {/* Scrollable Content */}
                            <Box
                                sx={{
                                    flexGrow: 1, // take up remaining space
                                    overflowY: 'auto', // make this section scrollable
                                    padding: '10px',
                                    backgroundColor: '#f5f5f5',
                                }}
                            >
                                {/* Cart Items */}
                                <Box sx={{ backgroundColor: '#fff', borderRadius: '8px', padding: '16px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <TimelapseIcon sx={{ mr: 1 }} />  {/* Icon with some right margin */}
                                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Delivery in 15 minutes</Typography>
                                    </Box>
                                    <Divider />
                                    <div>
                                        {products.map((productItem) => (
                                            <div key={productItem.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '16px 0' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={productItem.product.image} alt={productItem.product.name} style={{ width: '50px', height: '50px', marginRight: '10px' }} />
                                                    <div>
                                                        <Typography variant="body1">{productItem.product.name}</Typography>
                                                        <Typography variant="body2" color="textSecondary">{productItem.product.weight}</Typography>
                                                        <Typography variant="body2" color="textPrimary" sx={{ fontWeight: "bold" }}>
                                                            <s>₹{productItem.product.original_price}</s> ₹{productItem.product.price}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                {productItem.quantity > 0 ? (
                                                    <div style={{ display: 'flex', alignItems: 'center', background: 'green', borderRadius: '4px', padding: '2px 4px', color: 'white' }}>
                                                        <IconButton size="small" onClick={() => handleDecrease(productItem.id)} style={{ color: 'white', padding: '4px' }}>
                                                            <Remove />
                                                        </IconButton>
                                                        <Typography variant="body2" component="div" style={{ margin: '0 8px', color: 'white' }}>
                                                            {productItem.quantity}
                                                        </Typography>
                                                        <IconButton size="small" onClick={() => handleIncrease(productItem.id)} style={{ color: 'white', padding: '4px' }}>
                                                            <Add />
                                                        </IconButton>
                                                    </div>
                                                ) : (
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        color="success"
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent card click when clicking Add button
                                                            handleAdd(productItem.id);
                                                        }}
                                                        style={{ height: '36px', minWidth: '64px' }}
                                                    >
                                                        Add
                                                    </Button>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </Box>


                                {/* Billing Details */}
                                <Box sx={{ mt: 2, backgroundColor: '#fff', borderRadius: '8px', padding: '16px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Bill details</Typography>
                                    <Divider sx={{ my: 1 }} />

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <DescriptionTwoToneIcon sx={{ mr: 0.5 }} />
                                            <Typography variant="body2">Items total</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {/* <Typography variant="body2"><s>₹74</s></Typography> */}
                                            <Typography variant="body2" sx={{ ml: 1 }}>₹{totalAmount}</Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <MopedTwoToneIcon sx={{ mr: 0.5 }} />
                                            <Typography variant="body2">Delivery charge</Typography>
                                        </Box>
                                        <Typography variant="body2">₹{deliveryCharge}</Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <PhonelinkTwoToneIcon sx={{ mr: 0.5 }} />
                                            <Typography variant="body2">Platform charge</Typography>
                                        </Box>
                                        <Typography variant="body2">₹{platformCharge}</Typography>
                                    </Box>

                                    {feedingIndiaDonation === 1 && (
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <VolunteerActivismTwoToneIcon sx={{ mr: 0.5 }} />
                                                <Typography variant="body2">Feeding India</Typography>
                                            </Box>
                                            <Typography variant="body2">₹1</Typography>
                                        </Box>
                                    )}

                                    {tipAmount > 0 && (
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <PersonTwoToneIcon sx={{ mr: 0.5 }} />
                                                <Typography variant="body2">Tip for your delivery Partner</Typography>
                                            </Box>
                                            <Typography variant="body2">₹{tipAmount}</Typography>
                                        </Box>
                                    )}

                                    <Divider sx={{ my: 1 }} />

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold' }}>
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>Grand total</Typography>
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>₹{grandTotal + feedingIndiaDonation + tipAmount}</Typography>
                                    </Box>
                                </Box>

                                {/* Donation Section */}
                                <Box sx={{
                                    mt: 2,
                                    backgroundColor: '#fff',
                                    borderRadius: '12px',
                                    padding: '16px',
                                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            Feeding India donation ₹1
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                                            Working towards a malnutrition-free India. Feeding India...read more
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                                        <Checkbox
                                            color="primary"
                                            checked={feedingIndiaDonation === 1}
                                            onChange={(e) => setFeedingIndiaDonation(e.target.checked ? 1 : 0)}
                                        />
                                        <Typography variant="body2" sx={{ ml: 1 }}>₹{feedingIndiaDonation}</Typography>
                                    </Box>
                                </Box>


                                {/* Tip Section */}
                                <Box sx={{ mt: 2, backgroundColor: '#fff', borderRadius: '8px', padding: '16px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        Tip your delivery partner
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                                        Your kindness means a lot! 100% of your tip will go directly to your delivery partner.
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                width: '20%',
                                                backgroundColor: '#8b50f2',
                                                color: '#fff',
                                                borderColor: '#8b50f2',
                                                '&:hover': {
                                                    backgroundColor: '#5a1bcc',
                                                    borderColor: '#5a1bcc',
                                                },
                                            }}
                                            onClick={() => {
                                                if (tipAmount === 20) {
                                                    setTipAmount(0);
                                                } else {
                                                    setTipAmount(20);
                                                }
                                            }}
                                        >
                                            ₹20
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                width: '20%',
                                                backgroundColor: '#8b50f2',
                                                color: '#fff',
                                                borderColor: '#8b50f2',
                                                '&:hover': {
                                                    backgroundColor: '#5a1bcc',
                                                    borderColor: '#5a1bcc',
                                                },
                                            }}
                                            onClick={() => {
                                                if (tipAmount === 30) {
                                                    setTipAmount(0);
                                                } else {
                                                    setTipAmount(30);
                                                }
                                            }}
                                        >
                                            ₹30
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                width: '20%',
                                                backgroundColor: '#8b50f2',
                                                color: '#fff',
                                                borderColor: '#8b50f2',
                                                '&:hover': {
                                                    backgroundColor: '#5a1bcc',
                                                    borderColor: '#5a1bcc',
                                                },
                                            }}
                                            onClick={() => {
                                                if (tipAmount === 50) {
                                                    setTipAmount(0);
                                                } else {
                                                    setTipAmount(50);
                                                }
                                            }}
                                        >
                                            ₹50
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                width: '20%',
                                                backgroundColor: '#8b50f2',
                                                color: '#fff',
                                                borderColor: '#8b50f2',
                                                '&:hover': {
                                                    backgroundColor: '#5a1bcc',
                                                    borderColor: '#5a1bcc',
                                                },
                                            }}
                                            onClick={() => {
                                                if (tipAmount === 100) {
                                                    setTipAmount(0);
                                                } else {
                                                    setTipAmount(100);
                                                }
                                            }}
                                        >
                                            ₹100
                                        </Button>
                                    </Box>
                                </Box>
                                {/* Cancellation Policy Section */}
                                <Box sx={{ mt: 2, backgroundColor: '#fff', borderRadius: '12px', padding: '16px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        Cancellation Policy
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        Orders cannot be cancelled once packed for delivery. In case of unexpected delays, a refund will be provided, if applicable.
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Proceed to Checkout Button (fixed at the bottom) */}
                            <Box
                                sx={{
                                    position: 'sticky',
                                    bottom: 0,
                                    backgroundColor: '#fff',
                                    padding: '16px',
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                    boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: '100%',
                                        padding: '10px 0',
                                        borderRadius: '25px',
                                        backgroundColor: 'green',
                                        '&:hover': {
                                            backgroundColor: '#5a1bcc',
                                            borderColor: '#5a1bcc',
                                        }
                                    }}
                                    onClick={handleOpenAddressModel}
                                >
                                    Please select an address
                                </Button>
                            </Box>
                        </div>
                    )}
                </Box>
            </Dialog>
            <AddressModel open={openAddressModel} onClose={handleCloseAddressModel} />
        </>
    );
};

export default CartModal;
